<script>

import { useCampaignComponents } from '@campaign';
import { useEnvComponents } from '@overlay';

const {
    Campaign,
    Sidebar,
    Environment,
} = useCampaignComponents();

export default {
    name: 'Users',
    extends: useEnvComponents('Layout'),
    components: {
        Sidebar,
        Environment,
        Rightbar: Campaign,
    },

    setup() {

        return {
            title: 'campaigns',
            sidebar: true,
            rightbar: true,
        };
    }
};
</script>
