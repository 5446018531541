<script setup>

import { PopUpManager as PopUp } from '@/Ship';
import { useProfileActions } from '@profile';
import { useUserActions } from '@user';

const getUserResource = useUserActions('getUserResource');
const getProfileResource = useProfileActions('getProfileResource');


const users = getUserResource();
const profile = getProfileResource();

function getFilterValueByKey(filter, key) {
    if (users.filterset[filter]) {
        const filterset = users.filterset[filter].find(item => item.id === Number(key)  || item.value === key);
        return filterset ? filterset.key || filterset.value : key || 'Null';
    }

    return key;
}

function dropFilter(filter, value) {
    users.dropFilterValue(filter, value);
    users.setPage(1);
    users.load(null, {}, 'UsersIndexbar DropFilter');

    profile.state.user.filters = users.filters;
    profile.saveState();
}
</script>

<template>
    <div class="index-bar">
        <g-flex justify="between" align="end">

            <slot/>

            <div class="separator"></div>

            <g-flex class="toolbar" gap="5" align="center">
                <g-button v-if="profile.can('User Make')" class="tool" v-on:click="PopUp.open(require('@user/components/PopUps/MakeUser')).setCaption($t('user.make_user', 'Make User'))">
                    <g-symbol name="plus" width="14" height="14"/>
					{{ $t('user.make_user', 'Make User') }}
                </g-button>
			</g-flex>
        </g-flex>
        <g-flex v-if="Object.keys(users.filters).length > 0" class="filters" gap="4" wrap="wrap">
            <div class="filter" v-for="(values, key) in users.filters" v-bind:key="key">
                {{ $t(`filters.${key}`, key) }}:
                <div class="filter-item" v-for="(value, i) in Object.keys(values)" v-bind:key="`${key}-${i}`">
                    <div class="value">{{ getFilterValueByKey(key, value) }}</div>
                    <g-symbol name="close" width="14" height="14" v-on:click="dropFilter(key, value)"/>
                </div>
            </div>
        </g-flex>
    </div>
</template>

<style lang="scss" scoped>
.index-bar {
    top: 0;
    left: 0;
    z-index: 2;
    position: sticky;
    padding: 10px 15px 18px;
    border-radius: 0 0 5px 5px;
    background-color: var(--bar-bg, $bar-bg);
    border-bottom: 1px solid var(--separator-color, $separator-color);

    .per-page {
        width: 120px;
    }

    .separator {
        flex-grow: 1;
    }

    .tool {
        margin-top: 8px;
        cursor: pointer;
        position: relative;
		padding: 0 10px;
        background-color: transparent;
        fill: var(--main-text-color, $main-text-color);
        color: var(--main-text-color, $main-text-color);
		border: 1px solid var(--main-text-color, $main-text-color);

        &:hover {
			color: var(--title-color, $title-color);
            fill: var(--title-color, $title-color);
			background-color: var(--success, $success);
			border-color: var(--success, $success);
        }
    }
}

.filters {
    padding-top: 15px;
}

.filter {
    padding-left: 8px;
    border-radius: 5px;
    overflow: hidden;
    text-transform: capitalize;
    color: var(--btn-primary-color, $btn-primary-color);
    background-color: var(--danger, $danger);

    & > .filter-item {
        padding: 2px 4px 2px 8px;
        background-color: var(--danger-hover, $danger-hover);
        display: inline-flex;
        align-items: center;
        gap: 2px;

        & > .value {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 80px;
        }

        & > .g-symbol {
            cursor: pointer;
            fill: var(--btn-primary-color, $btn-primary-color);

            &:hover {
                fill: white;
            }
        }
    }
}
</style>