<script setup>

import { defineProps, defineEmits } from 'vue';
import { useFinanceResources } from '@finance';
import { STATE, i18n, PopUpManager as PopUp } from '@/Ship';
import { useProfileActions } from '@profile';

const props = defineProps(['total']);
const emit = defineEmits(['update:total']);

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const withdraws = useFinanceResources('Withdraw');
withdraws.setIncludes(['user', 'lead']);
withdraws.sort('id', 'desc');
//deposits.setFilters(filters)

withdraws.onload = () => {
    emit('update:total', {
        count: withdraws.state.length,
        sum: Math.round(withdraws.state.reduce((a, b) => a + Number(b.amount), 0))
    });
};

withdraws.load(null, {}, 'Withdrawals Component');

const columns = [
    { key: 'method', translationKey: 'base.method', defaultTitle: 'Method', sortable: true, justify: 'start' },
    { key: 'lead', translationKey: 'base.lead', defaultTitle: 'Lead', sortable: true, justify: 'start' },
    { key: 'amount', translationKey: 'base.amount', defaultTitle: 'Amount', sortable: true, justify: 'start' },
    { key: 'address', translationKey: 'base.address', defaultTitle: 'Address', sortable: true, justify: 'start' },
    { key: 'user_id', translationKey: 'base.agent', defaultTitle: 'Agent', sortable: true, justify: 'start' },
    { key: 'status', translationKey: 'base.status', defaultTitle: 'Status', sortable: true, justify: 'center' },
    { key: 'created_at', translationKey: 'base.date', defaultTitle: 'date', sortable: true, justify: 'start' },
];

const statuses = [
    { slug: 'new', name: 'New' },
    { slug: 'approved', name: 'Approved' },
    { slug: 'auto_approved', name: 'Auto approved' },
    { slug: 'processed', name: 'Processed' },
    { slug: 'error', name: 'Error' },
    { slug: 'canceled', name: 'Canceled' }
];

function sortBy(field) {
    if (withdraws.sortBy === field) {
        profile.state.finances.sortDir = withdraws.sortDir === 'asc'
            ? 'desc'
            : 'asc';
    }
    profile.state.finances.sortBy = field;
    profile.saveState();

    withdraws.sort(
        profile.state.finances.sortBy,
        profile.state.finances.sortDir
    );
    withdraws.load(null, {}, 'FinancesEnv Resort');
}

STATE.IS_SHOW_SIDEBAR = false;

function changeStatus(index, value) {
    const previousValue = withdraws.state[index].status;
    withdraws.save(withdraws.state[index].id, {
        status: value
    });
}

function removeDeposit(id) {
    PopUp.open(require('@finance/components/PopUps/RemoveDeposit'))
    .setCaption(i18n.global.t('finances.remove_replenishment', 'Remove replenishment'))
    .setPayload({ payload: { id } });
}

</script>

<template>
    <div class="deposits">
        <div class="row">
            <div class="title" v-for="column in columns" :key="column.key"
                 :class="[column.key, {sortable: withdraws.sortBy === column.key}]">
                <g-flex align="center" :justify="column.justify" gap="2"
                        v-on:click="() => column.sortable && sortBy(column.key)">
                    {{ $t(column.translationKey, column.defaultTitle) }}
                    <g-symbol v-if="column.sortable" name="arrow-down" width="14" height="14"/>
                </g-flex>
            </div>
        </div>
        <div v-for="(withdraw, i) in withdraws.state"
             v-bind:key="`deposit-${withdraw.id}`"
             class="row deposit"
             v-bind:class="[withdraw.type]"
             v-bind:data-type="withdraw.type || 'Deposit'">

            <!-- METHOD -->
            <div class="cell method" v-bind:class="[withdraw.method, withdraw.key, {sortable: withdraws.sortBy === 'method'}]">
                {{ withdraw.method }}
            </div>
            <!--/ METHOD -->

            <!-- LEAD -->
            <div class="cell lead">
                <div class="header">ID: {{ withdraw.lead_id }}</div>
                <router-link v-if="!!withdraw.lead" class="lead-link"
                             v-bind:to="{ name: 'Lead', params: {id: withdraw.lead_id} }">
                    {{ withdraw.lead.first_name }} {{ withdraw.lead.last_name }}
                </router-link>
                <span v-else class="danger">Archived lead</span>
            </div>
            <!--/ LEAD -->

            <!-- AMOUNT -->
            <div class="cell amount" v-bind:class="{sortable: withdraws.sortBy === 'amount'}">
                <template v-if="!['USDT','USD'].includes(withdraw.currency)">
                    <div class="header">Amount:</div>
                    {{ Number((withdraw.amount / withdraw.rate).toFixed(5)) }}
                    <small class="currency" v-bind:class="withdraw.currency.toLowerCase()">{{
                            withdraw.currency
                        }}</small>
                    <small v-if="withdraw.rate !== 1" class="rate"> ✕ {{ withdraw.rate }}</small>
                    =
                </template>
                {{ Number(withdraw.amount).toFixed(2) }} <small class="currency usdt">USDT</small>
            </div>
            <!--/ AMOUNT -->

            <!-- ADDRESS -->
            <div v-if="withdraw.method === 'card'" class="cell address" v-bind:class="{sortable: withdraws.sortBy === 'address'}">
                <div class="header">{{ withdraw.details?.card_name || 'n/a' }}</div>
                <g-copy v-bind:text="withdraw.details?.card_number || 'n/a'"></g-copy>
            </div>
            <div v-else-if="withdraw.method === 'paypal'" class="cell address" v-bind:class="{sortable: withdraws.sortBy === 'address'}">
                <div class="header">E-Mail:</div>
                <g-copy v-bind:text="withdraw.details?.paypal_email || 'n/a'"></g-copy>
            </div>
            <div v-else-if="withdraw.method === 'bank'" class="cell address" v-bind:class="{sortable: withdraws.sortBy === 'address'}">
                <div class="header">SWIFT/BIC</div>
                <g-copy class="value" v-bind:text="withdraw.details?.swift || 'n/a'"></g-copy>
            </div>
            <div v-else class="cell address" v-bind:class="{sortable: withdraws.sortBy === 'address'}">
                <div class="header">Address:</div>
                <g-copy class="value" v-bind:text="withdraw.address"></g-copy>
            </div>
            <!--/ ADDRESS -->

            <!-- AGENT -->
            <div class="cell" v-bind:class="{sortable: withdraws.sortBy === 'user_id'}">
                {{ withdraw.user?.name || '' }}
            </div>
            <!--/ AGENT -->

            <!-- STATUS -->
            <div class="cell status" v-bind:class="[withdraw.status, {sortable: withdraws.sortBy === 'status'}]">
                <template v-if="withdraw.status === 'processed'">Processed</template>
                <g-select v-else
                          v-model="withdraw.status"
                          option-text="name"
                          option-value="slug"
                          v-bind:options="statuses"
                          v-on:select="changeStatus(i, $event)"
                          v-bind:readonly="profile.cant('Withdraw Edit')"/>
            </div>
            <!--/ STATUS -->

            <!-- DATE -->
            <div class="cell date" v-bind:class="{sortable: withdraws.sortBy === 'created_at'}">
                {{ withdraw.created_at }}
            </div>
            <!--/ DATE -->
        </div>
    </div>
</template>

<style scoped lang="scss">
.deposits {
    display: table;
    min-width: 100%;

    & > .row {
        font-size: 0.9rem;
        padding: 4px;
        display: table-row;
        white-space: nowrap;

        &:nth-child(even) {
            background-color: #00000022;
        }

        & > .title {
            padding: 8px 8px;
            display: table-cell;
            vertical-align: middle;

            .g-flex {
                cursor: pointer;
                fill: var(--main-text-color, $main-text-color);
            }

            &.sortable > .g-flex > .g-symbol {
                fill: black;
                transform: rotate(180deg);
            }
        }

        & .sortable {
            fill: var(--sorted-text-color, $sorted-text-color);
            color: var(--sorted-text-color, $sorted-text-color);
            background-color: var(--sorted-bg, $sorted-bg);

            &.title {
                font-weight: bold;
            }

            &.desc > .g-symbol {
                transform: rotate(180deg);
            }
        }

        & > .cell {
            padding: 4px 10px;
            display: table-cell;
            vertical-align: middle;

            &.method {
                font-size: 1.1rem;
                text-transform: uppercase;
            }

            &.address {
                max-width: 180px;
            }

            &.amount {
                white-space: nowrap;
            }

            &.date {
                width: 140px;
            }

            &.status {
                width: 140px;
                text-align: center;

                & > .g-select:deep(fieldset) > legend {
                    padding: 0;
                    margin: 0;
                }

                &.new > .g-select:deep(fieldset) {
                    border-color: var(--primary-hover, $primary-hover);

                    & > legend {
                        color: var(--primary-hover, $primary-hover);
                    }
                }

                &.approved > .g-select:deep(fieldset),
                &.auto_approved > .g-select:deep(fieldset) {
                    border-color: var(--warning-hover, $warning-hover);

                    & > legend {
                        color: var(--warning-hover, $warning-hover);
                    }
                }

                &.processed > .value {
                    color: var(--success-hover, $success-hover);
                }

                &.error > .g-select:deep(fieldset) {
                    border-color: var(--danger-hover, $danger-hover);

                    & > legend {
                        color: var(--danger-hover, $danger-hover);
                    }
                }

                &.canceled > .g-select:deep(fieldset) {
                    border-color: var(--separator-color, $separator-color);

                    & > legend {
                        color: var(--disabled-color, $disabled-color);
                    }
                }
            }
        }
    }

    .header {
        font-size: 0.8rem;
        color: var(--disabled-color, $disabled-color);
    }
}

.test {
    color: var(--disabled-color, $disabled-color);
}

.lead-link {
    color: var(--main-text-color, $main-text-color);
    text-decoration: underline;

    &:hover {
        color: var(--title-text-color, $title-text-color);
    }
}

.card {
    color: #f2dc9d;
}

.bank {
    color: #4fa58c;
}

.paypal {
    color: #5d80e5;
}

.rate,
.currency {
    font-size: 0.8rem;
}

.btc {
    color: $btc;
    font-weight: 700;
}

.usdt {
    color: $usdt;
    font-weight: 700;
}

.eur {
    color: $eur;
    font-weight: 700;
}

.eth {
    color: $eth;
    font-weight: 700;
}

.danger {
    color: var(--danger, $danger);
}
</style>