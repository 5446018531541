import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Asset extends BaseResource {

    static #instance = null;

    endpoint = 'assets';
    referer = 'Asset Resource';

    section = 'Settings';
    container = 'Assets';

    static getInstance() {
        if (!Asset.#instance) {
            Asset.#instance = new Asset();
        }
        return Asset.#instance;
    }
}
