import { useCampaignResources } from '@campaign';
import { ResourcesManager } from "@/Ship";

export default (reload = false) => {
    const campaign = useCampaignResources('Campaign');
    //if (reload || (!campaign.isLoaded && !campaign.isLoading)) {
        //campaign.setIncludes(['offer', 'buyer.teamlead', 'countries']);

        //campaign.load();
    //}

    ResourcesManager.addResource(campaign, 'Campaign');

    return campaign;
};
