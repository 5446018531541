
import { useTradingActions } from '@trading';
import { STACK, ApiClient } from '@/Ship';

const getPositionHistoryResource = useTradingActions('getPositionHistoryResource');

export default (id) => {
    const position = getPositionHistoryResource();

    return STACK.push(() => {
        return ApiClient.put(`${position.endpoint}/${id || this.entry.id}`);
    });
};