import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Buyer extends BaseResource {

    static #instance = null;

    endpoint = 'buyers';
    referer = 'Buyer resource';

    section = 'Offers';
    container = 'Buyers';

    static getInstance() {
        if (!Buyer.#instance) {
            Buyer.#instance = new Buyer();
        }
        return Buyer.#instance;
    }
}
