import { useArchiveResources } from '@archive';
import { ApiClient, STACK } from '@/Ship';

const archive = useArchiveResources('Archive');
export default {

    restore(id) {
        return STACK.push(() => {
            return ApiClient.put(`${archive.endpoint}/${id || this.entry.id}`);
        });
    },
    massRestore(targets) {
        return STACK.push(() => {
            return ApiClient.put(archive.endpoint, { targets:  targets  });
        });
    }
};