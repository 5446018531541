<script setup>

import { reactive, defineEmits } from 'vue';
import { useLeadActions } from '@lead';
import { useShipActions } from '@/Ship';

const emit = defineEmits(['close']);

const getLeadResource = useLeadActions('getLeadResource');

const lead = getLeadResource();
const onlyNumberInput = useShipActions('onlyNumberInput');

const payload = reactive({
    balance: lead.model.balance,
    reason: '',
});

function edit() {
    lead.state[lead.index].balance = payload.balance
    lead.model.balance = payload.balance;
    emit('close');
}


</script>

<template>
    <form class="correct-balance" v-on:submit.prevent="edit">

        <g-field v-model="payload.balance"
                 v-on:keypress="onlyNumberInput($event)"
                 label="New balance"/>
        <g-textarea v-model="payload.reason"
                    rows="3"
                    label="Reason"/>

        <g-flex gap="8">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">{{ $t('base.cancel', 'Cancel') }}</g-button>
            <g-button class="apply-btn" type="submit" v-bind:disabled="!payload.balance || !payload.reason">{{ $t('base.apply', 'Apply') }}</g-button>
        </g-flex>
    </form>
</template>

<style lang="scss" scoped>
.correct-balance {
    gap: 10px;
    width: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.g-field,
.g-textarea {
    width: 100%;
}

.g-button {
    width: 135px;

    &.apply-btn {
        background-color: var(--primary, $primary);
        color: var(--btn-primary-color, $btn-primary-color);

        &:hover {
            color: var(--btn-primary-color, $btn-primary-color);
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    &.cancel-btn {
        background-color: transparent;
        color: var(--main-text-color, $main-text-color);
        border: 1px solid var(--separator-color, $main-text-color);

        &:hover {
            color: var(--title-text-color, $title-text-color);
            border-color: var(--main-text-color, $main-text-color);
        }
    }
}
</style>