<script setup>
import { defineEmits, defineProps } from 'vue';

const props = defineProps(['payload']);
const emit = defineEmits(['performed', 'close']);

const payload = {
    icon: 'delete',
    text: 'remove',
    fill: 'danger',
    showCancelButton: true,
};
Object.assign(payload, props.payload)

</script>

<template>
    <div class="confirm-popup">
        <div class="body"></div>
        <div class="footer">
            <g-button v-if="payload.showCancelButton"
                      class="cancel-btn"
                      v-on:click="$emit('close')">
                <g-symbol name="close" width="20" height="20"/>
                {{ $t('base.cancel', 'Cancel') }}
            </g-button>

            <g-button v-bind:class="payload.fill"
                      class="confirm-btn"
                      v-on:click="$emit('performed')">
                <g-symbol :name="payload.icon" width="20" height="20"/>
                {{ $t(`base.${[payload.text]}`, payload.text) }}
            </g-button>
        </div>
    </div>
</template>

<style scoped lang="scss">
.confirm-popup {
    width: 380px;
    max-width: 100%;
}

.footer {
    gap: 12px;
    display: flex;
    justify-content: center;
    margin-top: 32px;

    & > .g-button {
        padding: 0 20px;
        min-width: 120px;

        &.cancel-btn > svg {
            fill: var(--main-text-color, $main-text-color);
        }

        &.confirm-btn {
            color: var(--title-color, $title-color);

            svg {
                fill: var(--title-color, $title-color);
            }

            &.danger {
                background-color: var(--danger, $danger);

                &:hover {
                    background-color: var(--danger-hover, $danger-hover);
                }
            }

            &.success {
                background-color: var(--success, $success);

                &:hover {
                    background-color: var(--success-hover, $success-hover);
                }
            }

            &.primary {
                background-color: var(--primary, $primary);

                &:hover {
                    background-color: var(--primary-hover, $primary-hover);
                }
            }
        }
    }
}
</style>