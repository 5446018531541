
import { useProfileResources } from '@profile';
import {ResourcesManager} from "@/Ship";

export default () => {
    const profile = useProfileResources('Profile');
    if (!profile.isLoaded && !profile.isLoading) {
        profile.load();
    }

    ResourcesManager.addResource(profile, 'Profile');

    return profile;
};
