<template>
    <div class="documents">
        <div class="item">
            <div>
                <img v-if="!!docs.id_front && docs.id_front !== 'delete'"
                     v-bind:src="`${host}${docs.id_front}`"
                     alt="ID Front"/>
                <label v-else>
                    <g-symbol name="plus" width="24" height="24"/>
                    <br>
                    {{ $t('docs.import') }}
                    <br>
                    <b>ID card (front)</b>

                    <input type="file"
                           accept="image/jpeg, image/png, image/webp"
                           v-on:change="onFileChange($event, 'id_front')"
                           hidden/>
                </label>
                <span class="remove-btn"
                      v-if="!!docs.id_front && docs.id_front !== 'delete'"
                      v-on:click.stop="docs.id_front = 'delete'">✕</span>
            </div>
        </div>
        <div class="item">
            <div>
                <img v-if="!!docs.id_back && docs.id_back !== 'delete'"
                     v-bind:src="`${host}${docs.id_back}`"
                     alt="ID Back"/>
                <label v-else>
                    <g-symbol name="plus" width="24" height="24"/>
                    <br>
                    {{ $t('docs.import') }}
                    <br>
                    <b>ID card (back)</b>

                    <input type="file"
                           accept="image/jpeg, image/png, image/webp"
                           v-on:change="onFileChange($event, 'id_back')"
                           hidden/>
                </label>
                <span class="remove-btn"
                      v-if="docs.id_back && !!docs.id_back && docs.id_back !== 'delete'"
                      v-on:click.stop="docs.id_back = 'delete'">✕</span>
            </div>
        </div>
        <div class="item">
            <div>
                <img v-if="!!docs.card_front && docs.card_front !== 'delete'"
                     v-bind:src="`${host}${docs.card_front}`"
                     alt="Card Front"/>
                <label v-else>
                    <g-symbol name="plus" width="24" height="24"/>
                    <br>
                    {{ $t('docs.import') }}
                    <br>
                    <b>Card (front)</b>

                    <input type="file"
                           accept="image/jpeg, image/png, image/webp"
                           v-on:change="onFileChange($event, 'card_front')"
                           hidden/>
                </label>
                <span class="remove-btn"
                      v-if="!!docs.card_front && docs.card_front !== 'delete'"
                      v-on:click.stop="docs.card_front = 'delete'">✕</span>
            </div>
        </div>
        <div class="item">
            <div>
                <img v-if="!!docs.card_back && docs.card_back !== 'delete'"
                     v-bind:src="`${host}${docs.card_back}`"
                     alt="Card Back"/>

                <label v-else>
                    <g-symbol name="plus" width="24" height="24"/>
                    <br>
                    {{ $t('docs.import') }}
                    <br>
                    <b>Card (back)</b>

                    <input type="file"
                           accept="image/jpeg, image/png, image/webp"
                           v-on:change="onFileChange($event, 'card_back')"
                           hidden/>
                </label>
                <span class="remove-btn"
                      v-if="!!docs.card_back && docs.card_back !== 'delete'"
                      v-on:click.stop="docs.card_back = 'delete'">✕</span>
            </div>
        </div>
        <div class="item">
            <div>
                <img v-if="!!docs.ub && docs.ub !== 'delete'"
                     v-bind:src="`${host}${docs.ub}`"
                     alt="UB"/>

                <label v-else>
                    <g-symbol name="plus" width="24" height="24"/>
                    <br>
                    {{ $t('docs.import') }}
                    <br>
                    <b>UB</b>

                    <input type="file"
                           accept="image/jpeg, image/png, image/webp"
                           v-on:change="onFileChange($event, 'ub')"
                           hidden/>
                </label>
                <span class="remove-btn"
                      v-if="!!docs.ub && docs.ub !== 'delete'"
                      v-on:click.stop="docs.ub = 'delete'">✕</span>
            </div>
        </div>
        <div class="item">
            <div>
                <img v-if="!!docs.dod && docs.dod !== 'delete'"
                     v-bind:src="`${host}${docs.dod}`"
                     alt="DOD"/>
                <label v-else>
                    <g-symbol name="plus" width="24" height="24"/>
                    <br>
                    {{ $t('docs.import') }}
                    <br>
                    <b>DOD</b>

                    <input type="file"
                           accept="image/jpeg, image/png, image/webp"
                           v-on:change="onFileChange($event, 'dod')"
                           hidden/>
                </label>
                <span class="remove-btn"
                      v-if="!!docs.dod && docs.dod !== 'delete'"
                      v-on:click.stop="docs.dod = 'delete'">✕</span>
            </div>
        </div>
    </div>
</template>

<script>

import { onMounted, reactive, computed } from 'vue';
import { useProfileActions, useProfileResource, useProfileResources } from '@profile';

const { uploadDocuments } = useProfileActions();

export default {
    name: 'Documents',
    setup() {
        const profile = useProfileResources('Profile');
        let docs = reactive({
            id_front: null,
            id_back: null,
            card_front: null,
            card_back: null,
            dod: null,
            ub: null,
        });

        onMounted(() => {
            if (profile && profile.specifics && profile.specifics.documents) {
                for (const key in profile.specifics.documents) {
                    docs[key] = profile.specifics.documents[key];
                }
            }
        });

        const onFileChange = (e, target) => {
            let files = e.target.files || e.dataTransfer.files;
            return !files.length ? null : createImage(files[0], target);
        };
        const createImage = (file, target) => {
            let img = new Image();
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                img.src = e.target.result;

                img.onload = function () {

                    let canvas = document.createElement('canvas');
                    let ctx = canvas.getContext('2d');

                    canvas.width = 800;
                    canvas.height = 500;

                    const hRatio = canvas.width / img.width;
                    const vRatio = canvas.height / img.height;
                    const ratio = Math.min(hRatio, vRatio);
                    const centerShift_x = (canvas.width - img.width * ratio) / 2;
                    const centerShift_y = (canvas.height - img.height * ratio) / 2;

                    ctx.clearRect(0, 0, canvas.width, canvas.height);

                    ctx.drawImage(img, 0, 0, img.width, img.height,
                        centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);

                    docs[target] = canvas.toDataURL('image/jpeg');

                    url2File(docs[target], target, 'image/jpeg').then((encodedFile) => {
                        uploadDocuments(target, encodedFile).then((res) => {
                            docs[target] = res.url;
                        });
                    });
                    // docs[target] = encodedFile;
                };
            };
        };

        //return a promise that resolves with a File instance
        const url2File = (url, filename, mimeType) => {
            return (fetch(url)
                .then(function (res) {return res.arrayBuffer();})
                .then(function (buf) {return new File([buf], `${filename}.jpeg`, { type: mimeType });})
            );
        };

        const host = computed(() => {
            return process.env.VUE_APP_SERVER;
        });

        return {
            docs,
            host,
            onFileChange,
        };
    }
};
</script>

<style lang="scss" scoped>
.documents {
    flex-grow: 0;
    display: grid;
    grid-gap: 20px;

    & > .item {
        position: relative;
        box-shadow: 8px 16px 32px rgba(113 128 150 / 8%);
        border: 1px solid $separator-color;
        border-radius: $profile-menu-rounding;

        & > div {
            height: 100%;
            color: $secondary;
            font-size: 13px;
            position: relative;
            padding-bottom: 56.25%;

            & > img {
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                border-radius: 9px;
                object-fit: cover;
            }

            & > label {
                top: 50%;
                left: 50%;
                cursor: pointer;
                position: absolute;
                text-align: center;
                transform: translate(-50%, -50%);

                & > svg {
                    fill: silver;
                }

                & > b {
                    font-size: 16px;
                    color: $primary;
                }

                &:hover {
                    text-decoration: underline;

                    svg {
                        fill: $primary;
                    }
                }
            }

            & > .remove-btn {
                border-radius: 50%;
                text-align: center;
                cursor: pointer;
                z-index: 10;
                top: 2px;
                right: 2px;
                width: 24px;
                height: 24px;
                line-height: 24px;
                position: absolute;
                background-color: white;
            }
        }
    }
}

@media (min-width: 624px) {
    .information {
        display: flex;
        align-items: flex-start;
    }
}

@media (max-width: 1024px) {
    .documents {
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }
}

@media (min-width: 1025px) {
    .documents {
        overflow: auto;
        max-height: calc(100vh - 120px);
        grid-template-columns: repeat(auto-fit, minmax(280px, 2fr));
    }
}

@media (min-width: 685px) AND (max-width: 1024px) {
    .documents {
        & > .item {
            padding: 10px;
        }
    }
}

@media (min-width: 1325px) AND (max-width: 1624px) {
    .documents {
        & > .item {
            padding: 16px;
        }
    }
}
</style>
