<script setup>

import { defineProps, reactive } from "vue";
import { useProfileActions } from '@profile';
import { useLeadActions } from '@lead';
import { i18n, STATE } from "@/Ship";

const props = defineProps(['specifics']);

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const specifics = reactive({});
const payload = reactive({});
const state = reactive({});

setState();

function addFieldToChangeList(field) {
    if (!STATE.UNSAVED_DATA.details) {
        STATE.UNSAVED_DATA.details = [];
    }
    if (!STATE.UNSAVED_DATA.details.includes(field)) {
        STATE.UNSAVED_DATA.details.push(field);
    }
}

const model = new Proxy(payload, {
    get: (target, key) => {
        return target[key] || state[key];
    },
    set: (target, key, value) => {
        if (value === state[key]) {
            delete payload[key];
            STATE.UNSAVED_DATA.details.splice(STATE.UNSAVED_DATA.details.indexOf(key), 1);
        } else {
            target[key] = value;
            addFieldToChangeList(i18n.global.t(`lead.${key}`, key));
        }

        return true;
    }
});

function save() {
    Object.assign(state, payload);
    const specifics = {};
    Object.assign(specifics, lead.model.specifics);
    let hasSpecifics = false;
    Object.keys(payload).forEach(key => {
        if (['region', 'city', 'address', 'postcode', 'age', 'birthday', 'job_position'].includes(key)) {
            specifics[key] = payload[key];
            delete payload[key];
            hasSpecifics = true;
        }
    });

    if (hasSpecifics) {
        payload.specifics = specifics;
    }

    lead.save(lead.model.id, payload).then(() => {
        Object.assign(lead.state[lead.index], state);
        lead.state[lead.index].specifics = specifics;
        reset();
    });
}

function reset() {
    Object.keys(payload).forEach(key => delete payload[key]);
    STATE.UNSAVED_DATA.details = [];
}

function setState() {
    [
        'gender',
        'timezone',
        'marital_status',
    ].forEach(key => state[key] = lead.state[lead.index][key]);

    [
        'region',
        'city',
        'address',
        'postcode',
        'age',
        'birthday',
        'job_position',
    ].forEach(key => state[key] = lead.state[lead.index].specifics[key]);
}

</script>

<template>
    <g-select v-model="model.gender"
              v-bind:backlight="!!payload.gender"
              v-bind:options="[{value: 'Male'}, {value: 'Female'}, {value: 'Other'}]"
              option-value="value"
              option-text="value"
              v-bind:label="$t('lead.gender')"
              v-bind:readonly="profile.cant('Lead DetailsEdit')"/>

    <g-select v-model="model.marital_status"
              v-bind:backlight="!!payload.marital_status"
              v-bind:options="[{value: 'Unmarried'}, {value: 'Married'}, {value: 'Divorced'}, {value: 'Widowed'}, {value: 'Legally Separated'}]"
              option-value="value"
              option-text="value"
              v-bind:label="$t('lead.marital_status')"
              v-bind:readonly="profile.cant('Lead DetailsEdit')"/>

    <g-field v-model="model.region"
             v-bind:backlight="!!payload.region"
             v-bind:label="$t('lead.region')"
             v-bind:readonly="profile.cant('Lead DetailsEdit')">
        <g-symbol v-if="profile.cant('Lead DetailsEdit')" name="lock" width="18" height="18"/>
    </g-field>
    <g-field v-model="model.city"
             v-bind:backlight="!!payload.city"
             v-bind:label="$t('lead.city', 'City')"
             v-bind:readonly="profile.cant('Lead DetailsEdit')">
        <g-symbol v-if="profile.cant('Lead DetailsEdit')" name="lock" width="18" height="18"/>
    </g-field>
    <div class="full-row">
        <g-field v-model="model.address"
                 v-bind:backlight="!!payload.address"
                 v-bind:label="$t('lead.address', 'Address')"
                 v-bind:readonly="profile.cant('Lead DetailsEdit')">
            <g-symbol v-if="profile.cant('Lead DetailsEdit')" name="lock" width="18" height="18"/>
        </g-field>
    </div>
    <g-field v-model="model.postcode"
             v-bind:backlight="!!payload.postcode"
             v-bind:label="$t('lead.postcode', 'Postcode')"
             v-bind:readonly="profile.cant('Lead DetailsEdit')">
        <g-symbol v-if="profile.cant('Lead DetailsEdit')" name="lock" width="18" height="18"/>
    </g-field>
    <g-field v-model="model.timezone"
             v-bind:backlight="!!payload.timezone"
             v-bind:label="$t('lead.timezone', 'Timezone')"
             v-bind:readonly="profile.cant('Lead DetailsEdit')">
        <g-symbol v-if="profile.cant('Lead DetailsEdit')" name="lock" width="18" height="18"/>
    </g-field>

    <g-field v-model="model.birthday"
             type="date"
             v-bind:backlight="!!payload.birthday"
             v-bind:label="$t('lead.birthday')"
             v-bind:readonly="profile.cant('Lead DetailsEdit')">
        <g-symbol v-if="profile.cant('Lead DetailsEdit')" name="lock" width="18" height="18"/>
    </g-field>
    <g-field v-model="model.age"
             v-bind:backlight="!!payload.age"
             v-bind:label="$t('lead.age', 'Age')"
             v-bind:readonly="profile.cant('Lead DetailsEdit')">
        <g-symbol v-if="profile.cant('Lead DetailsEdit')" name="lock" width="18" height="18"/>
    </g-field>

    <g-select v-model="model.job_position"
              v-bind:backlight="!!payload.job_position"
              v-bind:options="[{value: 'Worker'}, {value: 'Employee'}, {value: 'Middle Manager'}, {value: 'Top-Manager'}, {value: 'Self-Employed'}]"
              option-value="value"
              option-text="value"
              v-bind:label="$t('lead.job_position')"
              v-bind:readonly="profile.cant('Lead DetailsEdit')"/>

    <g-flex justify="end" class="btn-bar full-row" gap="8">
        <g-button class="reset-btn" type="button" v-on:click="reset" v-bind:disabled="!Object.keys(payload).length">
            <g-symbol name="refresh" width="18" height="18"/>
            {{ $t('base.reset', 'Reset') }}
        </g-button>
        <g-button class="save-btn" type="button" v-on:click="save" v-bind:disabled="!Object.keys(payload).length">
            <g-symbol name="save" width="18" height="18"/>
            {{ $t('base.save', 'Save') }}
        </g-button>
    </g-flex>
</template>

<style scoped lang="scss">
.full-row {
    grid-column: 1/3;
}

.btn-bar {
    padding-top: 20px;
}

.btn-bar > .g-button {
    padding: 0 30px;
    min-width: 130px;
    font-size: 15px !important;

    &.reset-btn {
        fill: var(--main-text-color, $main-text-color);
        color: var(--main-text-color, $main-text-color);
        background-color: transparent;
        border: 1px solid var(--separator-color, $separator-color);

        &:hover {
            color: white;
            fill: white;
            background-color: var(--separator-color, $separator-color);
        }
    }

    &.save-btn {
        background-color: var(--primary, $primary);

        &:hover {
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    & > .g-symbol {
        margin-right: 4px;
    }
}
</style>