import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Withdraw extends BaseResource {

    static #instance = null;

    endpoint = 'withdraws';
    referer = 'Withdraw Resource';

    section = 'Finances';
    container = 'Finances';

    static getInstance() {
        if (!Withdraw.#instance) {
            Withdraw.#instance = new Withdraw();
        }
        return Withdraw.#instance;
    }
}
