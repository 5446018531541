import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Campaign extends BaseResource {

    static #instance = null;

    endpoint = 'campaigns';
    referer = 'Campaign Resource';

    section = 'Campaigns';
    container = 'Campaigns';

    static getInstance() {
        if (!Campaign.#instance) {
            Campaign.#instance = new Campaign();
        }
        return Campaign.#instance;
    }
}
