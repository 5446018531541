
import { useAuthTasks } from '@auth';
import { STATE, STACK, ApiClient } from '@/Ship';

const { saveToken, reloadProfile } = useAuthTasks();

export default (email, password) => {
    return STACK.push(() => ApiClient.post('/login', { email, password }, {header: {Ref: 'SignIn Action'}})).then(data => {
        saveToken(data);
        reloadProfile();
        STATE.IS_LOGIN = true;
    });
};
