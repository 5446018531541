<script setup>

import { ref, defineEmits, defineProps, computed } from 'vue';
import { i18n } from '@/Ship';
import { useFinanceComponents } from '@finance';
import { useFinanceResources } from '@finance';
import { useProfileActions } from '@profile';

const {
    DateFilter,
    ExportXlsx
} = useFinanceComponents();

const getProfileResource = useProfileActions('getProfileResource');

const profile = getProfileResource();
const deposits = useFinanceResources('Deposit');
const withdraws = useFinanceResources('Withdraw');

const props = defineProps({
    part: { type: String },
    total: { type: Object },
});
const emit = defineEmits(['update:part']);

const tab = ref('Replenishments');
const tabList = {};
if (profile.can('Deposit View')) {
    tabList['Replenishments'] = i18n.global.t('finances.replenishments', 'Replenishments');
    tab.value = 'Replenishments';
}

if (profile.can('Withdraw View')) {
    tabList['Withdrawals'] = i18n.global.t('finances.withdrawals', 'Withdrawals');
    if (!profile.can('Deposit View')) {
        tab.value = 'Withdrawals';
    }
}

const transactionsData = ref([])

const transactionColumns = computed(() => {
    return formatTransactions(tab.value === 'Replenishments' ? deposits.state : withdraws.state);
});
function getTransactionColumns(value) {
    transactionsData.value = transactionColumns.value || []
}

const formatTransactions = (transactions) => {
    return transactions.map(transaction => {
        const commonData = {
            lead_id: transaction.lead && transaction.lead.id,
            lead_name: formatLeadName(transaction.lead),
            agent: formatAgent(transaction.user),
            amount: parseFloat(transaction.amount) / parseFloat(transaction.rate),
            currency: transaction.currency,
            rate: parseFloat(transaction.rate),
            usdt_amount: parseFloat(transaction.amount),
            method: transaction.method,
            type: transaction.type,
            created_at: transaction.created_at
        };

        if (tab.value === 'Replenishments') {
            return {
                ...commonData,
                sub_method: transaction.details ? transaction.details.card_name : '',
                is_ftd: transaction.lead && transaction.lead.is_ftd
            };
        }

        return commonData;
    });
};

const formatLeadName = (lead) => {
    return lead ? `${lead.first_name} ${lead.last_name}` : 'Unknown';
};

const formatAgent = (user) => {
    return user ? user.name : 'Unknown';
};

function choosePart() {
    emit('update:part', tab.value);
}

function getFilterValueByKey(filter, key) {
    if (deposits.filterset[filter]) {
        key = String(key).split(',').pop();
        const filterset = deposits.filterset[filter].find(item => item.id === Number(key) || item.value == key);
        let value = filterset ? filterset.key || filterset.value || filterset.name : key || 'Null';

        return value;
    }

    return key;
}

function dropFilter(filter, value) {
    deposits.dropFilterValue(filter, value);
    deposits.load(null, {}, 'Drop Filter');
    profile.state.finances.filters = deposits.filters;
    profile.saveState();
}

function resetAllFilters() {
    Object.keys(deposits.filters).forEach(key => {
        delete deposits.filters[key];
    });

    profile.state.finances.filters = {};
    deposits.setFilters(deposits.filters);
    deposits.load(null, {}, 'Reset Filters');

    profile.saveState();
}
</script>

<template>
    <div class="indexbar">
        <g-flex class="filters-wrapper" wrap="wrap" align="center">
            <export-xlsx v-bind:name="tab" v-bind:data="transactionsData" v-on:export-click="getTransactionColumns"/>
            <g-flex v-if="tab === 'Replenishments'">
                <date-filter/>
                <g-flex class="filters" gap="4" wrap="wrap" align="center">
                    <div v-if="Object.keys(deposits.filters).length > 0"
                         class="filter reset-all"
                         v-on:click="resetAllFilters">
                        Reset All
                        <div class="filter-item">
                            <g-symbol name="close" width="14" height="14"/>
                        </div>
                    </div>
                    <div class="filter" v-for="(values, key) in deposits.filters" v-bind:key="key" v-bind:class="key">
                        {{ $t(`filters.${key}`, key) }}:
                        <div class="filter-item" v-for="(value, i) in Object.keys(values)" v-bind:key="`${key}-${i}`">
                            <div class="value">{{ getFilterValueByKey(key, value) }}</div>
                            <g-symbol name="close" width="14" height="14" v-on:click="dropFilter(key, value)"/>
                        </div>
                    </div>
                </g-flex>
            </g-flex>
        </g-flex>
        <g-flex justify="between">
            <g-tabs v-model="tab"
                    v-on:change="choosePart"
                    v-bind:tab-list="tabList"/>

            <g-flex align="center" class="total" gap="2">
                Total:
                <g-symbol name="tether" width="18" height="18"/>
                <span class="sum">{{ total.sum }}</span>
                / {{ total.count }}
            </g-flex>
        </g-flex>
    </div>
</template>

<style scoped lang="scss">
.total {
    & > .g-symbol {
        fill: var(--main-text-color, $main-text-color);
    }

    & > .sum {
        color: var(--title-text-color, $title-text-color);
    }
}

.filters-wrapper {
    padding-top: 10px;
    padding-bottom: 10px;
}

.filter {
    height: 30px;
    padding-left: 8px;
    border-radius: 5px;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    color: white;
    border: 1px solid;
    box-shadow: 0 5px 15px -10px #000000aa;
    flex-flow: wrap;

    & > .filter-item {
        padding: 2px 4px 2px 8px;
        display: inline-flex;
        align-items: center;
        gap: 2px;
        height: 28px;

        &:first-child {
            margin-left: 5px;
        }

        & > .value {
            font-weight: 500;
        }

        & > .g-symbol {
            cursor: pointer;
            fill: var(--btn-primary-color, $btn-primary-color);
        }
    }

    &.reset-all {
        background-color: black;
        border-color: #333333;

        & > .filter-item {
            background-color: #333333;
        }
    }

    &.is_ftd {
        background-color: #d08300;
        border-color: #eaa308;

        & > .filter-item {
            background-color: #eaa308;
        }
    }

    &.status {
        background-color: var(--primary, $primary);
        border-color: var(--primary-hover, $primary-hover);

        & > .filter-item {
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    &.created_at {
        background-color: #24688a;
        border-color: #4488aa;

        & > .filter-item {
            background-color: #4488aa;
        }
    }

    &.method {
        background-color: var(--secondary, $secondary);
        border-color: var(--secondary-hover, $secondary-hover);

        & > .filter-item {
            background-color: var(--secondary-hover, $secondary-hover);
        }
    }

    &.agents {
        background-color: #008060;
        border-color: #22a585;

        & > .filter-item {
            background-color: #22a585;
        }
    }

    &.teams {
        background-color: #700099;
        border-color: #b010df;

        & > .filter-item {
            background-color: #b010df;
        }
    }

    &:not(.is_ftd, .created_at, .status, .method, .agents, .teams, .reset-all) {
        background-color: var(--danger, $danger);
        border-color: var(--danger-hover, $danger-hover);

        & > .filter-item {
            background-color: var(--danger-hover, $danger-hover);

            & > .value {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 80px;
            }
        }
    }
}
</style>