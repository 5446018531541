import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Brand extends BaseResource {

    static #instance = null;

    endpoint = 'brands';
    referer = 'Brand Resource';

    section = 'Settings';
    container = 'Brand';

    static getInstance() {
        if (!Brand.#instance) {
            Brand.#instance = new Brand();
        }
        return Brand.#instance;
    }

    find(id) {
        return this.collection.value.find(brand => brand.id === id);
    }
}
