
import { useAuthTasks } from '@auth';
import { STACK, ApiClient } from '@/Ship';

const {
	saveToken,
	reloadProfile
} = useAuthTasks();

export default () => {
	return STACK.push(() => ApiClient.post('/refresh', {
		refresh_token: localStorage.refresh_token,
	}, {header: {Ref: 'RefreshToken Action'}})).then(({ data }) => {
		saveToken(data);
		reloadProfile();
	});
};
