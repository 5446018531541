<script setup>

import { watch, reactive, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { i18n, PopUpManager as PopUp, STATE } from '@/Ship';
import { useUserActions, useUserComponents } from '@user';
import { useProfileActions } from '@profile';
import { useTeamActions } from '@team';
import { useBrandActions } from '@brand';
import { useSettingsResources } from "@settings";
import { useAuthComponents } from "@auth";

const PasswordInput = useAuthComponents('PasswordInput');

const {
    getUserResource,
    generateApiKey
} = useUserActions();
const user = getUserResource();

const getTeamResource = useTeamActions('getTeamResource');
const teams = getTeamResource();

const getBrandResource = useBrandActions('getBrandResource');
const brands = getBrandResource();

const Whitelist = useUserComponents('Whitelist');

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const settings = useSettingsResources('Settings');
if (!settings.roles) {
    settings.load('roles');
}

const router = useRouter();
const route = useRoute();

const isRender = ref(true);
watch(() => user.index, () => {
    isRender.value = false;
    setTimeout(() => {
        isRender.value = true;
    }, 0);
});

const errors = reactive({
    name: false,
    email: false,
    password: false,
});
const isComplex = ref(false);

const statusList = [
    { name: 'Active' },
    { name: 'Suspended' },
    { name: 'Deleted' }
];

const payload = reactive({});

function addFieldToChangeList(field) {
    if (!STATE.UNSAVED_DATA.user) {
        STATE.UNSAVED_DATA.user = [];
    }
    if (!STATE.UNSAVED_DATA.user.includes(field)) {
        STATE.UNSAVED_DATA.user.push(field);
    }
}

function setValue(key, value) {
    if (user.model[key] === value) {
        delete payload[key];
        STATE.UNSAVED_DATA.user.splice(STATE.UNSAVED_DATA.user.indexOf(i18n.global.t(`user.${key}`, key)), 1);
    } else {
        payload[key] = value;
        addFieldToChangeList(i18n.global.t(`user.${key}`, key));
    }
}


const name = computed({
    get: () => 'name' in payload ? payload.name : user.model.name,
    set: (value) => {
        setValue('name', value);
        return true;
    }
});

const email = computed({
    get: () => 'email' in payload ? payload.email : user.model.email,
    set: (value) => {
        setValue('email', value);
        return true;
    }
});

const status = computed({
    get: () => payload.status || user.model.status,
    set: (value) => {
        setValue('status', value);
        return true;
    }
});

const password = computed({
    get: () => 'password' in payload ? payload.password : user.model.password,
    set: (value) => {
        setValue('password', value);
        return true;
    }
});

const userRoles = computed({
    get: () => payload.roles || (user.model.roles || []).map(role => role.id),
    set: (value) => {

        const roles = (user.model.roles || []).map(role => role.id);

        if (value.some(item => !roles.includes(item)) || roles.some(item => !value.includes(item))) {
            payload.roles = value;
            addFieldToChangeList(i18n.global.t('user.roles', 'Roles'));
        } else {
            STATE.UNSAVED_DATA.user.splice(STATE.UNSAVED_DATA.user.indexOf(i18n.global.t('user.roles', 'Roles')), 1);
            delete payload.roles;
        }

        return true;
    }
});

const userTeams = computed({
    get: () => payload.teams || (user.model.teams || []).map(team => team.id),
    set: (value) => {

        const teams = (user.model.teams || []).map(team => team.id);

        if (value.some(item => !teams.includes(item)) || teams.some(item => !value.includes(item))) {
            payload.teams = value;
            addFieldToChangeList(i18n.global.t('user.teams', 'Teams'));
        } else {
            STATE.UNSAVED_DATA.user.splice(STATE.UNSAVED_DATA.user.indexOf(i18n.global.t('user.teams', 'Teams')), 1);
            delete payload.teams;
        }

        return true;
    }
});

const userBrands = computed({
    get: () => payload.brands || (user.model.brands || []).map(brand => brand.id),
    set: (value) => {

        const brands = (user.model.brands || []).map(brand => brand.id);

        if (value.some(item => !brands.includes(item)) || brands.some(item => !value.includes(item))) {
            payload.brands = value;
            addFieldToChangeList(i18n.global.t('user.brands', 'Brands'));
        } else {
            STATE.UNSAVED_DATA.user.splice(STATE.UNSAVED_DATA.user.indexOf(i18n.global.t('user.brands', 'Brands')), 1);
            delete payload.brands;
        }

        return true;
    }
});

function save() {
    user.save(user.model.id, payload).then(() => {

        Object.keys(payload).forEach(key => {
            switch (key) {
                case 'brands':
                    user.state[user.index].brands = payload.brands.map(item => brands.state.find(brand => brand.id === item));
                    if (!payload.brands.includes(profile.state.env.brand)) {
                        profile.state.env.brand = payload.brands[0] || 0;
                        profile.saveState();
                    }
                    break;
                case 'teams':
                    user.state[user.index].teams = payload.teams.map(item => teams.state.find(team => team.id === item));
                    break;
                case 'roles':
                    user.state[user.index].roles = payload.roles.map(item => settings.roles.find(team => team.id === item));
                    break;
                default:
                    user.state[user.index][key] = payload[key];
                    break;
            }
            delete payload[key];
        });

        reset();
    });
}

function reset() {
    Object.keys(payload).forEach(key => delete payload[key]);
    STATE.UNSAVED_DATA.user = [];
}

function deleteUser() {
    PopUp
        .setCaption(i18n.global.t('user.remove_user'))
        .setMessage(`<p class="message"><b>${i18n.global.t('user.remove_confirm', { id: user.model.id }, 'The User with ID:{id} will be permanently deleted')}!</b></p>`)
        .setAction(() => {
            user.delete(user.model.id).then(() => {
                user.state.splice(user.index, 1);
                user.index = null;
                router.push({ name: 'Users', params: route.params });
            });

            PopUp.close();
        })
        .open(require('@/Ship/components/PopUps/Confirm'));
}

function apiKeyGen() {
    generateApiKey(user.model.id).then(response => {
        user.state[user.index].auth_key = response.auth_key;
    });
}

function closeUser() {
    let isLock = false;
    Object.keys(STATE.UNSAVED_DATA).forEach(key => {
        if (STATE.UNSAVED_DATA[key].length > 0) {
            isLock = true;
        }
    });
    isLock
        ? PopUp.open(require('@/Ship/components/PopUps/UnSavedAlert')).setCaption(i18n.global.t('message.saving_data', 'Saving data'))
        : router.push({ name: 'Users', params: route.params });
}
</script>

<template>
    <div>
        <div class="viewbar">
            <span class="close-btn" v-on:click="closeUser">
                <g-symbol name="close" width="24" height="24"/>
            </span>
        </div>
        <div class="card">
            <g-preloader-content v-bind:is-loading="user.isLoading || teams.isLoading"/>

            <g-flex align="center" justify="between" class="user-bar">
                <g-caption size="3">ID: {{ user.model.id }}</g-caption>
                <div>
                    <g-button v-if="profile.can('User Remove')" class="remove-btn with-tooltip"
                              type="button"
                              v-on:click="deleteUser"
                              v-bind:data-tooltip="$t('base.remove', 'Delete user')">
                        <g-symbol name="delete" width="18" height="18"/>
                    </g-button>
                </div>
            </g-flex>

            <div v-if="isRender" class="user custom-scrollbar">

                <!--<user-form v-if="user.model.id" v-model="user.model" permission="User Edit"/>-->

                <fieldset class="container">
                    <g-field v-model="name"
                            v-bind:label="$t('user.username', 'Username')"
                            v-bind:backlight="!!payload.name"
                            v-bind:readonly="profile.cant('User Edit')"
                            v-bind:error="errors.name">
                        <g-symbol v-if="profile.cant('User Edit')" name="lock" width="18" height="18"/>
                    </g-field>

                    <g-field v-model="email"
                             type="email"
                             v-bind:label="$t('user.email')"
                             v-bind:backlight="!!payload.email"
                             v-bind:reaonly="profile.cant('User Edit')"
                             v-bind:error="errors.email">
                        <g-symbol v-if="profile.cant('User Edit')" name="lock" width="18" height="18"/>
                    </g-field>

                    <g-select v-model="status"
                              v-bind:label="$t('setting.status', 'Status')"
                              v-bind:backlight="!!payload.status"
                              v-bind:options="statusList"
                              option-text="name"
                              option-value="name"
                              v-bind:readonly="profile.cant('User Edit')"/>

                    <password-input v-model="password"
                                    v-bind:label="$t('user.password')"
                                    v-bind:backlight="!!payload.password"
                                    v-bind:disabled="profile.cant('User Edit')"
                                    v-model:complex="isComplex"
                                    v-bind:error="errors.password"/>
                </fieldset>

                <fieldset class="roles" v-bind:class="{ backlight: !!payload.roles }">
                    <legend>{{ $t('parts.roles') }}</legend>
                    <div>
                        <g-checkbox v-for="role in settings.roles"
                                    v-model="userRoles"
                                    v-bind:value="role.id"
                                    v-bind:disabled="profile.cant('User Edit')"
                                    v-bind:key="`role-${role.id}`">
                            {{ role.name }}
                        </g-checkbox>
                    </div>
                </fieldset>

                <fieldset class="teams" v-bind:class="{ backlight: !!payload.teams }">
                    <legend>{{ $t('parts.teams') }}</legend>
                    <div>
                        <g-checkbox v-for="team in teams.state"
                                    v-model="userTeams"
                                    v-bind:value="team.id"
                                    v-bind:disabled="profile.cant('User Edit')"
                                    v-bind:key="`team-${team.id}`">
                            {{ team.name }}
                        </g-checkbox>
                    </div>
                </fieldset>

                <fieldset class="brands" v-if="profile.can('User BrandView')" v-bind:class="{ backlight: !!payload.brands }">
                    <legend>{{ $t('parts.brands') }}</legend>
                    <div>
                        <g-checkbox v-for="brand in brands.state"
                                    v-model="userBrands"
                                    v-bind:value="brand.id"
                                    v-bind:key="`brand-${brand.id}`"
                                    v-bind:disabled="profile.cant('User BrandEdit')">
                            {{ brand.name }}
                        </g-checkbox>
                    </div>
                </fieldset>

                <g-flex class="btn-bar" justify="end" gap="4">
                    <g-button class="cancel-btn" type="button" v-on:click="reset" v-bind:disabled="!Object.keys(payload).length">
                        <g-symbol name="refresh" width="18" height="18"/>
                        {{ $t('base.reset', 'Reset') }}
                    </g-button>
                    <g-button class="save-btn" type="button" v-on:click="save" v-bind:disabled="!Object.keys(payload).length">
                        <g-symbol name="save" width="18" height="18"/>
                        {{ $t('base.save', 'Save') }}
                    </g-button>
                </g-flex>

                <whitelist :user-id="user.model.id"/>

                <fieldset class="generate-key">
                    <legend>{{ $t('user.api_key') }}</legend>
                    <g-flex align="center" justify="end" gap="10">
                        <div class="api-key">
                            <g-copy v-bind:text="user.model.auth_key"/>
                        </div>
                        <g-button class="generate-btn" v-on:click="apiKeyGen" v-bind:disabled="profile.cant('User Edit')">
                            <g-symbol name="refresh"
                                      width="20"
                                      height="20"/>
                        </g-button>
                    </g-flex>
                </fieldset>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.card {
    height: 100%;
    overflow: hidden;
}

.user-bar {
    padding: 0 20px;
    height: var(--bar-height, $bar-height);
}

.user {
    padding: 20px;
    overflow: auto;
    height: calc(100% - var(--bar-height, $bar-height));
}

.btn-bar {
    padding: 10px 0;

    & > .g-button {
        padding: 0 20px;
        min-width: 120px;
    }
}

.container {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;

    & > .password-input,
    & > .g-field,
    & > .g-select {
        width: calc(50% - 10px);
    }
}

.generate-btn {
    width: 60px;
    padding: 0 20px;
    color: white !important;
    background-color: var(--save-btn-bg, $save-btn-bg);

    svg {
        fill: white;
    }
}

.remove-btn {
    width: 35px;
    height: 35px;
    color: white;
    margin: 0 2px;
    font-size: 0;
    background-color: transparent;
    fill: var(--main-text-color, $main-text-color);
    border: 1px solid var(--main-text-color, $main-text-color);
    position: relative;

    &::after {
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &::before {
        transform: translateX(-50%);
        left: 50%;
        top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &:hover {
        fill: var(--btn-primary-color, $btn-primary-color);
        border-color: var(--danger, $danger);
        background-color: var(--danger, $danger);

        &::after {
            background-color: var(--danger, $danger);
        }

        &::before {
            border-bottom-color: var(--danger, $danger);
        }
    }
}

.teams,
.roles,
.brands {
    margin: 5px 0;
    padding: 8px 10px 8px 15px;

    &:not(.backlight) {
        border: 1px solid var(--separator-color, $separator-color);
    }

    &.backlight {
        border: 1px solid var(--secondary-hover, $secondary-hover);

        & > legend {
            color: var(--secondary-hover, $secondary-hover);
        }
    }

    & > div {
        width: 560px;
        column-count: 4;
        column-gap: 10px;

        & > .g-checkbox {
            width: 100%;
            padding: 5px 0;
            display: inline-block;
        }
    }
}

.generate-key {
    padding: 8px 15px 15px;
    border: 1px solid var(--separator-color, $separator-color);

    .api-key {
        flex-grow: 1;
        text-align: right;
        padding-right: 15px;
        height: var(--field-height, $field-height);
        line-height: var(--field-height, $field-height);
        border-radius: var(--field-rounding, $field-rounding);
        border: 1px solid var(--field-border, $field-border);
        background-color: var(--field-active-bg, $field-active-bg);
    }
}
</style>