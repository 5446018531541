import { STACK, ApiClient } from '@/Ship';
import { useLeadActions } from "@lead";

const getLeadResource = useLeadActions('getLeadResource');

export default (section = null) => {

    const leads = getLeadResource();

    return STACK.push(() => {

        const params = leads.buildParams();

        if (section) {
            params.filter = section;
        }

        return ApiClient.get(`${leads.endpoint}/filters`, {
            params,
            headers: {'Ref': 'Lead Actions'}
        });
    });
}