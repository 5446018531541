<template>
    <form class="change-password" @submit.prevent>
        <div class="sub-title">{{ $t('auth.reset_sub_title_password') }}</div>
        <div>
            <g-input v-model="oldPassword"
                     v-bind:type="showOldPassword ? 'text' : 'password'"
                     v-bind:label="$t('label.old_password')"
                     name="password">
                <g-symbol v-bind:name="showOldPassword ? 'eye-open' : 'eye-close'"
                          width="25"
                          height="20"
                          v-on:click="showOldPassword = !showOldPassword"/>
            </g-input>

            <password-input v-model="newPassword" v-model:complex="isComplex" v-bind:label="$t('label.new_password')"/>

            <g-button class="save-btn"
                      v-bind:disabled="!isComplex || !oldPassword.length"
                      v-on:click="savePassword">
                <g-symbol name="import" width="24" height="24"/>
                {{ $t('button.save') }}
            </g-button>
        </div>
    </form>
</template>

<script>
import { ref } from 'vue';
import { useAuthComponents } from '@auth';
import { useProfileResources } from '@profile';

const PasswordInput = useAuthComponents('PasswordInput');

export default {
    name: 'ChangePasswordPopup',
    components: { PasswordInput },

    setup() {
        const showOldPassword = ref(false);
        const showNewPassword = ref(false);

        const oldPassword = ref('');
        const newPassword = ref('');

        const isComplex = ref(false);

        const profile = useProfileResources('Profile');

        function savePassword () {
            profile.entity.oldPassword = oldPassword.value;
            profile.entity.password = newPassword.value;

            profile.save();
        }

        return {
            isComplex,
            showOldPassword,
            showNewPassword,
            oldPassword,
            newPassword,
            savePassword
        };
    }

};
</script>

<style scoped lang="scss">
.sub-title {
    font-size: var(--text-md, $text-md);
    line-height: 140%;
    margin-bottom: 32px;
}

.g-button,
.g-input {
    width: 100%;
    margin: 16px 0;

    &:last-child {
        margin-bottom: 0;
    }
}

.save-btn {
    background-color: var(--primary, $primary);
}
</style>
