<script setup>

import { defineProps, defineEmits, computed, reactive } from 'vue';
import { useShipActions } from '@/Ship';

const props = defineProps({
    settings: {
        type: Object
    }
});

const emit = defineEmits(['save']);

const onlyNumberInput = useShipActions('onlyNumberInput');

const payload = reactive({});

function tradingComputed(propName, defaultValue = 0.001) {
    return computed({
        get: () => props.settings[propName] || defaultValue,
        set: (value) => {
            props.settings[propName] = value;
            payload[propName] = value;

            emit('save', payload, 'trading');

            return true;
        }
    });
}

const cryptoFee = tradingComputed('defaultCryptoFee');
const stockFee = tradingComputed('defaultStockFee');
const forexFee = tradingComputed('defaultForexFee');
const minDealAmountCrypto = tradingComputed('minDealAmountCrypto', 10);
const minDealAmountForex = tradingComputed('minDealAmountForex', 10);
const minDealAmountStock = tradingComputed('minDealAmountStock', 10);

</script>

<template>
    <fieldset class="trading general">
        <legend>{{$t('settings.trading_settings')}}</legend>
        <g-flex gap="10" justify="between" direction="column">
            <g-flex gap="10" class="wrap">
                <g-field v-model="cryptoFee" label="Default Crypto Fee" v-on:keypress="onlyNumberInput"/>
                <g-field v-model="minDealAmountCrypto" label="Min Crypto Deal Amount" v-on:keypress="onlyNumberInput"/>
            </g-flex>
            <g-flex gap="10" class="wrap">
                <g-field v-model="forexFee" label="Default Forex Fee" v-on:keypress="onlyNumberInput"/>
                <g-field v-model="minDealAmountForex" label="Min Forex Deal Amount" v-on:keypress="onlyNumberInput"/>
            </g-flex>
            <g-flex gap="10" class="wrap">
                <g-field v-model="stockFee" label="Default Stock Fee" v-on:keypress="onlyNumberInput"/>
                <g-field v-model="minDealAmountStock" label="Min Stock Deal Amount" v-on:keypress="onlyNumberInput"/>
            </g-flex>
        </g-flex>
    </fieldset>
</template>

<style scoped lang="scss">
.g-field {
    width: 218px;
}

.wrap {
    width: 100%;
    justify-content: space-between;
}
</style>