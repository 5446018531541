<template>
    <TeamsList/>
</template>
<script>
import { useTeamComponents } from '@team';

export default {
    name: 'Teams',
    components: {
        TeamsList: useTeamComponents('TeamsList')
    },
};

</script>
<style scoped lang="scss">
</style>