<script setup>
import { reactive, ref } from 'vue';
import {useShipActions, i18n, PopUpManager as PopUp, STATE} from '@/Ship';
import { useProfileActions } from '@profile';
import { useLeadActions } from '@lead';
import { useAssetActions } from '@asset';
import { useSettingsActions } from "@settings";

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const onlyNumberInput = useShipActions('onlyNumberInput');

let step = ref(profile.state.lead.multiplier_step || 1);

const getPairResource = useAssetActions('getPairResource');
const pairs = getPairResource();

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource();

const payload = reactive({});
const multipliers = reactive({});
const state = reactive({});

setState();

function addFieldToChangeList(field) {
    if (!STATE.UNSAVED_DATA.fees) {
        STATE.UNSAVED_DATA.fees = [];
    }
    if (!STATE.UNSAVED_DATA.fees.includes(field)) {
        STATE.UNSAVED_DATA.fees.push(field);
    }
}

const model = new Proxy(payload, {
    get: (target, key) => {
        return target[key] || state[key];
    },
    set: (target, key, value) => {
        if (value === state[key]) {
            delete payload[key];
            STATE.UNSAVED_DATA.fees.splice(STATE.UNSAVED_DATA.fees.indexOf(key), 1);
        } else {
            target[key] = value;
            addFieldToChangeList(i18n.global.t(`lead.${key}`, key));
        }

        return true;
    }
});

function save() {
    const specifics = lead.model.specifics;
    Object.keys(payload).forEach(key => {
        specifics[key] = payload[key];
    });

    lead.save(lead.model.id, {specifics}).then(() => {
        Object.assign(state, payload);
        lead.state[lead.index].specifics = specifics;
        reset();
    });
}

function reset() {
    Object.keys(payload).forEach(key => delete payload[key]);
    setState();
    STATE.UNSAVED_DATA.fees = [];
}

function setState() {
    state.crypto_fee = lead.model.specifics['crypto_fee'] || settings.general?.defaultCryptoFee || 0.001;
    state.stock_fee = lead.model.specifics['stock_fee'] || settings.general?.defaultStockFee || 0.001;
    state.forex_fee = lead.model.specifics['forex_fee'] || settings.general?.defaultForexFee || 0.001;

    state.multipliers = {};
    Object.assign(state.multipliers, lead.model.specifics['multipliers']);
}

function addMultiplier() {
    PopUp.open(require('@lead/components/PopUps/AddMultiplier'))
        .setCaption(i18n.global.t('lead.add_multiplier', 'Add Multiplier'))
        .setAction((symbol, multiplier) => {
            payload.multipliers = model.multipliers;
            model.multipliers[symbol] = (parseInt(multiplier) || 0) + '%';

            addFieldToChangeList(i18n.global.t('lead.multiplier', 'Multiplier'));

            PopUp.close();
        });
}

function incrementMultiplier(symbol) {
    payload.multipliers = model.multipliers;
    const value = parseInt(model.multipliers[symbol]);
    payload.multipliers[symbol] = `${(value + Number(step.value))}%`;

    addFieldToChangeList(i18n.global.t('lead.multiplier', 'Multiplier'));
}

function decrementMultiplier(symbol) {
    payload.multipliers = model.multipliers;
    const value = parseInt(model.multipliers[symbol]);
    payload.multipliers[symbol] = `${(value - Number(step.value))}%`;

    addFieldToChangeList(i18n.global.t('lead.multiplier', 'Multiplier'));
}

function dropMultiplier(symbol) {
    payload.multipliers = model.multipliers;
    delete state.multipliers[symbol];
    delete payload.multipliers[symbol];

    addFieldToChangeList(i18n.global.t('lead.multiplier', 'Multiplier'));
}

function saveStep() {
    profile.state.lead.multiplier_step = step.value;
    profile.saveState();
}

function getAsset(symbol) {
    return pairs.state.find(pair => pair.symbol === symbol);
}

</script>

<template>

    <div class="swap">
        <g-field v-model="model.crypto_fee"
                 v-bind:backlight="!!payload.crypto_fee"
                 v-on:keypress="onlyNumberInput($event)"
                 v-bind:label="$t('lead.crypto_fee', 'Crypto Fee')"
                 v-bind:readonly="profile.cant('Lead FeeEdit')">
            <g-symbol v-if="profile.cant('Lead FeeEdit')" name="lock" width="18" height="18"/>
        </g-field>
        <g-field v-model="model.stock_fee"
                 v-bind:backlight="!!payload.stock_fee"
                 v-on:keypress="onlyNumberInput($event)"
                 v-bind:label="$t('lead.stock_fee', 'Stock Fee')"
                 v-bind:readonly="profile.cant('Lead FeeEdit')">
            <g-symbol v-if="profile.cant('Lead FeeEdit')" name="lock" width="18" height="18"/>
        </g-field>
        <g-field v-model="model.forex_fee"
                 v-bind:backlight="!!payload.forex_fee"
                 v-on:keypress="onlyNumberInput($event)"
                 v-bind:label="$t('lead.forex_fee', 'Forex Fee')"
                 v-bind:readonly="profile.cant('Lead FeeEdit')">
            <g-symbol v-if="profile.cant('Lead FeeEdit')" name="lock" width="18" height="18"/>
        </g-field>
    </div>
    <fieldset class="multipliers">
        <div class="multipliers-action">
            <legend>
            <span>
                <span class="caption">{{ $t('lead.multipliers', 'Multipliers') }}</span>
                <button class="add-btn with-tooltip"
                        v-bind:data-tooltip="$t('lead.add_multiplier', 'Add Multiplier')"
                        v-on:click="addMultiplier">
                    <g-symbol name="plus" width="16" height="16"/>
                </button>
            </span>
            </legend>
            <label class="step">
                <span>{{ $t('lead.multiplier_step') }}:</span>
                <input v-model="step" v-on:keypress="onlyNumberInput($event)" v-on:change="saveStep"/>
            </label>
        </div>
        <div class="columns">
            <div v-for="(value, symbol) in model.multipliers" v-bind:key="symbol">
                <g-flex class="multiplier" align="center" gap="8">
                    <g-flex class="asset" align="center" gap="5">
                        <g-symbol-currency v-bind:name="getAsset(symbol)?.base?.toLowerCase()" width="28"
                                           height="28"/>
                        <span><b>{{ getAsset(symbol)?.base }}</b>{{ getAsset(symbol)?.counter }}</span>
                    </g-flex>

                    <output>{{ value }}</output>

                    <g-flex class="multiplier-controls" justify="center" wrap="wrap" direction="column" gap="2">
                        <button type="button" class="up" v-on:click="incrementMultiplier(symbol)">
                            <g-symbol name="arrow-down" width="15" height="15"/>
                        </button>
                        <button type="button" class="down" v-on:click="decrementMultiplier(symbol)">
                            <g-symbol name="arrow-down" width="15" height="15"/>
                        </button>
                        <button type="button" class="stop" v-on:click="dropMultiplier(symbol)">
                            <g-symbol name="close" width="18" height="18"/>
                        </button>
                    </g-flex>
                </g-flex>
            </div>
        </div>

        <g-flex justify="end" class="btn-bar full-row" gap="8">
            <g-flex justify="end" class="btn-bar full-row" gap="8">
                <g-button class="reset-btn" type="button" v-on:click="reset" v-bind:disabled="!Object.keys(payload).length">
                    <g-symbol name="refresh" width="18" height="18"/>
                    {{ $t('base.reset', 'Reset') }}
                </g-button>
                <g-button class="save-btn" type="button" v-on:click="save" v-bind:disabled="!Object.keys(payload).length">
                    <g-symbol name="save" width="18" height="18"/>
                    {{ $t('base.save', 'Save') }}
                </g-button>
            </g-flex>
        </g-flex>
    </fieldset>

</template>

<style scoped lang="scss">
.trading-fee,
.swap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    .g-field {
        width: 160px;
    }
}

.multipliers {
    margin-top: 20px;
    padding: 15px 0;

    &-action {
        display: flex;
        width: 100%;

        .step > input {
            width: 50px;
            height: 32px;
            margin: 0 2px;
            border-radius: 5px;
            color: var(--main-text-color, $main-text-color);
            background-color: var(--field-active-bg, $field-active-bg);
            border: 1px solid var(--separator-color, $separator-color);
        }

        legend,
        .step {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .step {
            justify-content: flex-end;

            & > span {
                margin-right: 8px;
            }
        }
    }

    .caption {
        font-size: 18px;
        font-weight: bold;
        margin-right: 10px;
    }

    .add-btn {
        font-size: 0;
        width: 30px;
        height: 30px;
        position: relative;
        background-color: transparent;
        border-radius: var(--btn-rounding, $btn-rounding);
        fill: var(--main-text-color, $main-text-color);
        border: 1px solid var(--separator-color, $separator-color);

        &:hover {
            fill: var(--title-text-color, $title-text-color);
            border: 1px solid var(--main-text-color, $main-text-color);
        }

        &::after {
            top: 50%;
            white-space: nowrap;
            transform: translateY(-50%);
            left: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size) + 2px);
        }

        &::before {
            top: 50%;
            transform: translateY(-50%);
            left: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size) + 2px);
            border-right-color: var(--tooltip-bg, $tooltip-bg);
        }
    }
}

.multiplier {
    width: 100%;
    padding: 8px 0;
    font-size: 18px;
    font-weight: bold;
    color: var(--title-text-color, $title-text-color);

    & > .asset {
        width: 116px;
        font-size: 0.9rem;
        font-weight: normal;
        color: var(--main-text-color, $main-text-color);

        b {
            color: var(--title-text-color, $title-text-color);
        }
    }

    & > output {
        flex-grow: 1;
    }
}

.multiplier-controls {
    padding: 4px;
    width: 68px;
    margin-left: 8px;
    height: 40px;

    & > button {
        padding: 0;
        font-size: 0;
        border-radius: 5px;
        border-width: 0;

        &.up {
            width: 22px;
            height: 14px;
            background-color: var(--success, $success);

            & > .g-symbol {
                transform: rotate(180deg);
            }

            &:hover {
                background-color: var(--success-hover, $success-hover);
            }
        }

        &.down {
            width: 22px;
            height: 14px;
            background-color: var(--danger, $danger);

            &:hover {
                background-color: var(--danger-hover, $danger-hover);
            }
        }

        &.stop {
            width: 36px;
            height: 36px;
            background-color: transparent;
            border: 1px solid var(--separator-color, $separator-color);

            &:hover {
                border-color: var(--danger, $danger);
            }
        }

        .g-symbol {
            margin: 0;
            fill: var(--title-text-color, $title-text-color);
        }
    }
}

.columns {
    column-count: 2;
    column-gap: 30px;
    column-rule: 1px solid var(--separator-color, $separator-color);
    max-width: 520px;

    & > div {
        width: 100%;
        display: inline-block;
    }
}

.btn-bar {
    padding-top: 20px;
}

.btn-bar > .g-button {
    padding: 0 30px;
    min-width: 130px;
    font-size: 15px !important;

    &.reset-btn {
        fill: var(--main-text-color, $main-text-color);
        color: var(--main-text-color, $main-text-color);
        background-color: transparent;
        border: 1px solid var(--separator-color, $separator-color);

        &:hover {
            color: white;
            fill: white;
            background-color: var(--separator-color, $separator-color);
        }
    }

    &.save-btn {
        background-color: var(--primary, $primary);

        &:hover {
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    & > .g-symbol {
        margin-right: 4px;
    }
}
</style>