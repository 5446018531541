<script setup>

import { computed, ref, defineProps, defineEmits, watchEffect } from 'vue';
import { i18n } from '@/Ship';
import { useCampaignActions } from '@campaign';
import { useOfferActions } from '@offer';
import { useBuyerActions } from '@buyer';
import { useUserActions } from '@user';
import { useCountryActions } from '@country';
import { useProfileActions } from '@profile';
import { useBrandActions } from '@brand';

const props = defineProps(['modelValue', 'errors']);
const emit = defineEmits(['update:modelValue']);

const getCampaignResource = useCampaignActions('getCampaignResource');
const campaign = getCampaignResource();

const getOfferResource = useOfferActions('getOfferResource');
const offers = getOfferResource();

const getBuyerResource = useBuyerActions('getBuyerResource');
const buyerResource = getBuyerResource();

const getCountryResource = useCountryActions('getCountryResource');
const countryResource = getCountryResource({ is_disabled: [0] });

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getAffiliates = useUserActions('getAffiliates');

//const languages = ref(['EN', 'RU', 'PL']);
const affiliates = ref([]);

const { getBrandResource } = useBrandActions();
const brands = getBrandResource();

const { modelValue, errors = [] } = props;

const showAllCountries = ref(false);

const countries = computed(() => {
    return showAllCountries.value
        ? countryResource.state
        : countryResource.state.filter(country => country.is_favorite || props.modelValue.geo.includes(country.code));
});
const filteredCountries = ref(countries.value)

watchEffect(() => {
    filteredCountries.value = countries.value.filter(country => {
        return country.name.toLowerCase().includes(name.toLowerCase());
    });
});
function find(name) {
    filteredCountries.value = countries.value.filter(country => {
        return i18n.global.t(`countries.${country.code}`).toLowerCase().includes(name.toLowerCase());
    });
}

const buyers = computed(() => {
    return buyerResource.state.filter(buyer => buyer.user_id === props.modelValue.affiliate_id);
});

getAffiliates().then(response => {
    affiliates.value = response.data;
});

function affiliateChoice(id) {
    if (buyers.value.length === 1) {
        buyerChoice(buyers.value[0].id);
    } else {
        buyers.index = null;
        props.modelValue.buyer_id = null;
    }
}

function buyerChoice(id) {
    buyerResource.index = buyerResource.state.findIndex(buyer => buyer.id === id);
    props.modelValue.buyer_id = buyerResource.model.id;
    props.modelValue.type = buyerResource.model.type;
    props.modelValue.cost = buyerResource.model.cost;
}
</script>

<template>
    <form class="make-campaign" v-on:submit.prevent>
        <fieldset class="fields-row">
            <g-field v-model="modelValue.name"
                     v-bind:label="$t('campaign.campaign_name', 'Campaign Name')"
                     v-bind:readonly="profile.cant('Campaign Edit')"
                     :error="errors.name">
                <g-symbol v-if="profile.cant('Campaign Make')" name="lock" width="18" height="18"/>
            </g-field>

            <g-field v-model="modelValue.source"
                     v-bind:label="$t('campaign.source', 'Source')"
                     v-bind:readonly="profile.cant('Campaign Edit')">
                <g-symbol v-if="profile.cant('Campaign Make')" name="lock" width="18" height="18"/>
            </g-field>
        </fieldset>

        <fieldset class="fields-row">
            <g-field v-model="modelValue.alias"
                     v-bind:label="$t('campaign.alias', 'Alias')"
                     v-bind:readonly="profile.cant('Campaign Edit')">
                <g-symbol v-if="profile.cant('Campaign Make')" name="lock" width="18" height="18"/>
            </g-field>

            <g-select v-model="modelValue.offer_id"
                      v-bind:label="$t('campaign.offer', 'Offer')"
                      v-bind:options="offers.state.map(item => {return { id: item.id, name: `${item.language} &nbsp; ${item.name}` }})"
                      option-text="name"
                      option-value="id"
                      v-bind:readonly="profile.cant('Campaign Edit')"/>
        </fieldset>

        <!--
        <fieldset class="alias-brand">
            <g-combobox v-model="modelValue.language"
                        v-bind:label="$t('base.language', 'Language')"
                        v-bind:options="languages"
                        v-bind:disabled="profile.cant('Campaign Edit')"/>

            <g-select v-model="modelValue.brand_id"
                      v-bind:label="$t('campaign.brands', 'Brand')"
                      v-bind:options="brands.state"
                      option-text="name"
                      option-value="id"
                      v-bind:readonly="profile.cant('Campaign Edit')"/>
        </fieldset>
        -->

        <fieldset class="geo custom-scrollbar">
            <legend>{{ $t('campaign.geo') }}</legend>
            <g-search v-if="showAllCountries" :label="$t('base.search')" @find="find" @drop="filteredCountries = countries"/>
            <g-flex direction="column" align="center">
                <div class="countries">
                    <g-checkbox v-model="modelValue.geo"
                                v-for="country in filteredCountries"
                                v-bind:key="`country-${country.code}`"
                                v-bind:value="country.code"
                                v-bind:disabled="profile.cant('Campaign Edit')">
                        {{ $t(`countries.${country.code}`) }}
                    </g-checkbox>
                </div>

                <button type="button"
                        class="show-countries"
                        v-bind:class="{hidden: showAllCountries}"
                        v-on:click="showAllCountries = !showAllCountries">
                    {{ showAllCountries ? $t('countries.hide_fav', 'Hide not favorite Countries') : $t('countries.show_all', 'Show all Countries') }}
                </button>
            </g-flex>
        </fieldset>

        <fieldset class="fields-row">
            <g-select v-model="modelValue.affiliate_id"
                      v-bind:label="$t('campaign.affiliate', 'Affiliate')"
                      v-bind:options="affiliates"
                      option-text="name"
                      option-value="id"
                      v-on:select="affiliateChoice"
                      v-bind:readonly="profile.cant('Campaign Make')"/>

            <g-select v-model="modelValue.buyer_id"
                      v-bind:label="$t('campaign.buyer', 'Media Buyer')"
                      v-bind:options="buyers"
                      option-text="name"
                      option-value="id"
                      v-on:select="buyerChoice"
                      v-bind:readonly="profile.cant('Campaign Make')"/>
            <!--v-bind:disabled="!buyers.state.length && !modelValue.buyer_id"-->
        </fieldset>

        <fieldset class="fields-row">
            <g-select v-model="modelValue.type"
                      v-bind:label="$t('campaign.type', 'Type')"
                      v-bind:options="[{value: 'CPA'}, {value: 'CPL'}]"
                      option-text="value"
                      option-value="value"
                      v-bind:readonly="profile.cant('Campaign Make')"/>
            <g-field v-model="modelValue.cost"
                     v-bind:label="$t('campaign.cost', 'Cost')"
                     v-bind:readonly="profile.cant('Campaign Make')">
                <g-symbol v-if="profile.cant('Campaign Make')" name="lock" width="18" height="18"/>
            </g-field>
        </fieldset>
    </form>
</template>

<style scoped lang="scss">
.make-campaign {
    max-width: 100%;
    container-type: inline-size;
}

.fields-row {
    padding-top: 8px;
}

.geo {
    margin-top: 10px;
    padding: 10px 20px 0;
    border-right: var(--bar-rounding, $bar-rounding);
    border: 1px solid var(--separator-color, $separator-color);

    .countries {
        column-count: 3;
        padding-bottom: 5px;
        flex-grow: 1;
        height: 180px;
        overflow-y: auto;
        width: 100%;

        & > .g-checkbox {
            display: inline-block;
            overflow: hidden;
            width: 100%;
            max-width: 120px;
            white-space: nowrap;
        }
    }
}

.show-countries {
    font-size: 0.9rem;
    background-color: transparent;

    &:not(.hidden) {
        color: var(--success-hover, $success-hover);

        &::after {
            content: ' \2193';
        }
    }

    &.hidden {
        color: var(--danger-hover, $danger-hover);

        &::after {
            content: ' \2A2F';
        }
    }
}

.g-search {
    margin-bottom: 10px;
}

@container (min-width: 360px) {
    .fields-row {
        gap: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;

        & > .g-field,
        & > .g-select,
        & > .g-combobox {
            width: calc(50% - 5px);
        }
    }
}
</style>