import { ApiClient } from '@/Ship';
//import { useAuthActions } from "@/Containers/Authentication/Authentication";
//import { useProfileResources } from "@/Containers/Profile/Profile";

export default function () {
    if (localStorage.access_token) {
        //        const { Profile } = useProfileResources();
        //        const profile = Profile.getInstance();
        if (ApiClient.expiresIn > 180000) {
            ApiClient.setToken(localStorage.access_token);
            //            profile.reload();
        } else {
            ApiClient.isTokenExpired = true;
            //            const { refreshToken } = useAuthActions();
            //            refreshToken().then(() => {
            //                profile.reload();
            //            })
        }
    }
}
