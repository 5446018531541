<script setup>
import { reactive, watch } from 'vue';
import { useLeadActions } from '@lead';
import { useProfileActions } from '@profile';

const {
    getFilters,
    getLeadResource
} = useLeadActions();

const leads = getLeadResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

if (!profile.state.lead) {
    profile.state.lead = {
        columns: [],
        filters: {},
        sortBy: 'id',
        sortDir: 'desc',
        perPage: 25,
    };
}

const filterset = reactive({});
const isLoading = reactive({});

function onExpand(section) {
    isLoading[section] = true;
    getFilters(section).then((response) => {
        filterset[section] = response.data[section];
        delete isLoading[section];
    });
}

watch(() => leads.isLoading, () => {
    Object.keys(filterset).forEach(key => delete filterset[key]);
});

</script>

<template>
<div class="sidebar">
    <!-- IS FRD -->
    <div class="filterset" v-bind:class="{ expanded: filterset.is_ftd }">
        <g-caption size="4" weight="600">
            Is FTD
            <span v-on:click="onExpand('is_ftd')">
                <g-symbol v-if="isLoading.is_ftd || filterset.is_ftd?.length"
                          v-bind:class="{ loading: isLoading.is_ftd }"
                          name="refresh"
                          width="18"
                          height="18"/>
                <g-symbol v-else name="plus" width="14" height="14"/>
            </span>
        </g-caption>
        <ul>
            <li v-for="filter in filterset.is_ftd" v-bind:key="`is_ftd-${filter.value}`">
                <span class="value">{{ filter.key }}</span>
                <span class="count">{{ filter.count }}</span>
            </li>
        </ul>
    </div>

    <!-- CONVERSION STATUS -->
    <div class="filterset" v-bind:class="{ expanded: filterset.conversion_status }">
        <g-caption size="4" weight="600">
            {{ $t('filters.conversion_status', 'Conversion status') }}
            <span v-on:click="onExpand('conversion_status')">
                <g-symbol v-if="isLoading.conversion_status || filterset.conversion_status?.length"
                          v-bind:class="{ loading: isLoading.conversion_status }"
                          name="refresh"
                          width="18"
                          height="18"/>
                <g-symbol v-else name="plus" width="14" height="14"/>
            </span>
        </g-caption>
        <ul class="custom-scrollbar">
            <li v-for="filter in filterset.conversion_status" v-bind:key="`conversion_status-${filter.value}`">
                <span class="value">{{ filter.key }}</span>
                <span class="count">{{ filter.count }}</span>
            </li>
        </ul>
    </div>

    <!-- RETENTION STATUS -->
    <div class="filterset" v-bind:class="{ expanded: filterset.retention_status }">
        <g-caption size="4" weight="600">
            {{ $t('filters.retention_status', 'Retention status') }}
            <span v-on:click="onExpand('retention_status')">
                <g-symbol v-if="isLoading.retention_status || filterset.retention_status?.length"
                          v-bind:class="{ loading: isLoading.retention_status }"
                          name="refresh"
                          width="18"
                          height="18"/>
                <g-symbol v-else name="plus" width="14" height="14"/>
            </span>
        </g-caption>
        <ul class="custom-scrollbar">
            <li v-for="filter in filterset.retention_status" v-bind:key="`retention_status-${filter.value}`">
                <span class="value">{{ filter.key }}</span>
                <span class="count">{{ filter.count }}</span>
            </li>
        </ul>
    </div>

    <!-- TEAM -->
    <div class="filterset" v-bind:class="{ expanded: filterset.teams }">
        <g-caption size="4" weight="600">
            {{ $t('filters.teams', 'Teams') }}
            <span v-on:click="onExpand('teams')">
                <g-symbol v-if="isLoading.teams || filterset.teams?.length"
                          v-bind:class="{ loading: isLoading.teams }"
                          name="refresh"
                          width="18"
                          height="18"/>
                <g-symbol v-else name="plus" width="14" height="14"/>
            </span>
        </g-caption>
        <ul class="custom-scrollbar">
            <li v-for="filter in filterset.teams" v-bind:key="`teams-${filter.value}`">
                <span class="value">{{ filter.key }}</span>
                <span class="count">{{ filter.count }}</span>
            </li>
        </ul>
    </div>

    <!-- SALE -->
    <div class="filterset" v-bind:class="{ expanded: filterset.sales }">
        <g-caption size="4" weight="600">
            {{ $t('filters.sales', 'Sale agent') }}
            <span v-on:click="onExpand('sales')">
                <g-symbol v-if="isLoading.sales || filterset.sales?.length"
                          v-bind:class="{ loading: isLoading.sales }"
                          name="refresh"
                          width="18"
                          height="18"/>
                <g-symbol v-else name="plus" width="14" height="14"/>
            </span>
        </g-caption>
        <ul class="custom-scrollbar">
            <li v-for="filter in filterset.sales" v-bind:key="`sales-${filter.value}`">
                <span class="value">{{ filter.key }}</span>
                <span class="count">{{ filter.count }}</span>
            </li>
        </ul>
    </div>

    <!-- RETENTION -->
    <div class="filterset" v-bind:class="{ expanded: filterset.retentions }">
        <g-caption size="4" weight="600">
            {{ $t('filters.retentions', 'Retention agent') }}
            <span v-on:click="onExpand('retentions')">
                <g-symbol v-if="isLoading.retentions || filterset.retentions?.length"
                          v-bind:class="{ loading: isLoading.retentions }"
                          name="refresh"
                          width="18"
                          height="18"/>
                <g-symbol v-else name="plus" width="14" height="14"/>
            </span>
        </g-caption>
        <ul class="custom-scrollbar">
            <li v-for="filter in filterset.retentions" v-bind:key="`retentions-${filter.value}`">
                <span class="value">{{ filter.key }}</span>
                <span class="count">{{ filter.count }}</span>
            </li>
        </ul>
    </div>

    <!-- COUNTRY -->
    <div class="filterset" v-bind:class="{ expanded: filterset.countries }">
        <g-caption size="4" weight="600">
            {{ $t('filters.countries', 'Countries') }}
            <span v-on:click="onExpand('countries')">
                <g-symbol v-if="isLoading.countries || filterset.countries?.length"
                          v-bind:class="{ loading: isLoading.countries }"
                          name="refresh"
                          width="18"
                          height="18"/>
                <g-symbol v-else name="plus" width="14" height="14"/>
            </span>
        </g-caption>
        <ul class="custom-scrollbar">
            <li v-for="filter in filterset.countries" v-bind:key="`countries-${filter.value}`">
                <span class="value">{{ filter.key }}</span>
                <span class="count">{{ filter.count }}</span>
            </li>
        </ul>
    </div>

    <!-- LANGUAGE -->
    <div class="filterset" v-bind:class="{ expanded: filterset.language }">
        <g-caption size="4" weight="600">
            {{ $t('filters.languages', 'Languages') }}
            <span v-on:click="onExpand('language')">
                <g-symbol v-if="isLoading.language || filterset.language?.length"
                          v-bind:class="{ loading: isLoading.language }"
                          name="refresh"
                          width="18"
                          height="18"/>
                <g-symbol v-else name="plus" width="14" height="14"/>
            </span>
        </g-caption>
        <ul class="custom-scrollbar">
            <li v-for="filter in filterset.language" v-bind:key="`language-${filter.value}`">
                <span class="value">{{ filter.key }}</span>
                <span class="count">{{ filter.count }}</span>
            </li>
        </ul>
    </div>

    <!-- TIMEZONE -->
    <div class="filterset" v-bind:class="{ expanded: filterset.timezone }">
        <g-caption size="4" weight="600" v-on:click="onExpand('timezone')">
            {{ $t('filters.timezone', 'Timezone') }}
            <span v-on:click="onExpand('timezone')">
                <g-symbol v-if="isLoading.timezone || filterset.timezone?.length"
                          v-bind:class="{ loading: isLoading.timezone }"
                          name="refresh"
                          width="18"
                          height="18"/>
                <g-symbol v-else name="plus" width="14" height="14"/>
            </span>
        </g-caption>
        <ul class="custom-scrollbar">
            <li v-for="filter in filterset.timezone" v-bind:key="`timezone-${filter.value}`">
                <span class="value">{{ filter.key || 'Undetermined' }}</span>
                <span class="count">{{ filter.count }}</span>
            </li>
        </ul>
    </div>

    <!-- CAMPAIGN -->
    <div class="filterset" v-bind:class="{ expanded: filterset.campaigns }">
        <g-caption size="4" weight="600" v-on:click="onExpand('campaigns')">
            {{ $t('filters.campaigns', 'Campaigns') }}
            <span v-on:click="onExpand('campaigns')">
                <g-symbol v-if="isLoading.campaigns || filterset.campaigns?.length"
                          v-bind:class="{ loading: isLoading.campaigns }"
                          name="refresh"
                          width="18"
                          height="18"/>
                <g-symbol v-else name="plus" width="14" height="14"/>
            </span>
        </g-caption>
        <ul class="custom-scrollbar">
            <li v-for="filter in filterset.campaigns" v-bind:key="`campaigns-${filter.name}`">
                <span class="value">{{ filter.key }}</span>
                <span class="count">{{ filter.count }}</span>
            </li>
        </ul>
    </div>

    <!-- AFFILIATE -->
    <div class="filterset" v-bind:class="{ expanded: filterset.affiliates }">
        <g-caption size="4" weight="600">
            {{ $t('filters.affiliates', 'Affiliates') }}
            <span v-on:click="onExpand('affiliates')">
                <g-symbol v-if="isLoading.affiliates || filterset.affiliates?.length"
                          v-bind:class="{ loading: isLoading.affiliates }"
                          name="refresh"
                          width="18"
                          height="18"/>
                <g-symbol v-else name="plus" width="14" height="14"/>
            </span>
        </g-caption>
        <ul class="custom-scrollbar">
            <li v-for="filter in filterset.affiliates" v-bind:key="`affiliates-${filter.value}`">
                <span class="value">{{ filter.key }}</span>
                <span class="count">{{ filter.count }}</span>
            </li>
        </ul>
    </div>

    <!-- ACCOUNT LEVEL -->
    <div class="filterset" v-bind:class="{ expanded: filterset.account_level }">
        <g-caption size="4" weight="600">
            {{ $t('filters.account_level', 'Account level') }}
            <span v-on:click="onExpand('account_level')">
                <g-symbol v-if="isLoading.account_level || filterset.account_level?.length"
                          v-bind:class="{ loading: isLoading.account_level }"
                          name="refresh"
                          width="18"
                          height="18"/>
                <g-symbol v-else name="plus" width="14" height="14"/>
            </span>
        </g-caption>
        <ul class="custom-scrollbar">
            <li v-for="filter in filterset.account_level" v-bind:key="`account_level-${filter.value}`">
                <span class="value">{{ filter.key || 'Undetermined' }}</span>
                <span class="count">{{ filter.count }}</span>
            </li>
        </ul>
    </div>

    <!-- GENDER -->
    <div class="filterset" v-bind:class="{ expanded: filterset.gender }">
        <g-caption size="4" weight="600" v-on:click="onExpand('gender')">
            {{ $t('filters.gender', 'Gender') }}
            <span v-on:click="onExpand('gender')">
                <g-symbol v-if="isLoading.gender || filterset.gender?.length"
                          v-bind:class="{ loading: isLoading.gender }"
                          name="refresh"
                          width="18"
                          height="18"/>
                <g-symbol v-else name="plus" width="14" height="14"/>
            </span>
        </g-caption>
        <ul>
            <li v-for="filter in filterset.gender" v-bind:key="`gender-${filter.value}`">
                <span class="value">{{ filter.key || 'Undetermined' }}</span>
                <span class="count">{{ filter.count }}</span>
            </li>
        </ul>
    </div>

    <!-- MARITAL STATUS -->
    <div class="filterset" v-bind:class="{ expanded: filterset.marital_status }">
        <g-caption size="4" weight="600" v-on:click="onExpand('marital_status')">
            {{ $t('filters.marital_status', 'Marital status') }}
            <span v-on:click="onExpand('marital_status')">
                <g-symbol v-if="isLoading.marital_status || filterset.marital_status?.length"
                          v-bind:class="{ loading: isLoading.marital_status }"
                          name="refresh"
                          width="18"
                          height="18"/>
                <g-symbol v-else name="plus" width="14" height="14"/>
            </span>
        </g-caption>
        <ul>
            <li v-for="filter in filterset.marital_status" v-bind:key="`marital_status-${filter.value}`">
                <span class="value">{{ filter.key || 'Undetermined' }}</span>
                <span class="count">{{ filter.count }}</span>
            </li>
        </ul>
    </div>

    <!-- Is TEST -->
    <div class="filterset" v-bind:class="{ expanded: filterset.is_test }">
        <g-caption size="4" weight="600" v-on:click="onExpand('is_test')">
            {{ $t('filters.is_test', 'Is Test') }}
            <span v-on:click="onExpand('is_test')">
                <g-symbol v-if="isLoading.is_test || filterset.is_test?.length"
                          v-bind:class="{ loading: isLoading.is_test }"
                          name="refresh"
                          width="18"
                          height="18"/>
                <g-symbol v-else name="plus" width="14" height="14"/>
            </span>
        </g-caption>
        <ul>
            <li v-for="filter in filterset.is_test" v-bind:key="`is_test-${filter.key}`">
                <span class="value">{{ filter.key }}</span>
                <span class="count">{{ filter.count }}</span>
            </li>
        </ul>
    </div>

    <!-- KYC STATUS -->
    <div class="filterset" v-bind:class="{ expanded: filterset.kyc_status }">
        <g-caption size="4" weight="600" v-on:click="onExpand('kyc_status')">
            {{ $t('filters.kyc_status', 'KYC status') }}
            <span v-on:click="onExpand('kyc_status')">
                <g-symbol v-if="isLoading.kyc_status || filterset.kyc_status?.length"
                          v-bind:class="{ loading: isLoading.kyc_status }"
                          name="refresh"
                          width="18"
                          height="18"/>
                <g-symbol v-else name="plus" width="14" height="14"/>
            </span>
        </g-caption>
        <ul class="custom-scrollbar">
            <li v-for="filter in filterset.kyc_status" v-bind:key="`kyc_status-${filter.value}`">
                <span class="value">{{ filter.key || 'Undetermined' }}</span>
                <span class="count">{{ filter.count }}</span>
            </li>
        </ul>
    </div>

    <!-- PHONE CONFIRMED -->
    <div class="filterset" v-bind:class="{ expanded: filterset.phone_confirmed }">
        <g-caption size="4" weight="600" v-on:click="onExpand('phone_confirmed')">
            {{ $t('filters.phone_confirmed', 'Phone confirmed') }}
            <span v-on:click="onExpand('phone_confirmed')">
                <g-symbol v-if="isLoading.phone_confirmed || filterset.phone_confirmed?.length"
                          v-bind:class="{ loading: isLoading.phone_confirmed }"
                          name="refresh"
                          width="18"
                          height="18"/>
                <g-symbol v-else name="plus" width="14" height="14"/>
            </span>
        </g-caption>
        <ul>
            <li v-for="filter in filterset.phone_confirmed" v-bind:key="filter.key">
                <span class="value">{{ filter.key }}</span>
                <span class="count">{{ filter.count }}</span>
            </li>
        </ul>
    </div>

    <!-- EMAIL CONFIRMED -->
    <div class="filterset" v-bind:class="{ expanded: filterset.email_confirmed }">
        <g-caption size="4" weight="600" v-on:click="onExpand('email_confirmed')">
            {{ $t('filters.email_confirmed', 'Email confirmed') }}
            <span v-on:click="onExpand('email_confirmed')">
                <g-symbol v-if="isLoading.email_confirmed || filterset.email_confirmed?.length"
                          v-bind:class="{ loading: isLoading.email_confirmed }"
                          name="refresh"
                          width="18"
                          height="18"/>
                <g-symbol v-else name="plus" width="14" height="14"/>
            </span>
        </g-caption>
        <ul>
            <li v-for="filter in filterset.email_confirmed" v-bind:key="filter.key">
                <span class="value">{{ filter.key }}</span>
                <span class="count">{{ filter.count }}</span>
            </li>
        </ul>
    </div>
</div>
</template>

<style lang="scss" scoped>
.sidebar {
    padding: 20px 0;
}

.filterset {
    padding: 0 10px;
    position: relative;
    margin-bottom: 6px;

    &:not(.expanded) {
        & > ul {
            max-height: 0;
            padding: 0 10px 0 5px;
        }
    }

    &.expanded {
        & > ul {
            max-height: 320px;
            padding: 16px 10px 16px 5px;
        }
    }

    & > .g-caption {
        padding-right: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .g-symbol {
            cursor: pointer;
            fill: #777777;

            &.loading {
                animation: loading 2s;
                fill: var(--primary-hover, $primary-hover);
            }
        }
    }

    & > ul {
        overflow: auto;
        transition: max-height 0.3s, padding 0.3s;
    }
}

li {
    margin: 4px 0;
    display: flex;
    justify-content: space-between;

    & > .value {
        overflow: hidden;
        max-width: 168px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    & > .count {
        color: #777777;
    }
}

@keyframes loading {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>