import { useActivityResources } from '@activity';
import {ResourcesManager} from "@/Ship";

export default (params, reload = false, referer = null) => {
    const activity = useActivityResources('Activity');
    if (reload || (!activity.isLoaded && !activity.isLoading)) {
        activity.setFilters(params.filters);
        activity.load(null, {}, referer);
    }

    ResourcesManager.addResource(activity, 'Activity');

    return activity;
};
