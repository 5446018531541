<template>
    <div>
        <h2>Permissions</h2>
    </div>
</template>

<script>
export default {
    name: 'Permissions'
};
</script>

<style lang="scss" scoped>

</style>