<script setup>
import {defineProps, defineEmits, ref, computed, watch, reactive} from 'vue';
import { useAuthComponents } from '@auth';
import { useSettingsResources } from '@settings';
import { useProfileActions } from '@profile';

const emit = defineEmits(['update:is-complex']);
const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
    },
    permission: {
        type: String,
        default: 'User Edit'
    },
    errors: {
        type: Object,
        default: () => []
    },
});

const isComplex = ref(false);
const payload = reactive({});

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const PasswordInput = useAuthComponents('PasswordInput');

const settings = useSettingsResources('Settings');
settings.load('roles');

const statusList = ref([{ name: 'Active' }, { name: 'Suspended' }, { name: 'Deleted' }]);

const userRoles = computed({
    get() {
        return props.modelValue.roles.map(role => role.id || role);
    },
    set(value) {
        props.modelValue.roles = settings.roles.filter(item => value.includes(item.id));
    },
});

watch(() => isComplex.value, (newValue) => {
    emit('update:is-complex', newValue);
});
</script>

<template>
    <form class="make-user" v-on:submit.prevent>
        <fieldset class="email-name-passwd">
            <g-field v-model="modelValue.email"
                     type="email"
                     v-bind:label="$t('user.email')"
                     v-bind:reaonly="profile.cant(permission)"
                     :error="errors?.email">
                <g-symbol v-if="profile.cant(permission)" name="lock" width="18" height="18"/>
            </g-field>

            <g-select v-model="modelValue.status"
                      v-bind:label="$t('setting.status', 'Status')"
                      v-bind:options="statusList"
                      option-text="name"
                      option-value="name"
                      v-bind:readonly="profile.cant(permission)"/>

            <g-field v-model="modelValue.name"
                     v-bind:label="$t('user.username', 'Username')"
                     v-bind:readonly="profile.cant(permission)"
                     :error="errors?.name">
                <g-symbol v-if="profile.cant(permission)" name="lock" width="18" height="18"/>
            </g-field>

            <password-input v-model="modelValue.password"
                            v-bind:label="$t('user.password')"
                            v-bind:disabled="profile.cant(permission)"
                            v-model:complex="isComplex"
                            :error="errors?.password"/>
        </fieldset>
        <fieldset class="roles">
            <legend>{{ $t('parts.roles') }}</legend>
            <div>
                <g-checkbox v-model="userRoles"
                            v-for="role in settings.roles"
                            v-bind:key="`role-${role.id}`"
                            v-bind:value="role.id"
                            v-bind:disabled="profile.cant(permission)">
                    {{ role.name }}
                </g-checkbox>
            </div>
        </fieldset>
    </form>
</template>

<style scoped lang="scss">
.make-user {
    container-type: inline-size;
}

@mixin flex-fieldset {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    & > .g-field,
    & > .g-select,
    & > .g-combobox {
        width: calc(50% - 5px);
    }
}

@container (min-width: 545px) {
    .roles > div {
        column-count: 4;
    }
}

@container (max-width: 544px) {
    .roles > div {
        column-count: 3;
    }
}

.email-name-passwd {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;

    & > .password-input,
    & > .g-field,
    & > .g-select {
        width: calc(50% - 10px);
    }
}

.roles {
    padding: 8px 15px 8px;
    border: 1px solid var(--separator-color, $separator-color);

    & > div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        & > .g-checkbox {
            width: 100%;
            display: inline-block;
            margin: 4px 0;
        }
    }
}

.show-countries {
    font-size: 0.9rem;
    background-color: transparent;

    &:not(.hidden) {
        color: var(--success-hover, $success-hover);

        &::after {
            content: ' ↓';
        }
    }

    &.hidden {
        color: var(--danger-hover, $danger-hover);

        &::after {
            content: ' ⨯';
        }
    }
}

.g-field,
.g-select,
.g-combobox,
.password-input {
    padding-top: 16px;
    width: calc(50% - 22px);
}
</style>
