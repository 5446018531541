<script setup>
import { computed, onMounted, reactive, ref } from 'vue';
import { useProfileActions } from '@profile';
import { preloaderAligner } from '@/Ship/components/global/preloaders/scrollHellper';
import { useSettingsActions } from '@settings';
import { i18n } from '@/Ship';

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const payload = reactive({
    name: '',
    target: ''
});

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource('statuses');
const errorText = ref('');

const target = ref('All');
const targets = [
    { name: 'conversion' },
    { name: 'retention' },
    { name: 'KYC' },
    { name: 'deposit' },
    { name: 'withdraw' }
];

const targetFilters = [
    { name: 'All' },
    ...targets
];

const statuses = computed(() => {
    return target.value !== 'All'
        ? settings.statuses.filter(status => status.target === target.value.toLowerCase())
        : settings.statuses;
});

const setTarget = () => {
    if (target.value !== 'All') {
        payload.target = target.value;
    }
};

async function removeStatus(id) {
    const index = settings.statuses.findIndex(item => item.id === id);
    if (index !== -1) {

        settings.delete('status', { status_id: settings.statuses[index].id }).then(() => {
            settings.statuses.splice(index, 1);
        });
    }
}

async function addStatus() {
    if (payload.name && payload.target) {
        const capitalizedStatusName = payload.name.charAt(0).toUpperCase() + payload.name.slice(1);
        const result = settings.statuses.find(item => item.name === payload.name);

        if (!result) {
            settings.create({
                name: capitalizedStatusName,
                target: payload.target,
                section: 'status'
            }).then(response => {
                settings.statuses.push(response);
                payload.name = '';
                payload.target = '';
            }).catch(error => {
                errorText.value = error.response.data.description.name[0];
            });
        } else {
            errorText.value = i18n.global.t('settings.status_name_already_exist');
        }
    }
}

onMounted(() => {
    preloaderAligner('.environment');
});
</script>

<template>
    <fieldset class="statuses">
        <legend>{{ $t('settings.statuses') }}</legend>

        <g-select v-model="target"
                  v-on:change="setTarget"
                  class="filter"
                  v-bind:label="$t('settings.target')"
                  v-bind:options="targetFilters"
                  text-transform="capitalize"
                  option-text="name"
                  option-value="name"/>

        <g-preloader-content :is-loading="settings.isLoading"/>
        <div class="container custom-scrollbar">
            <table>
                <tbody>
                <tr v-for="(status, i) in statuses" v-bind:key="`status-${status.id}`" class="row">
                    <td class="cell index">{{ i + 1 }}</td>
                    <td class="cell status">{{ status.name }}</td>
                    <td class="cell target">{{ status.target }}</td>
                    <td class="cell actions" valign="middle">
                        <g-symbol name="close"
                                  width="16"
                                  height="16"
                                  class="remove-btn"
                                  v-on:click="removeStatus(status.id)"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <form v-on:submit.prevent="addStatus">
            <g-flex align="end" justify="flex-start" gap="5">
                <g-select v-model="payload.target"
                          v-on:change="errorText = ''"
                          v-bind:label="$t('settings.target')"
                          v-bind:options="targets"
                          text-transform="capitalize"
                          option-text="name"
                          option-value="name"/>
                <g-field v-model="payload.name"
                         v-on:change="errorText = ''"
                         v-bind:label="$t('settings.new_status')"
                         v-bind:disabled="!payload.target || profile.cant('Settings StatusEdit')"
                         :error="errorText"
                         v-on:keyup.enter="addStatus"/>

                <g-button type="button"
                          @click="addStatus"
                          class="add-btn"
                          v-bind:disabled="!payload.name || profile.cant('Settings StatusEdit')">
                    <g-symbol name="send" width="20" height="20"/>
                </g-button>
            </g-flex>
        </form>
    </fieldset>
</template>


<style scoped lang="scss">
.statuses {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 15px 15px;
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 1px solid var(--separator-color, $separator-color);
    max-height: 566px;

    & > .container {
        flex-grow: 1;
    }
}

table {
    width: 100%;
    margin-top: 10px;
}

.row {
    &:nth-child(odd) {
        background-color: var(--env-bg, $env-bg);
    }

    &:nth-child(even) {
        background-color: var(--bar-bg, $bar-bg);
    }

    & > .cell {
        padding: 8px;

        &:last-child {
            text-align: right;
            font-size: 0;
        }
    }
}

.remove-btn {
    cursor: pointer;
    fill: var(--danger, $danger);

    &:hover {
        fill: var(--danger-hover, $danger-hover);
    }
}

form {
    margin-top: 8px;

    .g-select {
        width: 150px;
    }
}

.add-btn {
    color: white;
    padding: 0 20px;
    background-color: var(--primary, $primary);
    fill: var(--btn-primary-color, $btn-primary-color);

    &:hover {
        background-color: var(--primary-hover, $primary-hover);
    }
}
</style>