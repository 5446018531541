<template>
    <div class="finances environment">
        <indexbar v-model:part="part" v-bind:total="total"/>
        <component v-bind:is="part" v-model:total="total"/>
    </div>
</template>

<script>

import { ref } from 'vue';
import { useFinanceComponents } from '@finance';

const {
    Indexbar,
    Withdrawals,
    Replenishments
} = useFinanceComponents();

export default {
    name: 'Environment',
    components: {
        Indexbar,
        Withdrawals,
        Replenishments
    },

    setup() {
        const total = ref({ sum: 0, count: 0 });
        const part = ref('Replenishments');

        return {
            part,
            total,
        };
    }
};
</script>

<style lang="scss" scoped>
.environment {
    height: 100%;
    padding: 0 10px;
    position: relative;
    background-color: var(--env-bg, $env-bg);
}

.indexbar {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--separator-color, $separator-color);
}
</style>
