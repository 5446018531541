<template>
    <form class="sign-up" v-on:submit.prevent="registration">
        <div>
            <g-caption size="4" weight="700">{{ $t('auth.sign_up') }}</g-caption>
            <div class="subtitle">{{ $t('auth.sign_sub_title') }}</div>
        </div>

        <div>
            <g-field name="first_name"
                     v-model="firstName"
                     v-bind:label="$t('profile.first_name')"
                     @keyup="verifyFirstName"
                     :error="firstNameError"/>
            <g-field name="last_name"
                     v-model="lastName"
                     v-bind:label="$t('profile.last_name')"
                     @keyup="verifyLastName"
                     :error="lastNameError"/>

            <g-phone-input v-if="countries.length"
                           v-model="phone"
                           v-model:country="country"
                           v-bind:label="$t('profile.phone')"
                           v-bind:countries="countries"/>

            <g-field name="email"
                     v-model="email"
                     v-bind:label="$t('profile.email')"
                     type="email"
                     @keyup="verifyEmail"
                     :error="emailError"/>
            <hr class="separator">
            <div class="subtitle">{{ $t('auth.reset_sub_title_password') }}</div>
            <password-input v-model="password"
                            v-model:complex="isComplex"
                            v-bind:label="$t('profile.password')"/>
        </div>
        <g-button v-bind:disabled="!isAllFill">
            {{ $t('auth.create_account') }}
        </g-button>

        <div>
            {{ $t('auth.sign_have_account') }}
            <router-link :to="{ name: 'SignIn', params: { locale: $i18n.locale } }" tag="a" class="link">
                {{ $t('auth.sign_in') }}
            </router-link>
        </div>
    </form>
</template>

<script>
import { useRouter } from 'vue-router';
import { computed, onMounted, ref } from 'vue';
import { useAuthComponents, useAuthActions } from '@auth';
import { STACK, ApiClient, i18n, PopUpManager as PopUp, verifyField } from '@/Ship';

const { signUp, signIn } = useAuthActions();

export default {
    name: 'SignUp',
    components: {
        PasswordInput: useAuthComponents('PasswordInput')
    },
    setup() {
        const firstName = ref('');
        const lastName = ref('');
        const phone = ref('');
        const email = ref('');
        const password = ref('');
        const country = ref(null);

        const isComplex = ref(false);
        const isTermsAgree = ref(false);

        let firstNameError = ref('');
        let lastNameError = ref('');
        let emailError = ref('');
        let passwordError = ref('');
        const countries = ref([]);

        const router = useRouter();

        onMounted(() => {
            STACK.push(() => ApiClient.get('/countries')).then(data => {
                countries.value = data;
            });
        });

        function registration() {
            if (verifyAllFields) {
                signUp({
                    first_name: firstName.value,
                    last_name: lastName.value,
                    email: email.value,
                    phone: phone.value,
                    country: country.value,
                    password: password.value,
                }).then(() => {
                    signIn(email.value, password.value).then(() => {
                        router.push({ name: 'Welcome', params: router.currentRoute.value.params });
                    }).catch(() => {
                        PopUp.open(require('@auth/components/signUpInfoPonpUp'))
                        .setCaption(i18n.global.t('base.info'))
                        .setPayload({
                            payload:
                                {
                                    mainText: i18n.global.t('base.something_wrong'),
                                    additionText: i18n.global.t('auth.user_already_registered')
                                }
                        });
                    });
                });
            }
        }

        const verifyFirstName = () => {
            return verifyField(firstName.value, ['require', 'minLength:2', 'alpha'], firstNameError);
        };

        const verifyLastName = () => {
            return verifyField(lastName.value, ['require', 'minLength:2', 'alpha'], lastNameError);
        };

        const verifyEmail = () => {
            return verifyField(email.value, ['require', 'email'], emailError);
        };

        const verifyPassword = () => {
            return verifyField(password.value, ['require', 'minLength:8'], passwordError);
        };

        const verifyAllFields = computed(() => {
            return verifyFirstName()
                && verifyLastName()
                && verifyEmail()
                && verifyPassword();
        });

        const isAllFill = computed(() => {
            return firstName.value
                && lastName.value
                && email.value
                && phone.value
                && password.value;
        });

        return {
            countries,
            firstName,
            lastName,
            email,
            phone,
            password,
            country,
            isComplex,
            isTermsAgree,
            registration,
            firstNameError,
            lastNameError,
            emailError,
            passwordError,
            verifyFirstName,
            verifyLastName,
            verifyEmail,
            verifyPassword,
            verifyAllFields,
            isAllFill
        };
    },
};
</script>

<style lang="scss" scoped>
.sign-up {
    padding: 0 18px;
    max-width: 460px;
    box-sizing: border-box;
    text-align: center;

    & > div {
        margin: 25px 0;
    }
}

.g-checkbox {
    vertical-align: top;
}

.g-button {
    width: 100%;
    background-color: var(--login-btn, $login-btn);

    &:hover {
        background-color: var(--login-btn-hover, $login-btn-hover);
    }

    &:active {
        background-color: var(--login-btn-active, $login-btn-active);
    }
}

.g-field,
.g-input,
.password-input {
    margin: 8px 0 10px;
    padding-top: 1px;
}

.g-phone-input {
    margin-top: 18px;
}

.link {
    margin: 0 5px;
    font-size: 106%;
    font-weight: 600;
    white-space: nowrap;
    color: var(--secondary, $secondary);
    transition: color 0.2s;

    &:hover {
        color: var(--primary, $primary);
    }
}

.subtitle {
    color: var(--title-text-color, $title-text-color);
}

.separator {
    height: 1px;
    border: none;
    margin-top: 20px;
    background-color: var(--separator-color, $separator-color);
}
</style>
