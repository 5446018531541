import { useTradingResources } from '@trading';
import {ResourcesManager} from "@/Ship";

export default (filters, reload = false, referer = null) => {
    const position = useTradingResources('Position');
    if (reload || (!position.isLoaded && !position.isLoading)) {
        position.setFilters(filters);
        position.setIncludes(['pair']);
        position.load(null, {}, referer);
    }

    ResourcesManager.addResource(position, 'Position');

    return position;
};
