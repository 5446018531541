<script setup>

import { defineEmits, reactive, ref, computed } from 'vue';
import { useUserActions } from '@user';
import { useBuyerActions } from '@buyer';

const getAffiliates = useUserActions('getAffiliates');
const getBuyerResource = useBuyerActions('getBuyerResource');
const buyer = getBuyerResource();
const emit = defineEmits(['performed', 'close']);

const affiliates = ref([]);
const payload = reactive({
    name: '',
    user_id: null,
    type: 'CPL',
    cost: 1,
});

const isPayloadValid = computed(() => {
    return payload.name && payload.user_id && payload.type && payload.cost
});

getAffiliates().then(response => {
    affiliates.value = response.data;
});

function makeBuyer() {
    buyer.create(payload).then(()=>{
        buyer.load()
        emit('close');
    })
}

</script>

<template>
    <form class="make-buyer">
        <g-select v-model="payload.user_id"
                  v-bind:label="$t('campaign.affiliate', 'Affiliate')"
                  v-bind:options="affiliates"
                  option-text="name"
                  option-value="id"/>

        <g-field v-model="payload.name" v-bind:label="$t('campaign.buyer_name', 'Buyer Name')"/>

        <fieldset>
            <g-select v-model="payload.type"
                      v-bind:label="$t('campaign.type', 'Type')"
                      v-bind:options="[{value: 'CPA'}, {value: 'CPL'}]"
                      option-text="value"
                      option-value="value"/>

            <g-field v-model="payload.cost" v-bind:label="$t('campaign.cost', 'Cost')"/>
        </fieldset>
    </form>
    <g-flex class="btn-bar" gap="5" justify="end">
        <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">Cancel</g-button>
        <g-button class="save-btn" type="submit" v-bind:disabled="!isPayloadValid" v-on:click="makeBuyer">Make</g-button>
    </g-flex>
</template>

<style scoped lang="scss">
.make-buyer {
	width: 320px;

	& > .g-field,
	& > .g-select {
		padding-top: 10px;
	}
}

fieldset {
	margin-top: 15px;
	gap: 10px;
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;

	& > .g-field,
	& > .g-select {
		width: calc(50% - 5px);
	}
}

.btn-bar {
    margin-top: 20px;
}

.g-button {
    width: 100px;
}
</style>