<template>
    <form class="make-assets">

        <g-field v-model="payload.country" v-bind:label="$t('countries.country_code', 'Country code')"/>
        <g-field v-model="payload.symbol" v-bind:label="$t('assets.symbol', 'Symbol')"/>
        <g-field v-model="payload.name" v-bind:label="$t('assets.name', 'Name')"/>

        <g-combobox v-model="payload.industry"
                    v-bind:label="$t('assets.industry', 'Industry')"
                    v-bind:options="[...new Set(assets.map(val => val.industry))]"
                    option-text="name"
                    option-value="industry"
                    autocomplete="nope"/>

    </form>

    <g-flex class="btn-bar" gap="5" justify="end">
        <g-button class="cancel-btn"
                  type="button"
                  v-on:click="$emit('close')">
            {{ $t('base.cancel', 'Cancel') }}
        </g-button>
        <g-button class="make save-btn"
                  type="submit"
                  v-on:click="makeAsset"
                  v-bind:disabled="isMakeDisabled">
            {{ $t('base.make', 'Make') }}
        </g-button>
    </g-flex>
</template>
<script>
import { useCountryActions } from '@country';
import { computed, reactive } from 'vue';
import { useAssetActions } from '@asset';

const getAssetResource = useAssetActions('getAssetResource');
export default {
    name: 'MakeAssets',
    setup(props, { emit }) {
        const asset = getAssetResource();

        const payload = reactive({
            symbol: '',
            name: '',
            industry: '',
            country: '',
        });

        const assets = computed(() => {
            return asset.state;
        });

        function makeAsset() {
            asset.create(payload).then(() => {
                emit('close');
            });
        }

        const isMakeDisabled = computed(() => {
            return (
                !payload.symbol ||
                !payload.name ||
                !payload.industry
            );
        });

        return {
            payload,
            assets,
            isMakeDisabled,
            makeAsset,
        };
    }
};

</script>

<style scoped lang="scss">
.make-assets {
    width: 320px;

    & > .g-field,
    & > .g-select {
        padding-top: 10px;
    }

    :deep(.datalist) {
        max-height: 180px;
        max-width: 100%;
        overflow-x: hidden;
    }
}

fieldset {
    margin-top: 15px;
    gap: 10px;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    & > .g-field,
    & > .g-select {
        width: calc(50% - 5px);
    }
}

.btn-bar {
    margin-top: 20px;
}

.g-button {
    width: 100px;
}
</style>