<template>
    <div class="preloader-overlay">
        <component v-bind:is="type"/>
    </div>
</template>

<script>
export default {
    name: 'g-preloader-overlay',
    props: {
        type: {
            type: String,
            default: 'g-preloader'
        }
    },

    setup() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.preloader-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    position: absolute;
    background-color: var(--popup-overlay, $popup-overlay);
}
</style>