<script>

import { useFinanceComponents } from '@finance';
import { useEnvComponents } from '@overlay';

const {
    Sidebar,
    Environment,
} = useFinanceComponents();

export default {
    name: 'Finances',
    extends: useEnvComponents('Layout'),
    components: {
        Sidebar,
        Environment,
    },

    setup() {

        return {
            title: 'campaigns',
            sidebar: true,
        };
    }
};
</script>
