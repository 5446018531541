export default [{
    name: 'Users',
    path: `/:locale(${process.env.VUE_APP_AVAILABLE_LOCALES})?/users`,
    component: () => import('./View.vue'),
    children: [
        {
            name: 'User',
            path: ':id',
            component: () => import('./components/User.vue'),
        }
    ],
}];
