import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Whitelist extends BaseResource {

    static #instance = null;

    endpoint = 'whitelist';
    referer = 'Whitelist Resource';

    section = 'Users';
    container = 'Users';

    static getInstance() {
        if (!Whitelist.#instance) {
            Whitelist.#instance = new Whitelist();
        }
        return Whitelist.#instance;
    }
}
