import { useLeadResources } from '@lead';
import { ApiClient, STACK } from '@/Ship';

const lead = useLeadResources('Lead');
export default {

    archive(id) {
        return STACK.push(() => {
            return ApiClient.delete(`${lead.archiveEndpoint}/${id || this.entry.id}`);
        });
    },
    massArchive(targets) {
        return STACK.push(() => {
            return ApiClient.delete(lead.archiveEndpoint, { data: { targets } });
        });
    }
};
