

export default (position, fees, prices = {}) => {

	const marketPrice = prices[position.pair_symbol] || position.entry_price;

	const entryPrice = Number(position.entry_price);
	const marketCurrency = position.pair.market === 'forex' ? 'USD' : 'USDT';
	const counterPrice = (position.pair.counter !== marketCurrency)
		? prices[`${position.pair.counter}${marketCurrency}`] || NaN
		: 1;

	const swap = ((position.usdt_amount * position.swap) / 100) * (position.counterPrice || 1);

	let tradingFee = 0.001;
	switch(position.pair.market) {
		case 'crypto':
			tradingFee = fees.crypto_fee;
			break;
		case 'stocks':
			tradingFee = fees.stock_fee;
			break;
		case 'forex':
			tradingFee = fees.forex_fee;
			break;
	}
	const fee = position.usdt_amount * tradingFee / 100;

	return position.direction === 'Long'
		? ((marketPrice - entryPrice) * counterPrice * Number(position.value) - swap - fee).toFixed(2)
		: ((entryPrice - marketPrice) * counterPrice * Number(position.value) - swap - fee).toFixed(2);
};