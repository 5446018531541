
export default [
    {
        name: 'Finances',
        path: `/:locale(${process.env.VUE_APP_AVAILABLE_LOCALES})?/finances`,
        component: () => import('./View.vue'),
    },
    {
        name: 'Leads',
        path: `/:locale(${process.env.VUE_APP_AVAILABLE_LOCALES})?/finances/withdrawals`,
        component: () => import('./View.vue'),
    },
];
