<template>
	<div>
		<h1>Countries Root</h1>
        <countries/>
	</div>
</template>

<script>
import { useSettingsComponents } from '@settings';

const Countries = useSettingsComponents('Countries');

export default {
    name: 'View',
    components: { Countries }
};
</script>

<style lang="scss" scoped>

</style>