<template>
	<div>
		<h1>Assets Root</h1>
	</div>
</template>

<script>
export default {
    name: 'View'
};
</script>

<style lang="scss" scoped>

</style>