<template>
    <form class="sign-up-info">
        <div class="content">
            <p>{{payload.mainText}}</p>
            <p>{{ payload.additionText}}</p>
        </div>
        <div class="footer">
            <g-button class="ok" type="button" v-on:click="PopUp.close()">Ok</g-button>
        </div>
    </form>
</template>

<script>

import { PopUpManager as PopUp } from '@/Ship';

export default {
    name: 'SignUpInfoPopUp',
    components: {},
    props: {
        payload: {
            type: Object
        },
    },

    setup() {
        return { PopUp };
    }

};
</script>

<style scoped lang="scss">
.sign-up-info {
    width: 380px;
    max-width: 100%;
}

.content {
    text-align: center;

    .title {
        color: var(--label-color, $label-color);
    }
}

.footer {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;

    & > button {
        flex-grow: 1;
        border: 1px solid;
        background-color: transparent;

        &.ok {
            color: var(--main-text-color, $main-text-color);
            border-color: var(--main-text-color, $main-text-color);

            &:hover {
                color: white;
                background-color: var(--main-text-color, $disabled-color);
            }
        }
    }
}
</style>
