<script setup>

import { defineProps, defineEmits, onMounted } from 'vue';
import { useAssetActions } from '@asset';
import { useProfileActions } from '@profile';
import { useShipActions } from '@/Ship';

const onlyNumberInput = useShipActions('onlyNumberInput');
const getPairResource = useAssetActions('getPairResource');
const pair = getPairResource();

onMounted(() => {
    pair.index = pair.state.findIndex(item => item.symbol === props.payload.symbol);
});

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const props = defineProps(['payload']);
const emit = defineEmits(['performed', 'close']);

function editAsset() {
    pair.save(pair.model.symbol).then(() => {
        pair.index = null;
        emit('close');
    });
}
</script>

<template>
    <form class="make-pairs" v-on:submit.prevent="editAsset">
        <g-caption class="market" size="5">{{ $t('assets.market', 'Market') }}: <b>{{ pair.model.market }}</b>
        </g-caption>
        <g-caption class="symbol" size="4">{{ $t('assets.symbol', 'Symbol') }}: <b class="warning">{{
                pair.model.base
            }}</b>{{ pair.model.counter }}
        </g-caption>

        <g-flex align="end" gap="5">
            <div class="min-amount">
                <g-field v-model="pair.model.min_amount"
                         v-on:keypress="onlyNumberInput"
                         v-bind:label="$t('min_amount', 'Min amount')"
                         v-bind:disabled="profile.cant('Asset Edit')"/>
            </div>

            <g-range v-model="pair.model.volatility"
                     min="1"
                     max="10"
                     class="volatility"
                     v-bind:label="$t('assets.volatility', 'Volatility')"
                     v-bind:disabled="profile.cant('Asset Edit')">
            </g-range>
        </g-flex>
        <g-flex class="btn-bar" gap="5" justify="center">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">
                {{ $t('base.cancel', 'Cancel') }}
            </g-button>
            <g-button class="save-btn" type="submit" v-bind:disabled="profile.cant('Asset Edit') || !pair.model.min_amount">
                {{ $t('base.save', 'Save') }}
            </g-button>
        </g-flex>
    </form>
</template>


<style scoped lang="scss">
.make-pairs {
    width: 300px;
}

.market {
    text-transform: capitalize;
}

.symbol {
    margin-bottom: 20px;
}

.min-amount {
    width: 50%;

    & > .g-field {
        width: 100%;
    }
}

.volatility {
    flex-grow: 1.8;
}

.btn-bar {
    padding-top: 40px;

    & > .g-button {
        padding: 0 20px;
        min-width: 100px;
    }
}
</style>