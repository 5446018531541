<script setup>
import { defineEmits, watch, reactive, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useUserActions } from '@user';
import { i18n, verifyField } from '@/Ship';
import { useSettingsResources } from "@settings";
import { useAuthComponents } from "@auth";
import { useTeamActions } from "@team";

const emit = defineEmits(['performed','close']);

const PasswordInput = useAuthComponents('PasswordInput');

const getUserResource = useUserActions('getUserResource');
const user = getUserResource();

const { getTeamResource } = useTeamActions();
const teams = getTeamResource();

const settings = useSettingsResources('Settings');
if (!settings.roles) {
    settings.load('roles');
}

const router = useRouter();

const isAllow = ref(false);
const isComplex = ref(false);

const payload = reactive({
    name: '',
    email: '',
    password: '',
    status: 'Active',
    roles: [],
    teams: [],
});

const errors = reactive({
    name: false,
    email: false,
});

const statusList = [
    { name: 'Active' },
    { name: 'Suspended' },
    { name: 'Deleted' }
];

function validatePayload(changedKey, changedValue) {
    switch (changedKey) {
        case 'name':
            errors.name = verifyField(changedValue, ['require', 'minLength:2']);
            break;
        case 'email':
            errors.email = verifyField(changedValue, ['require', 'email']);
            break;
        case 'password':
            errors.password = isComplex.value ? null : i18n.global.t('validator.password');
            break;

        default:

            break;
    }

    isAllow.value = !Object.values(errors).some(error => error !== null);
}

const name = computed({
    get: () => {
        return payload.name;
    },
    set: (value) => {
        payload.name = value;
        errors.name = verifyField(value, ['require', 'minLength:2']);
    }
});

const email = computed({
    get: () => {
        return payload.email;
    },
    set: (value) => {
        payload.email = value;
        errors.email = verifyField(value, ['require', 'email']);
    }
});

watch(errors, () => {
    isAllow.value = !Object.values(errors).some(error => error !== null);
});

function makeUser() {
    user.create(payload).then(response => {
        emit('close');
        user.index = user.state.findIndex(item => item.id === response.id);
        router.push({ name: 'User', params: { ...router.currentRoute.value.params, id: response.id } });
    }).catch(error => {
        if (error.response && error.response.data && error.response.data.description) {
            const errorDescriptions = error.response.data.description;

            for (const key in errorDescriptions) {
                if (errors[key] !== undefined) {
                    errors[key] = errorDescriptions[key][0];
                }
            }
        }
    });
}

</script>

<template>
    <form class="make-user" v-on:submit.prevent="makeUser">
        <fieldset class="container">
            <g-field v-model="name"
                    v-bind:label="$t('user.username', 'Username')"
                    v-bind:error="errors.name"/>

            <g-field v-model="email"
                    type="email"
                    v-bind:label="$t('user.email')"
                    v-bind:error="errors.email"/>

            <g-select v-model="payload.status"
                      v-bind:label="$t('setting.status', 'Status')"
                      v-bind:options="statusList"
                      option-text="name"
                      option-value="name"/>

            <password-input v-model="payload.password"
                            v-bind:label="$t('user.password')"
                            v-model:complex="isComplex"/>
        </fieldset>

        <fieldset class="roles">
            <legend>{{ $t('parts.roles') }}</legend>
            <div>
                <g-checkbox v-for="role in settings.roles"
                            v-model="payload.roles"
                            v-bind:value="role.id"
                            v-bind:key="`role-${role.id}`">
                    {{ role.name }}
                </g-checkbox>
            </div>
        </fieldset>

        <fieldset class="teams" v-bind:class="{ backlight: !!payload.teams }">
            <legend>{{ $t('parts.teams') }}</legend>
            <div>
                <g-checkbox v-for="team in teams.state"
                            v-model="payload.teams"
                            v-bind:value="team.id"
                            v-bind:key="`team-${team.id}`">
                    {{ team.name }}
                </g-checkbox>
            </div>
        </fieldset>

        <g-flex class="btn-bar" gap="5" justify="end">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">{{ $t('base.cancel') }}</g-button>
            <g-button class="save-btn" type="submit" v-bind:disabled="!isAllow">{{ $t('base.make') }}</g-button>
        </g-flex>
    </form>
</template>

<style scoped lang="scss">
.make-user {
    width: 460px;
}

.container {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;

    & > .password-input,
    & > .g-field,
    & > .g-select {
        width: calc(50% - 10px);
    }
}

.teams,
.roles {
    margin: 5px 0;
    padding: 8px 10px 8px 15px;
    border: 1px solid var(--separator-color, $separator-color);

    & > div {
        column-count: 3;
        column-gap: 5px;

        & > .g-checkbox {
            width: 100%;
            padding: 5px 0;
            display: inline-block;
        }
    }
}

.btn-bar {
    margin-top: 20px;

    & > .g-button {
        width: 100px;
    }
}
</style>