import { usePermissionResources } from '@permission';
import {ResourcesManager} from "@/Ship";

export default () => {
    const role = usePermissionResources('Role');
    if (!role.isLoaded && !role.isLoading) {
        //role.setIncludes([]);
        role.load();
    }

    ResourcesManager.addResource(role, 'Role');

    return role;
};
