<script setup>
import { computed, onMounted, reactive } from 'vue';
import { preloaderAligner } from '@/Ship/components/global/preloaders/scrollHellper';
import { useBrandActions } from '@brand';
import { useSettingsActions } from '@settings';
import { i18n, PopUpManager as PopUp, verifyField } from '@/Ship';

const payload = reactive({ name: '', domain: '' });

const getBrandResource = useBrandActions('getBrandResource');
const brands = getBrandResource();

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource('brands');

const errors = reactive({
    name: null,
    domain: null,
});

const name = computed({
    get: () => payload.name,
    set: value => {
        payload.name = value;
        errors.name = verifyField(value, ['require', 'minLength:2']);
        return true;
    }
});

const domain = computed({
    get: () => payload.domain,
    set: value => {
        payload.domain = value;
        errors.domain = verifyField(value, ['require', 'domain']);
        return true;
    }
});

async function editBrand(id) {
    const index = settings.brands.findIndex(item => item.id === id);

    PopUp.open(require('@settings/components/PopUps/EditBrand.vue'))
    .setCaption(i18n.global.t('settings.edit_brand', 'Edit Brand'))
    .setPayload({
        payload: {
            name: settings.brands[index].name,
            domain: settings.brands[index].domain,
            id: settings.brands[index].id
        }
    });
}

async function removeBrand(id) {
    const index = settings.brands.findIndex(item => item.id === id);
    if (index !== -1) {

        settings.delete('brand', { brand_id: settings.brands[index].id }).then(() => {
            settings.brands.splice(index, 1);
        });
    }
}

async function addBrand() {
    if (payload.name && payload.domain) {
        const result = settings.brands.find(item => item.name === payload.name);

        if (!result) {
            settings.create({
                name: payload.name,
                domain: payload.domain,
                section: 'brand'
            }).then(response => {
                settings.brands.push(response);
                payload.name = '';
                payload.domain = '';
            }).catch(error => {
                if (error.response && error.response.data && error.response.data.description) {
                    const errorDescriptions = error.response.data.description;

                    for (const key in errorDescriptions) {
                        if (errors[key] !== undefined) {
                            errors[key] = errorDescriptions[key][0];
                        }
                    }
                }
            });
        }
    }
}

onMounted(() => {
    preloaderAligner('.environment');
});
</script>

<template>
    <fieldset class="brands general">
        <legend>{{ $t('settings.brands') }}</legend>
        <g-preloader-content :is-loading="settings.isLoading"/>
        <div class="container custom-scrollbar">
            <table>
                <tbody>
                <tr v-for="(brand, i) in settings.brands" v-bind:key="`brands-${brand.id}`" class="row">
                    <td class="cell index">{{ i + 1 }}</td>
                    <td class="cell brand">{{ brand.name }}</td>
                    <td class="cell target">{{ brand.domain }}</td>
                    <td class="cell actions" valign="middle">
                        <g-symbol name="settings"
                                  width="14"
                                  height="14"
                                  class="edit-btn"
                                  v-on:click="editBrand(brand.id)"/>
                        <g-symbol name="close"
                                  width="16"
                                  height="16"
                                  class="remove-btn"
                                  v-on:click="removeBrand(brand.id)"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <form v-on:submit.prevent="addBrand">
            <g-flex align="end" justify="flex-start" gap="5">
                <g-field v-model="domain"
                         v-bind:label="$t('settings.domain', 'Domain')"
                         :error="errors.domain"/>
                <g-field v-model="name"
                         v-bind:label="$t('settings.new_brand', 'New Brand')"
                         :error="errors.name"
                         v-on:keyup.enter="addBrand"/>

                <g-button type="button"
                          @click="addBrand"
                          class="add-btn"
                          v-bind:disabled="!payload.name || !payload.domain">
                    <g-symbol name="send" width="20" height="20"/>
                </g-button>
            </g-flex>
        </form>
    </fieldset>
</template>


<style scoped lang="scss">
.brands {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 15px 15px;
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 1px solid var(--separator-color, $separator-color);
    max-height: 566px;

    & > .container {
        flex-grow: 1;
    }
}

table {
    width: 100%;
    margin-top: 10px;
}

.row {
    &:nth-child(odd) {
        background-color: var(--env-bg, $env-bg);
    }

    &:nth-child(even) {
        background-color: var(--bar-bg, $bar-bg);
    }

    & > .cell {
        padding: 8px;

        &:last-child {
            text-align: right;
            font-size: 0;
        }
    }
}

.edit-btn {
    cursor: pointer;
    fill: var(--success, $success);

    &:hover {
        fill: var(--success-hover, $success-hover);
    }
}

.remove-btn {
    cursor: pointer;
    fill: var(--danger, $danger);

    &:hover {
        fill: var(--danger-hover, $danger-hover);
    }
}

form {
    margin-top: 8px;

    .g-select {
        width: 150px;
    }
}

.add-btn {
    color: white;
    padding: 0 20px;
    background-color: var(--primary, $primary);
    fill: var(--btn-primary-color, $btn-primary-color);

    &:hover {
        background-color: var(--primary-hover, $primary-hover);
    }
}
</style>