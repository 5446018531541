<script setup>

import { defineEmits, defineProps } from 'vue';

import { useArchiveActions } from '@archive';



const { restoring } = useArchiveActions();

const props = defineProps({
    targets: {
        type: Array,
        default: () => [],
    }
});

const emit = defineEmits(['updated', 'drop']);

function restoreLeads() {
    restoring.massRestore(props.targets).then(() => {
        emit('updated');
    });
}
</script>

<template>
    <form>
        <fieldset>
            <legend>Mass Restore <span class="selected-count"> ( Selected leads: {{ targets.length }} )</span></legend>
            <g-symbol class="close-btn" name="close" width="20" height="20" v-on:click="$emit('drop')"/>

            <div class="separator"></div>

            <g-button
                class="restore-btn"
                type="button"
                v-on:click="restoreLeads"
                v-bind:data-tooltip="$t('base.restore', 'Restore')">
                <g-symbol name="restore" width="20" height="20"/>
            </g-button>
        </fieldset>
    </form>
</template>

<style lang="scss" scoped>
form {
    z-index: 3;
    bottom: 10px;
    position: sticky;
    container-type: inline-size;
    container-name: form;
}

.select-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

fieldset {
    gap: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 25px 25px 20px;
    background-color: var(--popup-bg, $popup-bg);
    border: 1px solid var(--success, $success);
    border-radius: var(--bar-rounding, $bar-rounding);
    box-shadow: 0 -20px 30px 14px var(--body-bg, $body-bg);

    & > legend {
        padding: 0 6px;
        font-size: 18px;
        font-weight: bold;
        color: var(--main-text-color, $main-text-color);
    }
}

.g-button {
    color: black;
    font-weight: bold;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.restore-btn {
        background-color: transparent;
        fill: var(--success, $success);
        color: var(--title-color, $title-color);
        border: 1px solid var(--success-hover, $success-hover);

        &:hover {
            color: white;
            fill: white;
            background-color: var(--success, $success);
        }
    }
}

.close-btn {
    top: 20px;
    right: 10px;
    position: absolute;
    cursor: pointer;
    fill: var(--main-text-color, $main-text-color);
}

@container form (min-width: 885px) {
    .restore-btn {
        width: 110px;

        &::after {
            content: attr(data-tooltip);
        }
    }
}

@container form (max-width: 1100px) {
    .restore-btn {
        flex-shrink: 0;
        font-size: 0 !important;
        width: var(--btn-height);
        position: relative;

        &:hover {
            &::after {
                content: attr(data-tooltip);
                position: absolute;
                z-index: 2;
                left: 50%;
                bottom: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
                transform: translateX(-50%);
                padding: var(--tooltip-paddings, $tooltip-paddings);
                font-size: var(--tooltip-font-size, $tooltip-font-size);
                border-radius: var(--tooltip-rounding, $tooltip-rounding);
                color: var(--tooltip-text-color, $tooltip-text-color);
                background-color: var(--danger, $danger);
            }

            &::before {
                content: '';
                position: absolute;
                z-index: 3;
                left: 50%;
                bottom: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
                border: var(--tooltip-arrow-size, $tooltip-arrow-size) solid transparent;
                transform: translateX(-50%);
                border-top-color: var(--danger, $danger);
            }
        }
    }
}

@media (min-width: 725px) {
    .separator {
        flex-grow: 1;
    }
}

@media (max-width: 1024px) {
    fieldset {
        gap: 6px 2px;
        flex-wrap: wrap;
    }

    .g-select {
        width: 100%;
    }

    .g-button {
        flex-grow: 1;
    }
}

</style>
