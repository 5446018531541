import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Permission extends BaseResource {

    static #instance = null;

    endpoint = 'permissions';
    referer = 'Permission Resource';

    section = 'Settings';
    container = 'Permission';

    static getInstance() {
        if (!Permission.#instance) {
            Permission.#instance = new Permission();
        }
        return Permission.#instance;
    }
}
