import { useLeadResources } from '@lead';
import { ApiClient, STACK } from '@/Ship';
export default (payload) => {
	const lead = useLeadResources('Lead');


	return STACK.push(() => {
		return ApiClient.patch(lead.endpoint, payload);
	});
};
