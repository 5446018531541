<script>

import { useArchiveComponents } from '@archive';
import { useEnvComponents } from '@overlay';

const {
    Sidebar,
    Environment,
} = useArchiveComponents();

export default {
    name: 'Archive',
    extends: useEnvComponents('Layout'),
    components: {
		Environment,
        Sidebar,
    },

    setup() {

        return {
            title: 'Archive',
            sidebar: true,
            rightbar: false,
        };
    }
};
</script>
