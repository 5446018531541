<script setup>

import { computed, onMounted } from 'vue';
import { useBrandActions } from '@brand';
import { useSettingsActions } from '@settings';
import { useProfileActions } from '@profile';
import { ApiClient } from "@/Ship";


const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource();

const applyBrand = useBrandActions('applyBrand');

const selectedBrand = computed(() => {

    const index = profile.brands.findIndex(brand => brand.id === profile.state?.env?.brand);

    return index > -1
        ? profile.brands[index].name
        : 'Indefinite';
});

onMounted(() => {
    if (!profile.state?.env?.brand) {
        chooseBrand(0);
    } else {

        ApiClient.defaults.params = {
            brand_id: profile.state.env.brand
        };
    }
});

function chooseBrand(i) {
    if (profile.brands[i]?.id !== profile.state?.env?.brand) {
        applyBrand(profile.brands[i]?.id || 0);

        if (!profile.state.env) {
            profile.state.env = {};
        }
        profile.state.env.brand = profile.brands[i].id;
        profile.saveState();
    }
}
</script>

<template>
    <g-fade-container class="brands-menu" icon="chip" v-bind:label="selectedBrand">
        <div class="account-menu">
            <label v-for="(brand, i) in profile.brands" class="menu-item" v-on:click="chooseBrand(i)">
                {{ brand.name }}
            </label>
        </div>
    </g-fade-container>
</template>

<style lang="scss" scoped>
.g-fade-container:deep(.value) {
    background-color: var(--primary, $primary) !important;
}

.account-menu {
    padding: 10px 16px;
    background-color: var(--bar-bg, $env-bg);

    & > .menu-item {
        display: block;
        padding: 6px 0;
        cursor: pointer;
        color: var(--main-text-color, $main-text-color);
        fill: var(--main-text-color, $main-text-color);

        &:hover {
            color: var(--title-text-color, $title-text-color);
            fill: var(--title-text-color, $title-text-color);
            border-radius: 5px;
            //background-color: var(--primary, $primary);
        }

        &.disabled {
            pointer-events: none;
            color: var(--disabled-color, $disabled-color);
            fill: var(--disabled-color, $disabled-color);
        }
    }
}

</style>