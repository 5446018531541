import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Wallet extends BaseResource {

    static #instance = null;

    endpoint = 'wallets';

    section = 'Finances';
    container = 'Finances';

    static getInstance() {
        if (!Wallet.#instance) {
            Wallet.#instance = new Wallet();
        }
        return Wallet.#instance;
    }
}
