import { reactive } from 'vue';

const STATE = reactive({
    LOADING: false,
    IS_LOADING: false,
    IS_PROCESSING: false,
    IS_LOGIN: true,
    IS_VISIBLE: true,
    IS_LIGHT_MODE: false,
    IS_SHOW_SIDEBAR: false,
    AUTO_SAVE_INDICATOR: 0,
    BRAND: 1,
    UNSAVED_DATA: {}
});

export default STATE;
