import { useCommentResources } from '@comment';
import {ResourcesManager} from "@/Ship";

export default (params, reload = false, referer = null) => {
    const comment = useCommentResources('Comment');

    if (reload) {
        comment.setIncludes(params.includes);
        comment.setFilters(params.filters);
        comment.sort('id', 'desc');
        comment.load(null, {}, referer);
    }

    ResourcesManager.addResource(comment, 'Comment');

    return comment;
};
