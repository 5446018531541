<script setup>

import { reactive } from 'vue';
import { useCampaignActions } from '@campaign';
import { useProfileActions } from '@profile';

const getCampaignResource = useCampaignActions('getCampaignResource');
const campaigns = getCampaignResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

if (!profile.state.campaign) {
    profile.state.campaign = {
        filters: {},
        sortBy: 'id',
        sortDir: 'desc',
        //perPage: 25,
    };
}

const filters = reactive({});

Object.assign(filters, profile.state.campaign.filters);
campaigns.setFilters(filters);

function filtrate() {
    for (const key in filters) {
        if (!Object.keys(filters[key]).length) {
            delete filters[key];
        }
    }
    campaigns.setFilters(filters);
    campaigns.load();

    profile.state.campaign.filters = filters;
    profile.saveState();
}
</script>

<template>
    <g-filterset v-model="filters.offers"
                 v-bind:label="$t('filters.offers', 'Offers')"
                 value-field="id"
                 v-bind:filterset="campaigns.filterset.offers"
                 v-on:change="filtrate"
                 expanded/>

	<g-filterset v-model="filters.language"
	             v-bind:label="$t('filters.languages', 'Languages')"
                 value-field="key"
	             v-bind:filterset="campaigns.filterset.language"
	             v-on:change="filtrate"
                 expanded/>

	<g-filterset v-model="filters.buyers"
	             v-bind:label="$t('filters.buyers', 'Buyers')"
	             value-field="id"
	             v-bind:filterset="campaigns.filterset.buyers"
	             v-on:change="filtrate"
                 expanded/>

	<g-filterset v-model="filters.affiliates"
	             v-bind:label="$t('filters.affiliates', 'Affiliates')"
	             value-field="id"
	             v-bind:filterset="campaigns.filterset.affiliates"
	             v-on:change="filtrate"
                 expanded/>

	<g-filterset v-model="filters.countries"
	             v-bind:label="$t('filters.geo', 'Geo')"
	             value-field="code"
	             v-bind:filterset="campaigns.filterset.countries"
	             v-on:change="filtrate"
                 expanded/>
</template>

<style lang="scss" scoped>
.g-filterset {
    border: none !important;
    padding: 10px 20px 20px 10px;

    &:deep(.value) {
        max-width: 120px;
    }
}
</style>