import { useAssetResources } from '@asset';
import {ResourcesManager} from "@/Ship";

export default (reload = false) => {
    const pair = useAssetResources('Pair');
    if (reload || (!pair.isLoaded && !pair.isLoading)) {
        //pair.setIncludes([]);
        pair.load();
    }

    ResourcesManager.addResource(pair, 'Pair');

    return pair;
};
