<script setup>
import { computed, defineEmits, reactive, ref, watch } from 'vue';
import { useTeamActions } from '@team';
import { useUserActions } from '@user';
import { verifyField } from '@/Ship';

const emit = defineEmits(['performed','close']);

const getTeamResource = useTeamActions('getTeamResource');
const teams = getTeamResource();

const getTeamleads = useUserActions('getTeamleads');
const teamleads = ref([]);

getTeamleads().then(response => {
    teamleads.value = response.data;
});

const isAllow = ref(false);

const payload = reactive({
    name: '',
});

const errors = reactive({
    name: false,
});

const name = computed({
    get: () => payload.name,
    set: value => {
        payload.name = value;
        errors.name = verifyField(value, ['require', 'minLength:2']);
        return true;
    }
});

watch([errors, () => payload.name], () => {
    isAllow.value = true;
    for (const key in errors) {
        if (errors[key] !== null) {
            isAllow.value = false;
            break;
        }
    }
});

const desks = computed(() => {
    return teams.state.map(team => team.desk).filter(item => item).filter((value, index, array) => {
        return array.indexOf(value) === index;
    });
});

function makeTeam() {
    teams.create(payload).then(() => {
        emit('close');
    }).catch(error => {
        if (error.response && error.response.data && error.response.data.description) {
            const errorDescriptions = error.response.data.description;

            for (const key in errorDescriptions) {
                if (errors[key] !== undefined) {
                    errors[key] = errorDescriptions[key][0];
                }
            }
        }
    });
}

</script>

<template>
    <div class="make-team">
        <g-field v-model="name"
                 v-bind:label="$t('teams.name', 'Team Name')"
                 v-bind:error="errors.name"/>
        <g-combobox v-model="payload.desk"
                    v-bind:label="$t('teams.desk', 'Desk')"
                    v-bind:options="desks"/>

        <g-select v-model="payload.teamlead_id"
                  v-bind:label="$t('teams.teamlead', 'Teamlead')"
                  v-bind:options="teamleads"
                  option-text="name"
                  option-value="id"/>
        <g-flex class="btn-bar" gap="5" justify="end">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">{{ $t('base.cancel', 'Cancel') }}</g-button>
            <g-button class="make save-btn" type="submit"  v-on:click="makeTeam"  v-bind:disabled="!isAllow">{{ $t('base.make', 'Make') }}</g-button>
        </g-flex>
    </div>
</template>

<style scoped lang="scss">
.make-team {
    width: 300px;
    max-width: 100%;
    gap: 8px;
    display: flex;
    flex-direction: column;
}

.btn-bar {
    margin-top: 10px;
}

.g-button {
	width: 100px;

    &.make {
        color: white;
        background-color: var(--primary, $primary);

        &:hover {
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    &.cancel {
        color: var(--danger-hover, $danger-hover);
        background-color: transparent;
        border: 1px solid var(--danger, $danger);

        &:hover {
            color: white;
            background-color: var(--danger, $danger);
        }
    }
}
</style>