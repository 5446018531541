import axios from 'axios';
import { useAuthActions } from '@auth';

const { refreshToken } = useAuthActions();
axios.defaults.baseURL = `${process.env.VUE_APP_SERVER}/${process.env.VUE_APP_API_PREFIX}/`;

Object.defineProperty(axios.__proto__, 'isTokenExpired', {
    value: false,
    writable: true,
});

Object.defineProperty(axios.__proto__, 'expiresIn', {
    get() {
        return (localStorage.expires_in || 0) - Date.now();
    }
});

axios.__proto__.setToken = (token) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

axios.__proto__.checkToken = () => {
    if (localStorage.access_token && (localStorage.expires_in - axios.expiresIn) > 180000) {
        axios.setToken(localStorage.access_token);
        axios.isTokenExpired = false;
        return true;
    }
    return false;
};

axios.__proto__.removeToken = () => {
    delete axios.defaults.headers.common['Authorization'];
};

axios.interceptors.request.use(config => {
    const tokenLive = 180000; // half an hour
    if (localStorage.access_token && axios.expiresIn > 0) {
        if (!axios.isTokenExpired && axios.expiresIn < tokenLive) {
            axios.isTokenExpired = true;
            refreshToken();
        }
    }
    return config;
}, error => {
    `The error occurred during request ${error}`;
});

export default axios;
