<template>
    <div class="toolbar part-toolbar">
        <h5>{{ $t('page.profile') }}</h5>
    </div>
</template>

<script>
export default {
    name: 'Search'
};
</script>

<style lang="scss" scoped>
.toolbar {
    &:deep(.g-input) {
        flex-grow: 1;
    }
}
</style>
