<template>
    <div class="g-date-picker">
        <ul class="presets">
            <li v-for="(preset, key) of presets" :key="preset" @click="onPresetSelect(preset)">
                <span :class="{'active': preset === selectedPreset}">{{ $t(`filters.${preset}`) }}</span>
            </li>
        </ul>
        <DatePicker :is-range="isRange" ref="calendar" v-model="dateRange" :locale="locale" @update:model-value="onSetRange" />
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { i18n } from '@/Ship';

export default {
    name: 'GDatePicker',
    props: {
        from: Date,
        to: Date,
        isRange: {
            type: Boolean,
            default: true
        }
    },
    emits: ['update:from', 'update:to', 'change'],

    setup(props, { emit }) {
        const selectedPreset = ref('');
        const dateRange = ref({ start: props.from || null, end: props.to || null });
        const calendar = ref(null);

        watch(() => props.to, () => {
            if (isValidDate(props.from) && isValidDate(props.to)) {
                const start = new Date(props.from);
                const end = new Date(props.to);
                moveCalendar(start);
                dateRange.value = { start, end };
            }
        });

        function moveCalendar(date) {
            const startDate = dateRange.value.start ? new Date(dateRange.value.start) : new Date();
            const monthDiff = (date.getFullYear() - startDate.getFullYear()) * 12 + (date.getMonth() - startDate.getMonth());
            calendar.value.move(monthDiff);
        }

        function isValidDate(dateString) {
            if (dateString && dateString.length >= 10) {
                const date = new Date(dateString);
                return !isNaN(date.getTime());
            }
            return false;
        }

        const locale = computed(() => {
            return {
                id: i18n.global.locale.value,
                firstDayOfWeek: {
                    en: 1,
                    ru: 2,
                    pl: 2,
                }[i18n.global.locale.value],
            };
        });

        const presets = [
            'today',
            'yesterday',
            'current_week',
            'previous_week',
            'current_month',
            'previous_month',
        ];

        const onPresetSelect = (preset) => {
            let start;
            let end = new Date();
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            switch (preset) {
                case 'today':
                    start = today;
                    break;
                case 'yesterday':
                    start = new Date(today.getTime() - 24 * 60 * 60 * 1000);
                    end.setDate(start.getDate());
                    break;
                case 'current_week':
                    start = new Date(today.setDate(today.getDate() - today.getDay()));
                    break;
                case 'previous_week':
                    end = new Date(today.setDate(today.getDate() - today.getDay()));
                    start = new Date(end.getTime() - 7 * 24 * 60 * 60 * 1000);
                    break;
                case 'current_month':
                    start = new Date(today.getFullYear(), today.getMonth(), 1);
                    break;
                case 'previous_month':
                    start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                    end = new Date(today.getFullYear(), today.getMonth(), 0);
                    break;
            }

            selectedPreset.value = preset;
            moveCalendar(start);
            dateRange.value = { start, end };
        };

        function onSetRange() {
            if (dateRange.value.start && dateRange.value.end) {
                dateRange.value.start.setHours(0, 0, 0);
                dateRange.value.end.setHours(23, 59, 59);
                emit('update:from', dateRange.value.start);
                emit('update:to', dateRange.value.end);
                emit('change');
            }
        }

        return {
            locale,
            dateRange,
            presets,
            selectedPreset,
            calendar,
            onSetRange,
            onPresetSelect,
        };
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 451px) {
    .vc-container {
        padding: 16px 14px 10px;
    }
}

@media (max-width: 450px) {
    .presets {
        display: none;
    }

    .vc-container {
        padding: 10px;
    }
}

.g-date-picker {
    width: 100%;
    max-width: 440px;
    display: flex;
    align-items: stretch;
    border-radius: 8px;
    border: 1px solid var(--secondary, $secondary);
    box-shadow: var(--box-shadow, $datepicker-shadow);
    background-color: var(--dropdown-bg, $dropdown-bg);
}

.presets {
    padding: 18px;
    background-color: var(--datepicker-secondary, $datepicker-secondary);

    & > li {
        cursor: pointer;
        font-size: 14px;
        margin-bottom: 10px;
        white-space: nowrap;
        color: var(--btn-primary-color, $btn-primary-color);
        border-bottom: 1px solid transparent;

        &:hover {
            color: var(--title-text-color, $title-text-color);
            border-color: var(--main-text-color, $secondary);
        }

        .active {
            color: var(--title-text-color, $title-text-color);
        }
    }
}

:deep(.highlighted) {
    background-color: var(--secondary, #{$secondary});
}

:deep(.highlighted-text) {
    color: var(--datepicker-bg, $datepicker-bg);
}

:deep(.vc-container) {
    --text-xs: var(--date-field-text-lg, #{$datepicker-text-xs});
    --text-sm: var(--date-field-text-lg, #{$datepicker-text-sm});
    --text-base: var(--date-field-text-lg, #{$datepicker-text-base});
    --text-lg: var(--date-field-text-lg, #{$datepicker-text-lg});
    --blue-100: var(--datepicker-primary, #{$datepicker-primary});
    --blue-200: var(--secondary, #{$secondary});
    --blue-300: var(--datepicker-primary, #{$datepicker-primary});
    --blue-400: var(--datepicker-primary, #{$datepicker-primary});
    --blue-500: var(--datepicker-primary, #{$datepicker-primary});
    --blue-600: var(--datepicker-primary-hover, #{$datepicker-primary-hover});
    --blue-700: var(--datepicker-primary-hover, #{$datepicker-primary-hover});
    --blue-800: var(--datepicker-primary-hover, #{$datepicker-primary-hover});
    --blue-900: var(--datepicker-primary-hover, #{$datepicker-primary-hover});
    --gray-100: var(--datepicker-secondary, #{$datepicker-secondary-hover});
    --gray-200: var(--datepicker-secondary-hover, #{$datepicker-secondary-hover});
    --gray-300: var(--datepicker-secondary-hover, #{$datepicker-secondary-hover});
    --gray-400: var(--datepicker-secondary-hover, #{$datepicker-secondary-hover});
    --gray-500: var(--datepicker-secondary, #{$datepicker-secondary});
    --gray-600: var(--datepicker-secondary, #{$datepicker-secondary});
    --gray-700: var(--datepicker-secondary, #{$datepicker-secondary});
    --gray-800: var(--datepicker-secondary, #{$datepicker-secondary});
    --gray-900: var(--datepicker-secondary, #{$datepicker-secondary});
    --day-border: 1px solid var(--datepicker-secondary-hover, #{$datepicker-secondary-hover});
    --day-border-highlight: 1px solid var(--datepicker-secondary-hover, #{$datepicker-secondary-hover});
    --weekday-bg: var(--datepicker-bg, $datepicker-bg);
    --weekday-border: 0;
    --day-width: 90px;
    --day-height: 90px;

    border-radius: var(--datepicker-rounding, $datepicker-rounding);
    background-color: var(--datepicker-bg, $datepicker-bg);
    color: var(--datepicker-color, $datepicker-color);
    font-family: var(--font-main, $font-primary);
    width: 100%;
    position: relative;
    border: none;

    .vc-header {
        background-color: var(--datepicker-bg, $datepicker-bg);
        padding: 10px 0;
    }

    & .vc-weeks {
        padding: 0;
    }

    & .vc-weekday {
        background-color: var(--weekday-bg);
        border-bottom: var(--weekday-border);
        border-top: var(--weekday-border);
        padding: 5px 0;
    }

    & .vc-day {
        text-align: left;
    }

    & .vc-day-dots {
        margin-bottom: 5px;
    }

    & .vc-pane-container > div {
        &:nth-of-type(3) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .vc-arrow > svg > path {
        fill: var(--secondary, $secondary);
    }
}
</style>