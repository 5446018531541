<template>
    <div class="index-bar">
        <g-flex justify="between" align="end">

            <slot/>

            <div class="separator"></div>

            <g-flex class="toolbar" gap="15" align="center">

                <g-select v-model="perPage"
                          class="per-page"
                          :label="$t('base.per_page')"
                          v-bind:options="[{value: 25}, {value: 50}, {value: 75}, {value: 100}]"
                          v-on:change="$emit('perpage', perPage)"
                          option-value="value"
                          option-text="value"/>
            </g-flex>
        </g-flex>

        <g-flex class="filters" gap="4" wrap="wrap">
            <div v-if="Object.keys(archive.filters).length > 0"
                      class="filter reset-all"
                      v-on:click="resetAllFilters">
                Reset All
                <div class="filter-item">
                    <g-symbol name="close" width="14" height="14"/>
                </div>
            </div>
            <div class="filter" v-for="(values, key) in archive.filters" v-bind:key="key" v-bind:class="key">
                {{ $t(`filters.${key}`, key) }}:
                <div class="filter-item" v-for="(value, i) in values" v-bind:key="`${key}-${i}`">
                    <div class="value">{{ getFilterValueByKey(key, value) }}</div>
                    <g-symbol name="close" width="14" height="14" v-on:click="dropFilter(key, value)"/>
                </div>
            </div>
        </g-flex>

        <g-tabs v-if="profile.can('Lead ArchiveView')"
                v-model="tab"
                v-bind:tab-list="tabList"
                v-on:click="$router.push({ name: 'Leads', params: { locale: $i18n.locale } })"/>
    </div>
</template>

<script>

import { reactive, ref } from 'vue';
import { i18n, PopUpManager as PopUp } from '@/Ship';
import { useProfileActions } from '@profile';
import { useArchiveActions } from '@archive';

const getArchiveResource = useArchiveActions('getArchiveResource');
const getProfileResource = useProfileActions('getProfileResource');

export default {
    name: 'Indexbar',
    emits: ['perpage'],

    setup() {
        const archive = getArchiveResource();
        const profile = getProfileResource();

        const perPage = ref(profile.state.archive.perPage || 25);
        const tab = ref('archive');
        const tabList = reactive({
            leads: i18n.global.t(`parts.leads`),
            archive: i18n.global.t(`parts.archive`),
        });

        function getFilterValueByKey(filter, key) {
            if (archive.filterset[filter]) {

                key = String(key).split(',').pop();
                const filterset = archive.filterset[filter].find(item => item.id === Number(key) || item.value == key);

                let value;
                switch (filter) {
                    case 'campaigns':
                        value = profile.can('Lead RealCampaignView')
                            ? filterset?.name || key
                            : filterset?.alias || key;
                        break;
                    default:
                        value = filterset
                            ? filterset.key || filterset.value || filterset.name
                            : key || 'Null';
                        break;
                }

                return value;
            }
            return key;
        }

        function dropFilter(filter, value) {
            archive.dropFilterValue(filter, value);
            archive.setPage(1);
            archive.load();

            profile.state.archive.filters = archive.filters;
            profile.saveState();
        }

        function resetAllFilters() {
            Object.keys(archive.filters).forEach(key => {
                const valuesClone = archive.filters[key].slice();
                valuesClone.forEach(value => {
                    archive.dropFilterValue(key, value);
                });
            });

            Object.assign(profile.state.archive.filters, archive.filters);

            archive.setFilters(archive.filters);
            archive.setPage(1);
            archive.load();

            profile.saveState();
        }

        return {
            PopUp,
            perPage,
            archive,
            profile,
            tabList,
            tab,
            dropFilter,
            getFilterValueByKey,
            resetAllFilters
        };
    }
};
</script>

<style lang="scss" scoped>
.index-bar {
    top: 0;
    left: 0;
    z-index: 4;
    position: sticky;
    padding: 10px 15px 18px;
    border-radius: 0 0 5px 5px;
    background-color: var(--bar-bg, $bar-bg);
    border-bottom: 1px solid var(--separator-color, $separator-color);

    .per-page {
        width: 120px;
    }

    .separator {
        flex-grow: 1;
    }
}

.g-tabs {
    margin-top: 8px;
}

.filters {
    padding-top: 20px;
    padding-bottom: 15px;
}

.filter {
    padding-left: 8px;
    border-radius: 5px;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    color: white;
    border: 1px solid;
    box-shadow: 0 5px 15px -10px #000000aa;

    & > .filter-item {
        padding: 2px 4px 2px 8px;
        display: inline-flex;
        align-items: center;
        height: 100%;
        gap: 2px;

        &:first-child {
            margin-left: 5px;
        }

        & > .value {
            font-weight: 500;
        }

        & > .g-symbol {
            cursor: pointer;
            fill: var(--btn-primary-color, $btn-primary-color);
        }
    }

    &.reset-all {
        background-color: black;
        border-color: #333333;

        & > .filter-item {
            background-color: #333333;
        }
    }

    &.is_ftd {
        background-color: #d08300;
        border-color: #eaa308;

        & > .filter-item {
            background-color: #eaa308;
        }
    }

    &.created_at {
        background-color: var(--primary, $primary);
        border-color: var(--primary-hover, $primary-hover);

        & > .filter-item {
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    &.ftd_date {
        background-color: var(--secondary, $secondary);
        border-color: var(--secondary-hover, $secondary-hover);

        & > .filter-item {
            background-color: var(--secondary-hover, $secondary-hover);
        }
    }

    &.conversion_status {
        background-color: #008060;
        border-color: #22a585;

        & > .filter-item {
            background-color: #22a585;
        }
    }

    &.retention_status {
        background-color: #700099;
        border-color: #b010df;

        & > .filter-item {
            background-color: #b010df;
        }
    }

    &:not(.is_ftd, .created_at, .ftd_date, .conversion_status, .retention_status, .reset-all) {
        background-color: var(--danger, $danger);
        border-color: var(--danger-hover, $danger-hover);

        & > .filter-item {
            background-color: var(--danger-hover, $danger-hover);

            & > .value {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 80px;
            }
        }
    }
}
</style>
