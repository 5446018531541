import { useLeadResources } from '@lead';
import { ApiClient, STACK } from '@/Ship';

const lead = useLeadResources('Lead');
function serializePayload(payload) {
    const params = {};

    Object.keys(payload).forEach(key => {
        if (Array.isArray(payload[key])) {
            const values = payload[key].map(item => {
                if (typeof item === 'object' && item !== null) {
                    return item.value;
                } else {
                    return item;
                }
            }).join(',');
            params[key] = values;
        } else {
            params[key] = payload[key];
        }
    });

    return params;
}

export default {
    getFilters() {
        return STACK.push(() => {
            return ApiClient.get(`${lead.endpoint}/filters`);
        });
    },
    getFiltersInfo(filter, payload) {
        const params = {};
        const serializedPayload = serializePayload(payload);
        const queryParams = { ...params, ...serializedPayload };

        return STACK.push(() => {
            return ApiClient.get(`${lead.endpoint}/filters?filter=${filter}`, {
                params: queryParams,
                headers: { 'Ref': 'Lead Resource' }
            });
        });
    },
    filtrate(payload) {
        return STACK.push(() => {
            return ApiClient.get(lead.filterEndpoint, { data: { payload } });
        });
    }
};