import { useProfileResources } from '@profile';
import { ApiClient, STACK } from '@/Ship';

const profile = useProfileResources('Profile');
export default {
    remove(id, payload) {
        return STACK.push(() => {
            return ApiClient.delete(`${profile.endpoint}/${id || this.entry.id}/document/${payload.type}`);
        });
    },
};