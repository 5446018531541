
export default [
    {
        name: 'Archive',
        path: `/:locale(${process.env.VUE_APP_AVAILABLE_LOCALES})?/leads/archive`,
        component: () => import('../Archive/View.vue'),
    },
    {
        name: 'Leads',
        path: `/:locale(${process.env.VUE_APP_AVAILABLE_LOCALES})?/leads`,
        component: () => import('./View.vue'),
        children: [
            {
                name: 'Lead',
                path: ':id',
                component: () => import('./components/Lead.vue'),
            },
        ],
    },
];
