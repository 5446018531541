<template>
    <div class="info">
        <form class="profile-form" @submit.prevent>
            <g-field
                type="text"
                :label="$t('base.name')"
                v-model="name"
                :error="errors.name"
            />
            <g-field
                type="email"
                :label="$t('auth.email')"
                v-model="email"
                :error="errors.email"
            />
            <password-input
                v-model="password"
                v-model:complex="isComplex"
                v-bind:label="$t('auth.password')"
                v-bind:error="errors.password"
            />
            <div class="footer">
                <g-button class="g-button save-btn" @click="updateProfile" v-bind:disabled="!isAllow">
                    {{ $t('base.save') }}
                </g-button>
            </div>
            <g-field label="Roles" v-model="roles" readonly>
                <g-symbol name="lock" width="18" height="18"/>
            </g-field>
            <div>
                <fieldset class="teams">
                    <legend>{{ $t('parts.teams') }}</legend>
                    <div>
                        <g-checkbox v-model="teamList"
                                    v-for="team in teams.state"
                                    v-bind:value="team.id"
                                    v-bind:key="`team-${team.id}`"
                                    v-bind:disabled="true">
                            {{ team.name }}
                        </g-checkbox>
                    </div>
                </fieldset>
            </div>
            <whitelist :user-id="profile.id" disabled/>
        </form>
    </div>
</template>

<script>
import { computed, ref, onMounted, reactive, watch } from 'vue';
import {
    STACK,
    ApiClient,
    STATE,
    PopUpManager as PopUp,
    verifyField, i18n,
} from '@/Ship';
import { useProfileResource, useProfileActions } from '@profile';
import { useProfileComponents } from '@profile';
import { useAuthComponents } from '@auth';
import { useUserComponents } from '@user';

import { useTeamActions } from '@team';

const { getTeamResource } = useTeamActions();
const { Whitelist } = useUserComponents();

export default {
    name: 'Info',
    components: {
        PasswordInput: useAuthComponents('PasswordInput'),
        Documents: useProfileComponents('Documents'),
        Whitelist,
    },

    setup: function () {
        const profile = useProfileResource('Profile');
        const isAllow = ref(false);
        const isComplex = ref(false);
        const countries = ref([]);

        const teams = getTeamResource(true);

        const errors = reactive({
            name: null,
            email: null,
            password: false,
        });

        const name = computed({
            get: () => profile.name,
            set: value => {
                profile.name = value;
                errors.name = verifyField(value, ['require', 'minLength:2']);
                return true;
            }
        });

        const email = computed({
            get: () => profile.email,
            set: value => {
                profile.email = value;
                errors.email = verifyField(value, ['require', 'email']);
                return true;
            }
        });

        const password = computed({
            get: () => profile && profile.password,
            set: value => {
                profile.password = value;
                errors.password = isComplex.value ? null : i18n.global.t('validator.password');
                return true;
            }
        });

        watch([errors, () => password.value], () => {
            isAllow.value = true;

            for (const key in errors) {
                if (errors[key] !== null) {
                    isAllow.value = false;
                    break;
                }
            }
        });

        const teamList = computed(() => {
            return (profile.teams || []).map(team => team.id || team);
        });

        const roles = computed(() => {
            return profile.roles
            .map(function (role) {
                return ' ' + role['name'];
            })
            .toString();
        });

        const updateProfile = () => {
            profile.save().catch(error => {
                if (error.response && error.response.data && error.response.data.description) {
                    const errorDescriptions = error.response.data.description;

                    for (const key in errorDescriptions) {
                        if (errors[key] !== undefined) {
                            errors[key] = errorDescriptions[key][0];
                        }
                    }
                }
            });
            profile.clearEntity();
        };

        const isShowTooltip = ref([]);

        onMounted(() => {
            STACK.push(() => ApiClient.get('/countries')).then((data) => {
                countries.value = data;
            });
        });

        return {
            countries,
            teamList,
            roles,
            PopUp,
            profile,
            isShowTooltip,
            updateProfile,
            isComplex,
            name,
            email,
            password,
            errors,
            isAllow,
            teams
        };
    },
};
</script>

<style lang="scss" scoped>
.info {
    display: grid;
    grid-template-columns: 500px auto;
    gap: 20px;
}

.profile-form {
    .g-field,
    .password-input,
    .g-phone-input {
        padding-top: 6px;
        margin-top: 8px;
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        padding: 20px 0;
    }

    .g-button {
        padding: 0 40px;
    }
}

.whitelist {
    margin: 12px 0 0;
    width: 100%;
    min-width: 260px;
}

.password-set {
    margin-top: 25px;
    border-top: 1px solid var(--field-border, $field-border);
}

.confirm-btn {
    padding: 0 20px;
    background-color: var(--primary, $primary);
}

.teams {
    margin: 12px 0 0;
    padding: 8px 15px 8px;
    border: 1px solid var(--separator-color, $separator-color);

    & > div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        & > .g-checkbox {
            width: 100%;
            display: inline-block;
            margin: 4px 0;
        }
    }
}


</style>
