
import { useProfileActions } from '@profile';

const getProfileResource = useProfileActions('getProfileResource');

export default function (allow) {
	const profile = getProfileResource();

	return profile.can(allow);
}
