import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Team extends BaseResource {

    static #instance = null;

    endpoint = 'teams';
    referer = 'Team Resource';

    section = 'Settings';
    container = 'Teams';

    static getInstance() {
        if (!Team.#instance) {
            Team.#instance = new Team();
        }
        return Team.#instance;
    }
}
