<template>
	<div>
		<h2>Offers List</h2>
	</div>
</template>

<script>
export default {
    name: 'Campaigns'
};
</script>

<style lang="scss" scoped>

</style>