import { useUserResources } from '@user';
import {ResourcesManager} from "@/Ship";

export default () => {
    const team = useUserResources('Team');
    if (!team.isLoaded && !team.isLoading) {
        team.sort('id', 'desc')
        //team.setIncludes([]);
        team.load();
    }

    ResourcesManager.addResource(team, 'Team');

    return team;
};
