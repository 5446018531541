<script setup>

import { computed, ref, defineEmits } from 'vue';
import { useUserActions } from '@user';
import { useProfileActions } from '@profile';
import { useSettingsActions } from '@settings';

const impersonate = useProfileActions('impersonate');

const emit = defineEmits(['performed','close']);

const getUserResource = useUserActions('getUserResource');
const users = getUserResource();
if (!users.isLoaded && !users.isLoading) {
    users.load();
}

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource('roles');

const role = ref();
const id = ref();

const userList = computed(() => users.state.filter(user => {
    return user.roles.map(role => role.name).includes(role.value);
}));
const onImpersonate = () => {
    impersonate(id.value)
    emit('close');
};

</script>

<template>
    <form class="impersonate">
        <g-select v-model="role"
                  label="Role"
                  v-bind:options="settings.roles || []"
                  option-text="name"
                  option-value="name"/>
        <g-select v-model="id"
                  v-bind:label="$t('parts.users', 'Users')"
                  v-bind:options="userList"
                  option-text="name"
                  option-value="id"
                  v-bind:disabled="!role"/>
    </form>

    <g-flex class="btn-bar" gap="5" justify="end">
        <g-button class="cancel-btn"
                  type="button"
                  v-on:click="$emit('close')">
            {{ $t('base.cancel', 'Cancel') }}
        </g-button>
        <g-button class="make save-btn"
                  type="submit"
                  v-on:click="onImpersonate"
                  v-bind:disabled="!id">
            {{ $t('profile.impersonate', 'Impersonate') }}
        </g-button>
    </g-flex>
</template>


<style scoped lang="scss">
.impersonate {
    width: 320px;

    & > .g-field,
    & > .g-select {
        padding-top: 10px;
    }

    :deep(.datalist) {
        max-height: 160px;
        max-width: 100%;
        overflow-x: hidden;
    }
}

fieldset {
    margin-top: 15px;
    gap: 10px;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    & > .g-field,
    & > .g-select {
        width: calc(50% - 5px);
    }
}

.btn-bar {
    margin-top: 86px;
}

.g-button {
    min-width: 100px;
    padding: 0 20px;
}
</style>