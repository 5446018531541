import { useBuyerResources } from '@buyer';
import { ResourcesManager } from "@/Ship";

export default () => {
    const buyer = useBuyerResources('Buyer');
    if (!buyer.isLoaded && !buyer.isLoading) {
        //buyer.setIncludes([]);
        buyer.load();
    }

    ResourcesManager.addResource(buyer, 'Buyer');

    return buyer;
};
