import { useUserResources } from '@user';
import {ResourcesManager} from "@/Ship";

export default () => {
    const whitelist = useUserResources('Whitelist');
    if (!whitelist.isLoaded && !whitelist.isLoading) {
        //whitelist.setIncludes([]);
        whitelist.load();
    }

    ResourcesManager.addResource(whitelist, 'Whitelist');

    return whitelist;
};
