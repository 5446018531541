<script setup>
import { computed, ref, reactive, onMounted } from 'vue';
import { useAssetActions } from '@asset';
import { useProfileActions } from '@profile';
import { preloaderAligner } from '@/Ship/components/global/preloaders/scrollHellper';

const { getSettings } = useAssetActions();
const settings = getSettings();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const levelName = ref('');
const alias = ref('');
const leverages = ['2', '3', '5', '10', '15', '20', '25'];
const newLevel = reactive({});

let timeout = null;

onMounted(() => {
    preloaderAligner('.environment');
});

const selectedLeverages = computed({
    get() {
        return levels.value[levelName.value]?.leverages || newLevel[levelName.value]?.leverages || [];
    },
    set(value) {
        if (Object.keys(levels.value).includes(levelName.value)) {
            levels.value[levelName.value].leverages = value.sort((a, b) => a - b);
        } else {
            if (!newLevel[levelName.value]) {
                newLevel[levelName.value] = {
                    leverages: []
                };
            }
            newLevel[levelName.value].leverages = value.sort((a, b) => a - b);
        }

        return true;
    }
});

const payload = reactive({});

const brands = ref([{v: process.env.VUE_APP_BRAND}]);
const brand = ref(process.env.VUE_APP_BRAND);

const levels = computed({
    get: () => settings.model['account-levels']
        ? settings.model['account-levels'][brand.value] || {}
        : {},
    set(value) {
        !settings.model['account-levels'] || Array.isArray(settings.model['account-levels'])
            ? settings.model['account-levels'] = {
                [brand.value]: value
            }
            : settings.model['account-levels'][brand.value] = value;

        return true;
    }
});

function addLevel() {
    if (!Object.keys(levels.value).includes(levelName.value)) {
        if (!newLevel[levelName.value]) {
            newLevel[levelName.value] = {
                leverages: []
            };
        }

        if (Object.keys(levels.value).length) {
            levels.value[levelName.value] = newLevel[levelName.value];
        } else {
            levels.value = newLevel;
        }

    }

    settings.save('account-levels', {
        'account_levels': settings.model['account-levels']
    }).then(() => {
        delete newLevel[levelName.value];
    });
}

function removeLevel(level) {
    delete settings.model['account-levels'][brand.value][level];

    clearTimeout(timeout);
    timeout = setTimeout(() => {
        settings.save('account-levels', { 'account_levels': settings.model['account-levels'] });
    }, 1000);
}
</script>

<template>
    <fieldset class="account-levels">
        <legend>{{ $t('lead.account_level') }}</legend>

        <g-preloader-content :is-loading="settings.isLoading"/>

        <g-flex direction="column" justify="between" gap="10">
            <g-select v-if="brands.length > 1"
                      v-model="brand"
                      v-bind:options="brands"
                      option-text="v"
                      option-value="v"
                      v-bind:label="$t('settings.brand', 'Brand')"/>

            <div class="container custom-scrollbar">
                <table>
                    <thead>
                    <tr>
                        <th class="index">#</th>
                        <th class="level">Level</th>
                        <!--<th class="alias">Alias</th>-->
                        <th class="leverages">Leverage</th>
                        <th class="action"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(level, key) in levels"
                        v-bind:key="`account-${level}`"
                        v-on:click="levelName = key">
                        <td class="index">{{ Object.keys(levels).indexOf(key) + 1 }}</td>
                        <td class="level">{{ key }}</td>
                        <!--<td class="alias">{{ accountLevel.alias }}</td>-->
                        <td class="leverages">
                            <span v-for="leverage in level.leverages">✕{{ leverage }}</span>
                        </td>
                        <td class="action">
                            <g-symbol name="close"
                                      width="16"
                                      height="16"
                                      class="remove-btn"
                                      v-on:click.stop="removeLevel(key)"/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <form v-on:submit.prevent>
                <fieldset v-bind:disabled="profile.cant('Settings AccountLevelEdit')">
                    <g-flex align="end" gap="8">

                        <g-field v-model="levelName"
                                 class="level"
                                 v-bind:label="$t('settings.level_name', 'Level name')"/>

                        <g-select v-if="brands.length > 1"
                                  v-model="brand"
                                  v-bind:options="brands"
                                  option-text="v"
                                  option-value="v"
                                  class="brand"
                                  v-bind:label="$t('settings.brand', 'Brand')"/>

                        <g-button v-bind:class="Object.keys(levels).includes(levelName) ? 'save-btn' : 'create-btn'"
                                  v-on:click="addLevel"
                                  v-bind:disabled="!levelName">
                            {{
                                Object.keys(levels).includes(levelName)
                                    ? $t('base.save', 'Save')
                                    : $t('base.create', 'Create')
                            }}

                        </g-button>
                    </g-flex>

                    <div class="leverages">
                        <label>{{ $t('settings.available_leverages', 'Available leverages') }}:</label>
                        <g-checkbox v-for="leverage in leverages"
                                    v-bind:key="`leverage-{leverage}`"
                                    v-model="selectedLeverages"
                                    v-bind:value="leverage"
                                    v-bind:disabled="!levelName">
                            ✕{{ leverage }}
                        </g-checkbox>
                    </div>
                </fieldset>
            </form>
        </g-flex>
    </fieldset>
</template>

<style scoped lang="scss">
.account-levels {
    padding: 10px 15px 15px;
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 1px solid var(--separator-color, $separator-color);

    & > .g-flex {
        height: 100%;
        max-height: 520px;
    }

    .container {
        flex-grow: 1;
    }
}

table {
    width: 100%;

    tr {
        cursor: pointer;
        text-transform: capitalize;

        &:nth-child(odd) {
            background-color: var(--env-bg, $env-bg);
        }

        &:nth-child(even) {
            background-color: var(--bar-bg, $bar-bg);
        }

        & > td,
        & > th {
            text-align: left;
            padding: 8px;

            &.level {
                white-space: nowrap;
                color: var(--title-text-color, $title-text-color);
            }

            &.leverages {
                width: 100%;

                & > span {
                    margin: 0 4px;
                    display: inline-block;
                }
            }

            &.action {
                text-align: right;

                .g-symbol {
                    fill: var(--danger, $danger);

                    &:hover {
                        fill: var(--danger-hover, $danger-hover);
                    }
                }
            }
        }

        & > th {
            color: var(--title-color, $title-color);
        }
    }
}

form {
    padding: 10px;
    background-color: var(--bar-bg, $bar-bg);
    border: 1px solid var(--separator-color, $separator-color);

    .g-field {
        flex-grow: 1;
    }

    .brand {
        width: 168px;
    }

    .leverages {
        padding: 10px 0;
        margin-top: 10px;

        & > label {
            display: block;
        }

        & > .g-checkbox {
            margin-right: 5px;
            display: inline-block;
        }
    }
}

.g-button {
    color: white;
    padding: 0 15px;
    width: 100px;
    white-space: nowrap;

    &.create-btn {
        background-color: var(--success, $success);
    }
}
</style>