<script setup>

import { ref, defineEmits, computed } from 'vue';
import { useAssetActions } from '@asset';

const emit = defineEmits(['performed', 'close']);

const getPairResource = useAssetActions('getPairResource');
const pairs = getPairResource();

const market = ref('crypto');
const symbol = ref('BTCUSDT');
const counter = ref('USDT');
const multiplier = ref('1%');

function add() {
    emit('performed', symbol.value, multiplier.value);
}
</script>

<template>
    <form class="add-multiplier" v-on:submit.prevent="add">

        <g-flex gap="8">
            <g-select v-model="market"
                      v-bind:options="[{m: 'crypto'}, {m: 'stocks'}]"
                      option-value="m"
                      option-text="m"
                      v-on:change="counter = 'USDT'"
                      v-bind:label="$t('trading.market', 'Market')"/>

            <g-select v-model="counter"
                      v-bind:options="[{c: 'USDT'}, {c: 'BTC'}, {c: 'ETH'}]"
                      option-value="c"
                      option-text="c"
                      v-bind:disabled="market !== 'crypto'"
                      v-bind:label="$t('trading.counter_currency', 'Counter Currency')"/>
        </g-flex>

        <g-flex gap="8">
            <g-fade-container icon="wallet" v-bind:label="symbol">
                <div class="assets">
                    <label v-for="asset in pairs.state.filter(pair => (pair.market === market && pair.counter === counter &&
        pair.is_active === 1 ))" class="currency">
                        <input type="radio" v-model="symbol" name="currency" v-bind:value="asset.symbol">
                        <span> <g-symbol-currency v-bind:name="asset.base.toLowerCase()" width="22" height="22"/> {{ asset.base }}:{{ asset.counter }}</span>
                    </label>
                </div>
            </g-fade-container>

            <g-field v-model="multiplier" v-bind:label="$t('lead.multiplier', 'Multiplier')"/>
        </g-flex>

        <g-flex gap="8" justify="end">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">{{
                    $t('base.cancel', 'Cancel')
                }}
            </g-button>
            <g-button class="apply-btn" type="submit">{{ $t('base.add', 'Add') }}</g-button>
        </g-flex>
    </form>
</template>

<style lang="scss" scoped>
.add-multiplier {
    width: 300px;
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.g-button {
    width: 100px;

    &.apply-btn {
        background-color: var(--primary, $primary);
        color: var(--btn-primary-color, $btn-primary-color);

        &:hover {
            color: var(--btn-primary-color, $btn-primary-color);
            background-color: var(--primary-hover, $primary-hover);
        }
    }

    &.cancel-btn {
        background-color: transparent;
        color: var(--main-text-color, $main-text-color);
        border: 1px solid var(--separator-color, $main-text-color);

        &:hover {
            color: var(--title-text-color, $title-text-color);
            border-color: var(--main-text-color, $main-text-color);
        }
    }
}

.g-fade-container {
    width: 100%;
    margin: 10px 0;

    &:deep(.container) {
        padding: 0 20px;
        max-height: 280px;
        overflow-y: auto !important;
        background-color: var(--bar-bg, $env-bg);

        & > .assets {
            column-count: 2;

            & > .currency {
                display: inline-block;
                width: 100%;
                cursor: pointer;
                white-space: nowrap;
                color: var(--main-text-color, $main-text-color);

                & > span {
                    gap: 10px;
                    display: flex;
                    align-items: center;
                }

                &:hover {
                    color: var(--title-text-color, $title-text-color);
                }
            }
        }
    }
}
</style>