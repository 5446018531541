<script setup>

import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useUserActions, useUserComponents } from '@user';
import { useProfileActions } from '@profile';
import { preloaderAligner } from '@/Ship/components/global/preloaders/scrollHellper';
import { i18n, PopUpManager as PopUp, STATE } from '@/Ship';

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getUserResource = useUserActions('getUserResource');
const users = getUserResource();

const Indexbar = useUserComponents('Indexbar');

const router = useRouter();

function openUser(id) {
    let isLock = false;
    Object.keys(STATE.UNSAVED_DATA).forEach(key => {
        if (STATE.UNSAVED_DATA[key].length > 0) {
            isLock = true;
        }
    });
    if (isLock) {
        PopUp.open(require('@/Ship/components/PopUps/UnSavedAlert')).setCaption(i18n.global.t('message.saving_data', 'Saving data'));
    } else {
        users.index = users.state.findIndex(item => item.id === id);
        router.push({ name: 'User', params: { ...router.currentRoute.value.params, id } });
    }
}

function sortBy(field) {
    if (users.sortBy === field) {
        profile.state.user.sortDir = users.sortDir === 'asc'
            ? 'desc'
            : 'asc';
    }
    profile.state.user.sortBy = field;
    profile.saveState();

    users.sort(
        profile.state.user.sortBy,
        profile.state.user.sortDir
    );

    users.load();
}


users.sort(
    profile.state.user.sortBy,
    profile.state.user.sortDir
);

users.load(null, {}, 'Users Environment');

if (router.currentRoute.value.params.id) {
    users.onload.push(() => {
        openUser(Number(router.currentRoute.value.params.id));
    });
}

preloaderAligner('.environment');

</script>

<template>
    <div class="users environment">
        <indexbar/>
        <div class="index">

            <div class="row header">
                <!-- ID -->
                <div class="id cell title"
                     v-bind:class="{[`sorted ${users.sortDir}`]:  users.sortBy === 'id' }"
                     v-on:click="sortBy('id')">
                    <g-flex align="center" gap="5" inline>
                        ID
                        <g-symbol name="arrow-down" width="14" height="14"/>
                    </g-flex>
                </div>

                <!-- Name -->
                <div class="name cell title"
                     v-bind:class="{[`sorted ${users.sortDir}`]:  users.sortBy === 'name' }"
                     v-on:click="sortBy('name')">
                    <g-flex align="center" gap="5" inline>
                        {{ $t('user.name', 'Name') }}
                        <g-symbol name="arrow-down" width="14" height="14"/>
                    </g-flex>
                </div>

                <!-- Email -->
                <div class="email cell title"
                     v-bind:class="{[`sorted ${users.sortDir}`]:  users.sortBy === 'email' }"
                     v-on:click="sortBy('email')">
                    <g-flex align="center" gap="5" inline>
                        Email
                        <g-symbol name="arrow-down" width="14" height="14"/>
                    </g-flex>
                </div>

                <!-- Roles -->
                <div class="roles cell title"
                     v-bind:class="{[`sorted ${users.sortDir}`]:  users.sortBy === 'roles' }"
                     v-on:click="sortBy('roles')">
                    <g-flex align="center" gap="5" inline>
                        {{ $t('user.roles', 'Roles') }}
                        <g-symbol name="arrow-down" width="14" height="14"/>
                    </g-flex>
                </div>

                <!-- Brands -->
                <div class="brands cell title"
                     v-bind:class="{[`sorted ${users.sortDir}`]:  users.sortBy === 'brands' }"
                     v-on:click="sortBy('brands')">
                    <g-flex align="center" gap="5" inline>
                        {{ $t('user.brands', 'Brands') }}
                        <g-symbol name="arrow-down" width="14" height="14"/>
                    </g-flex>
                </div>

                <!-- Teams -->
                <div class="teams cell title"
                     v-bind:class="{[`sorted ${users.sortDir}`]:  users.sortBy === 'teams' }"
                     v-on:click="sortBy('teams')">
                    <g-flex align="center" gap="5" inline>
                        {{ $t('user.teams', 'Teams') }}
                        <g-symbol name="arrow-down" width="14" height="14"/>
                    </g-flex>
                </div>

                <!-- Teamleads -->
                <div class="teamleads cell title"
                     v-bind:class="{[`sorted ${users.sortDir}`]:  users.sortBy === 'teamleads' }"
                     v-on:click="sortBy('teamleads')">
                    <g-flex align="center" gap="5" inline>
                        {{ $t('user.teamleads', 'Teamleads') }}
                        <g-symbol name="arrow-down" width="14" height="14"/>
                    </g-flex>
                </div>

                <!-- Status -->
                <div class="status cell title"
                     v-bind:class="{[`sorted ${users.sortDir}`]:  users.sortBy === 'status' }"
                     v-on:click="sortBy('status')">
                    <g-flex align="center" gap="5" inline>
                        {{ $t('user.status', 'Status') }}
                        <g-symbol name="arrow-down" width="14" height="14"/>
                    </g-flex>
                </div>

                <!-- Created at -->
                <div class="date cell title"
                     v-bind:class="{[`sorted ${users.sortDir}`]:  users.sortBy === 'created_at' }"
                     v-on:click="sortBy('created_at')">
                    <g-flex align="center" gap="5" inline>
                        {{ $t('user.created_at', 'Created at') }}
                        <g-symbol name="arrow-down" width="14" height="14"/>
                    </g-flex>
                </div>
            </div>

            <g-preloader-content v-bind:is-loading="users.isLoading"/>
            <div class="row"
                 v-for="user in users.state"
                 v-bind:key="`user-${user.id}`"
                 v-on:click="openUser(user.id)"
                 v-bind:class="[{'open': $route.params.id === user.id.toString()}, user.status.toLowerCase()]">

                <!-- ID -->
                <div class="id cell" v-bind:class="{ sorted: users.sortBy === 'id' }">
                    {{ user.id }}
                </div>

                <!-- Name -->
                <div class="name cell" v-bind:class="{ sorted: users.sortBy === 'name' }">
                    {{ user.name }}
                </div>

                <!-- Email -->
                <div class="email cell" v-bind:class="{ sorted: users.sortBy === 'email' }">{{ user.email }}</div>

                <!-- Roles -->
                <div class="role cell" v-bind:class="{ sortable: users.sortBy === 'roles' }">
                    <div v-bind:title="user.roles.map(role => role.name).join(', ')">{{ user.roles.map(role => role.name).join(', ') }}</div>
                </div>

                <!-- Brands -->
                <div class="brand cell" v-bind:class="{ sorted: users.sortBy === 'brands' }">
                    <div v-bind:title="user.brands.map(brand => brand.name).join(', ')">{{ user.brands.map(brand => brand.name).join(', ') }}</div>
                </div>

                <!-- Teams -->
                <div class="team cell" v-bind:class="{ sorted: users.sortBy === 'teams' }">
                    <div v-bind:title="user.teams.map(team => team.name).join(', ')">
                        {{ user.teams.map(team => team.name).join(', ') }}
                    </div>
                </div>

                <!-- Teamleads -->
                <div class="teamlead cell" v-bind:class="{ sorted: users.sortBy === 'teamleads' }">
                    <div v-bind:title="user.teamleads.map(teamlead => teamlead).join(', ')">
                        {{ user.teamleads.map(teamlead => teamlead).join(', ') }}
                    </div>
                </div>

                <!-- Status -->
                <div class="status cell" v-bind:class="{ sorted: users.sortBy === 'status' }">
                    {{ user.status }}
                </div>

                <!-- Created at -->
                <div class="date cell" v-bind:class="{ sorted: users.sortBy === 'created_at' }">
                    {{ user.created_at }}
                </div>
            </div>
            <div class="info-block" v-if="!users.state.length && !users.isLoading">
                {{ $t('base.nothing_found') }}
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.environment {
    position: relative;
    background-color: var(--env-bg, $env-bg);
    height: 100%;
}

.index {
    position: relative;
    min-width: 100%;
    padding: 0 10px;
}

.preloader-wrapper {
    height: 100vh !important;
}

.row {
    fill: var(--main-text-color, $main-text-color);

    &:nth-child(odd) {
        background-color: var(--env-bg, $env-bg);
    }

    &:nth-child(even) {
        background-color: var(--bar-bg, $bar-bg);
    }

    &:not(.header) {
        cursor: pointer;

        & > .cell {
            border: 0 solid transparent;
            border-width: 1px 0;
        }

        &:hover > .cell,
        &.open > .cell {
            border-color: var(--success, $success);
        }
    }

    &.suspended {
        opacity: 0.7;
        color: var(--warning, $warning) !important;
    }

    &.deleted {
        opacity: 0.3;
    }
}

.cell {
    & > div {
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5em;
        max-height: 3em;
    }

    &.sorted {
        color: var(--sorted-text-color, $sorted-text-color);
        background-color: var(--sorted-bg, $sorted-bg);
    }

    &.title {
        & > .g-flex {
            cursor: pointer;
        }

        &.sorted {
            font-weight: bold;
            fill: var(--sorted-text-color, $sorted-text-color);

            &.desc {
                .g-symbol {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &.id,
    &.date,
    &.status {
        white-space: nowrap;
        text-align: center;
    }
}

@media (min-width: 1025px) {
    .index {
        display: table;

        .row {
            display: table-row;

            & > .cell {
                padding: 10px 8px;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}

@media (max-width: 1024px) {
    .index {
        display: table;

        .row {
            display: table-row;

            & > .cell {
                padding: 8px 5px;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }
}
</style>
