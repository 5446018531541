<template>
    <div class="profile-info">
        <div class="person">
            <div class="text profile-title">
                <div class="name">{{ profile.first_name }} {{ profile.last_name }}</div>
                <div class="subscription"><b>{{ subscription }}</b></div>
            </div>
        </div>
        <div class="progress">
            <div class="title">
                Rating
                <span>{{ ratingPercent }}%</span>
            </div>
            <progress max="100" :value="ratingPercent"></progress>
            <div class="title">
                Profile Completion
                <span> {{ authRating }}%</span>
            </div>
            <progress max="100" :value="authRating"></progress>
        </div>
        <div class="balance data">
            <span class="operation">Balance:</span>
            <span class="value"><g-symbol-currency name="usdt" width="24" height="24"/> {{ balance }}</span>
        </div>
        <div class="bonuses data">
            <span class="operation">Bonus:</span>
            <span class="value"><g-symbol-currency name="usdt" width="24" height="24"/> {{ bonuses }}</span>
        </div>
        <div class="positions data">
            <span class="operation">Positions fee:</span>
            <span class="value">{{ positionsFee }}</span>
        </div>
    </div>
</template>

<script>
import { useProfileResource, useProfileActions } from '@profile';
import { computed } from 'vue';
import GSymbolCurrency from '@/Ship/components/global/symbol/GSymbolCurrency.vue';

export default {
    name: 'Profile-info',
    components: { GSymbolCurrency },
    setup() {
        const profile = useProfileResource('Profile');

        const subscription = computed(() => {
            return profile.subscription;
        });

        const ratingPercent = computed(() => {
            return profile.rating_percent || 0;
        });

        const authRating = computed(() => {
            return profile.auth_rating || 0;
        });

        const balance = computed(() => {
            return profile.balance || 0;
        });

        const bonuses = computed(() => {
            return profile.bonuses || 0;
        });

        const positionsFee = computed(() => {
            return profile.positions_fee || 0;
        });

        return {
            profile,
            subscription,
            ratingPercent,
            authRating,
            balance,
            bonuses,
            positionsFee
        };
    }
};
</script>

<style lang="scss" scoped>
.profile-info {
    min-width: 280px;
    border-radius: 20px;
    display: grid;
    box-shadow: var(--profile-menu-shadow, $profile-menu-shadow);
    border: 1px solid var(--separator-color, $separator-color);

    .person {
        grid-area: prsn;
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .progress {
        grid-area: prgs;

        progress {
            margin-bottom: 10px;
        }
    }

    .balance {
        grid-area: blns;
    }

    .bonuses {
        grid-area: bnss;
    }

    .positions {
        grid-area: poss;
    }

    .orders {
        grid-area: ords;
    }

    .closed {
        grid-area: clsd;
    }

    .turnover {
        grid-area: trvr;
    }

    .with-us {
        grid-area: wtus;
    }

    .referral {
        grid-area: rfrl;
    }

    .profile-title {
        width: 100%;
        word-break: break-word;
    }

    .name {
        font-size: 16px;
        font-weight: bold;
    }

    .subscription {
        text-transform: uppercase;
    }

    #upload {
        display: none;
    }

    .img {
        position: relative;
        width: fit-content;
        margin-right: 30px;

        img {
            border-radius: 50%;
            width: 110px;
            height: 110px;
            display: block;
            cursor: pointer;
            margin: auto;
            border: 3px solid;
            padding: 1px;
        }
    }

    .level {
        width: 32px;
        height: 34px;
        font-size: 14px;
        font-weight: bold;
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 32px;
            height: 32px;
            border: none;
        }
    }

    .data {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        color: var(--title-text-color, $title-text-color);

        .value {
            font-weight: bold;
            color: var(--main-text-color, $main-text-color);
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .g-symbol-currency {
            margin-right: 4px;
        }
    }

    .title {
        font-size: 14px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--title-text-color, $title-text-color);

        span {
            color: var(--main-text-color, $main-text-color);
        }
    }

    button {
        color: white;
        font-size: 11px;
        font-weight: bold;
        padding: 5px 10px;
        background-color: var(--primary, $primary);
    }
}

progress {
    width: 100%;
    height: 6px;
    appearance: none;
    margin-top: auto;

    &::-webkit-progress-bar {
        background: var(--field-bg, $field-bg);
        border-radius: 5px;
    }

    &::-webkit-progress-value {
        border-radius: 5px;
        background: var(--primary, $primary);
    }
}

@media (max-width: 644px) {
    .profile-info {
        padding: 20px;
        grid-gap: 8px;
        grid-template-areas:
            'prsn'
            'prgs'
            'blns'
            'bnss'
            'poss'
            'ords'
            'clsd'
            'trvr'
            'wtus'
            'rfrl';
        grid-template-columns: 1fr;
        margin: 1px 6px 10px 6px;

        .data {
            font-size: 14px;
        }
    }
}

@media (min-width: 645px) AND (max-width: 1024px) {
    .profile-info {
        padding: 30px;
        margin: 1px 6px 10px 6px;
        grid-row-gap: 8px;
        grid-column-gap: 16vw;
        grid-template-areas:
            'prsn prgs'
            'blns ords'
            'bnss poss'
            'trvr clsd'
            'rfrl wtus';
        grid-template-columns: 1.1fr 0.9fr;
    }
}

@media (min-width: 1025px) {
    .profile-info {
        grid-gap: 10px;
        grid-template-areas:
            'prsn'
            'prgs'
            'blns'
            'bnss'
            'poss'
            'ords'
            'clsd'
            'trvr'
            'wtus'
            'rfrl';
        grid-template-columns: 1fr;
        grid-template-rows: 120px 100px repeat(8, 30px);
    }
}

@media (min-width: 825px) AND (max-width: 1524px) {
    .profile-info {
        padding: 20px;
    }
}

@media (min-width: 1525px) {
    .profile-info {
        padding: 30px;
    }
}
</style>
