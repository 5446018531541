<template>
    <form class="g-search" v-on:submit.prevent="find">
        <fieldset class="field filled" v-bind:class="{focus: isFocus}">

            <legend>{{ label }}</legend>

            <input ref="search"
                   v-model="searchString"
                   @input="find"
                   type="search"
                   v-on:keypress="$event.keyCode === 13 && find()">


            <g-symbol v-if="searchString" class="clear-btn" name="close" width="20" height="20" v-on:click="reset"/>
            <g-symbol class="find-btn"
                      name="search"
                      width="20"
                      height="20"
                      v-on:click="find"/>
        </fieldset>
    </form>
</template>

<script>

import { ref } from 'vue';

export default {
    name: 'GSearchInput',
    inheritAttrs: false,
    emits: ['find', 'drop'],
    props: {
        label: {
            type: String,
        }
    },
    setup(props, { emit }) {
        const find = () => {
            emit('find', searchString.value );
        };

        const reset = () => {
            searchString.value = '';
            emit('drop');
        };

        const search = ref();
        const datalist = ref(null);
        const isFocus = ref(false);
        const toTop = ref(false);
        const searchString = ref('');


        return {
            search,
            toTop,
            isFocus,
            datalist,
            searchString,
            reset,
            find,
        };
    }
};
</script>

<style lang="scss" scoped>
.field {
    position: relative;
    padding: 0 10px 0 0;
    display: flex;
    white-space: nowrap;
    align-items: center;
    color: var(--main-text-color, $main-text-color);
    border: 1px solid var(--input-border, $input-border);
    border-radius: var(--input-rounding, $input-rounding);
    height: calc(var(--input-height, $input-height) + 7px);
    background-color: var(--input-active-bg, $input-active-bg);

    legend {
        height: 14px;
        line-height: 14px;
        padding: 0 2px;
        margin-left: 8px;
        margin-bottom: -6px;
        color: var(--label-color, $label-color);
    }

    input {
        flex-grow: 1;
        width: 100%;
        height: 100%;
        border: none;
        padding-left: 10px;
        box-sizing: border-box;
        background-color: transparent;
        color: var(--title-color, $title-color);
        font-size: var(--input-font-size, $input-font-size);
        font-family: var(--font-primary, $font-primary);

        &.by-field {
            width: 70px;
            cursor: default;
        }

        &:focus {
            outline: none;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            background: transparent;
            transition: background-color 5000s ease-in-out 0s;
            -webkit-text-fill-color: var(--main-text-color, $main-text-color);
        }
    }

    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    &:deep(.g-symbol) {
        cursor: pointer;
        flex-shrink: 0;
        margin-left: 4px;
        fill: var(--main-text-color, $main-text-color);

        &:hover {
            fill: var(--title-color, $title-color);
        }
    }

    &:not(.focus) {
        & > .datalist {
            opacity: 0;
            max-height: 0;
            border-width: 0;
            overflow: hidden;
            transition:
                opacity 0.2s 0.1s,
                max-height 0.3s,
                border-width 0s 0.3s;
        }
    }
}
</style>