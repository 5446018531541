import { usePermissionResources } from '@permission';
import {ResourcesManager} from "@/Ship";

export default () => {
    const permission = usePermissionResources('Permission');
    if (!permission.isLoaded && !permission.isLoading) {
        //permission.setIncludes([]);
        permission.load();
    }

    ResourcesManager.addResource(permission, 'Permission');

    return permission;
};
