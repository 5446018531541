<template>
    <div class="wrapper">
        <topbar/>
<!--        <div class="topbar">-->
<!--            <topbar/>-->
<!--            <div class="toolbar">-->
<!--                <slot/>-->
<!--                <save-indicator/>-->
<!--                <account-menu/>-->
<!--            </div>-->
<!--            <g-lang-select v-bind:lang-list="languages"/>-->
<!--        </div>-->
        <leftbar>
            <sidebar v-if="sidebar"/>
        </leftbar>
        <environment class="custom-scrollbar"/>
        <transition v-if="rightbar" name="rightbar" mode="out-in">
            <router-view class="rightbar"/>
        </transition>
    </div>
</template>

<script>


import { useEnvComponents } from '@overlay';
import SaveIndicator from './SaveIndicator.vue';

const {
    Topbar,
    Leftbar,
} = useEnvComponents();

export default {
    name: 'Layout',
    components: {
        SaveIndicator,
        Topbar,
        Leftbar,
    },
};
</script>

<style lang="scss">
.wrapper {
    min-height: 100vh;
    background-color: var(--body-bg, $body-bg);
    overflow: hidden;
    display: grid;
    grid-gap: 4px;
    grid-template-areas:
            'tbr tbr tbr'
            'lbr env rbr';
    grid-template-rows: var(--bar-height, $bar-height)  calc(100vh - var(--bar-height, $bar-height) - 8px);
    grid-template-columns: auto 1fr min-content;
}

.leftbar {
    grid-area: lbr;
    position: relative;
    border-left: 1px solid var(--separator-color, $separator-color);
    box-shadow: -10px 0 18px -10px #00000050;
    width: var(--rightbar-width, $rightbar-width);
    max-width: 100%;
}

.environment {
    grid-area: env;
}

.rightbar {
    grid-area: rbr;
    position: relative;
    border-left: 1px solid var(--separator-color, $separator-color);
    box-shadow: -10px 0 18px -10px #00000050;
    width: var(--rightbar-width, $rightbar-width);
    max-width: 100%;

    .viewbar {
        z-index: 3;
        top: 8px;
        position: absolute;
        font-size: 0;
        left: -36px;

        & > .close-btn {
            fill: white;
            padding: 6px;
            display: block;
            cursor: pointer;
            background-color: var(--danger, $danger);

            &:hover {
                background-color: var(--danger-hover, $danger-hover);
            }
        }
    }

    & > .card {
        height: 100%;
        overflow: hidden;
        width: var(--rightbar-width, $rightbar-width);
    }
}

.rightbar-enter-active {
    transition: width 0.4s;
}

.rightbar-enter-from,
.rightbar-leave-active {
    width: 0;
    transition: width 0.1s;
}

@media (min-width: 985px) {
    .topbar {
        padding: 0 10px;
    }

    @media (max-width: 1284px) {
        .wrapper {
            grid-template-columns: min-content 1fr min-content;
        }
    }
}

@media (max-width: 984px) {
    .environment {
        height: calc(100vh - var(--bar-height, $bar-height) - 8px);
    }

    .topbar {
        top: 0;
        padding: 0 8px;
        position: sticky;
    }

    .leftbar {
        z-index: 3;
    }

    .rightbar {
        right: 0;
        margin-top: 145px;
        position: fixed;
        z-index: 2;
        background-color: var(--env-bg, $env-bg);
        height: calc(100vh - (var(--bar-height, $bar-height) + 148px));
        transition: width 0.3s;
    }
}

@media (max-width: 645px) {
    .rightbar {
        overflow-x: scroll;
        width: 100vw;

        .viewbar {
            left: -36px;

            & > .close-btn {
                border-radius: var(--btn-rounding, $btn-rounding);
                position: relative;
                left: 500px;
                width: 38px;
            }
        }
    }
}

@media (max-width: 644px) {
    .rightbar {
        .viewbar {
            right: 0;
        }
    }
}
</style>
