<template>
    <aside class="leftbar" v-bind:class="{show: STATE.IS_SHOW_SIDEBAR}">
        <nav class="linkbar">
            <div class="bar">
                <span v-if="can('Lead View')"
                      class="link"
                      v-on:click="open('Leads')">
                    <g-symbol name="customers" width="22" height="22"/>
                </span>

                <span v-if="can('Campaign View')"
                      class="link"
                      v-on:click="open('Campaigns')">
                    <g-symbol name="offers" width="22" height="22"/>
                </span>

                <span v-if="can('Finances View')"
                      class="link"
                      v-on:click="open('Finances')">
                    <g-symbol name="tether" width="26" height="26"/>
                </span>

            </div>
            <div class="bar">
                <span v-if="can('User View')"
                      class="link"
                      v-on:click="open('Users')">
                    <g-symbol name="users" width="22" height="22"/>
                </span>
                <span v-if="can('Settings View')"
                      class="link"
                      v-on:click="open('Settings')">
                    <g-symbol name="settings" width="22" height="22"/>
                </span>
            </div>
        </nav>
        <transition name="fade" appear>
            <div class="mainbar custom-scrollbar" v-if="$slots.default">
                <slot>
                    <nav class="navigation">
                        <div class="bar">
                            <span v-if="can('Lead View')"
                                  class="link"
                                  v-on:click="open('Leads')">
                                {{ $t('parts.leads', 'Leads') }}
                            </span>

                            <span v-if="can('Campaign View')"
                                  class="link"
                                  v-on:click="open('Campaigns')">
                                {{ $t('parts.campaigns', 'Campaigns') }}
                            </span>
                            <span v-if="can('Finances View')"
                                  class="link"
                                  v-on:click="open('Finances')">
                                {{ $t('parts.finances', 'Finances') }}
                            </span>

                        </div>
                        <div class="bar">
                            <span v-if="can('User View')"
                                         class="link"
                                         v-on:click="open('Users')">
                                {{ $t('parts.users', 'Users') }}
                            </span>
                            <span v-if="can('Settings View')"
                                         class="link"
                                         v-on:click="open('Settings')">
                                {{ $t('parts.settings', 'Settings') }}
                            </span>
                        </div>
                    </nav>
                </slot>
            </div>
        </transition>
    </aside>
</template>

<script>

import {i18n, PopUpManager as PopUp, STATE} from '@/Ship';
import { useRouter } from 'vue-router';
import { useProfileActions } from '@profile';

const can = useProfileActions('can');

export default {
    name: 'Leftbar',

    setup() {

        const router = useRouter();

        function open(section) {
            let isLock = false;
            Object.keys(STATE.UNSAVED_DATA).forEach(key => {
                if (STATE.UNSAVED_DATA[key].length > 0) {
                    isLock = true;
                }
            });
            isLock
                ? PopUp.open(require('@/Ship/components/PopUps/UnSavedAlert')).setCaption(i18n.global.t('message.saving_data', 'Saving data'))
                : router.push({ name: section, params: router.currentRoute.value.params });
        }

        return {
            STATE,
            can,
            open,
        };
    }
};
</script>

<style lang="scss" scoped>
.leftbar {
    display: flex;
    position: relative;
    overflow: hidden;
    align-items: stretch;
    background-color: var(--sidebar-bg, $sidebar-bg);
    color: var(--sidebar-text-color, $sidebar-text-color);
    left: 0;
    z-index: 2;
    transition: width 0.3s;
    box-shadow: 10px 0 18px -8px #00000055;
    width: var(--sedebar-width, $sidebar-width);
    height: calc(100vh - var(--bar-height, $bar-height));

    &:not(.show) {
        width: var(--bar-height, $bar-height);
    }

    .mainbar {
        flex-grow: 1;
        height: auto;
    }

    @media (max-width: 1439px) {
        .leftbar {
            bottom: 0;
        }

        &:hover {
            width: var(--sedebar-width, $sidebar-width);
        }
    }
}

@media (max-width: 984px) {
    .leftbar {
        left: 0;
        bottom: 0;
        z-index: 3;
        position: fixed;
        transition: width 0.3s;
        box-shadow: 10px 0 18px -8px #00000055;
        top: var(--bar-height, $bar-height) + 100px;
        width: var(--sedebar-width, $sidebar-width);
        height: calc(100vh - var(--bar-height, $bar-height));

        &:not(.show) {
            width: 0;
        }
    }
}

.link {
    display: block;
    height: 42px;
    margin: 2px 0;
    line-height: 38px;
}

.linkbar {
    height: 100%;
    padding: 20px 0;
    width: var(--bar-height, $bar-height);
    background-color: var(--bar-bg, $bar-bg);
    box-shadow: 10px 0 18px -8px #00000077;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .link {
        padding: 10px 0;
        text-align: center;
        border-radius: 5px;
        width: var(--bar-height, $bar-height);
        fill: var(--main-text-color, $main-text-color);

        &:not(:last-child) {
            border-bottom: 1px solid var(--separator-color, $separator-color);
        }

        &:hover {
            fill: var(--title-color, $title-color);
        }

        &.router-link-active {
            fill: var(--success, $success);
        }
    }
}

.navigation {
    flex-grow: 1;
    height: 100%;
    font-size: 15px;
    padding: 20px 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .link {
        line-height: 42px;
        color: var(--sidebar-text-color, $sidebar-text-color);

        &:hover {
            color: var(--primary-hover, $primary-hover);
        }
    }
}

.fade-enter-active {
    transition: transform 0.3s, opacity 0.7s;
}

.fade-enter-from,
.fade-leave-active {
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.3s, opacity 0.7s;
}
</style>