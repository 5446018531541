<script setup>

import { ref, computed } from "vue";
import { useLeadActions } from '@lead';
import { useFinanceActions } from '@finance';

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getWalletResource = useFinanceActions('getWalletResource');
const wallets = getWalletResource({lead_id: [lead.model.id]}, true);

const address = ref();
const currency = ref('BTC');
const currencies = ref([
	{n: 'Bitcoin', v: 'BTC'},
	{n: 'Ethereum', v: 'ETH'},
	{n: 'Tron', v: 'TRX'},
	{n: 'Tether (ERC-20)', v: 'ERC-20'},
	{n: 'Tether (TRC-20)', v: 'TRC-20'},
]);

function addWallet() {
	const params = {
		lead_id: lead.model.id,
		currency: currency.value,
		network: currency.value,
		address: address.value,
		is_active: 1,
	};

	if (currency.value === 'ERC-20') {
		params.currency = 'USDT';
		params.network = 'ETH';
	} else if (currency.value === 'TRC-20') {
		params.currency = 'USDT';
		params.network = 'TRX';
	}

	wallets.create(params);
}

function disactivate(index) {
	wallets.state[index].is_active = !wallets.state[index].is_active;
	wallets.save(wallets.state[index].id, {
		is_active: wallets.state[index].is_active
	});
}

function remove(index) {
	wallets.delete(wallets.state[index].id).then(() => {
		wallets.state.splice(index, 1);
	});
}

const withdrawFunds = computed({
    get: () => lead.model.specifics.withdraw_funds === undefined
        ? false
        : lead.model.specifics.withdraw_funds,
    set: value => {
        const specifics = lead.model.specifics;
        specifics.withdraw_funds = value;
        lead.model.specifics = specifics;

        lead.save();

        return true;
    }
});


</script>

<template>
	<div class="wallets">
        <fieldset class="settings">
            <label>
                <g-switch v-model:checked="withdrawFunds"/> <span>Forwarding funds</span>
            </label>
        </fieldset>

        <fieldset>
            <legend>Forwarding Wallets</legend>
            <table>
			    <tbody>
			    <tr v-for="(wallet, i) in wallets.state" v-bind:key="`wallet-${i}`" v-bind:class="{active: wallet.is_active}">
				    <td class="cell">
					    <g-flex align="center" gap="5">
						    <g-symbol-currency v-bind:name="wallet.currency.toLowerCase()" width="20" height="20"/>
						    <span v-if="wallet.currency === 'USDT'">
							    {{ wallet.currency }}
							    <template v-if="wallet.network === 'Tron'">(TRC-20)</template>
							    <template v-else-if="wallet.network === 'Ethereum'">(ERC-20)</template>
						    </span>
						    <span v-else>{{ wallet.network }}</span>
					    </g-flex>
				    </td>
				    <td class="cell address">
					    <g-copy v-bind:text="wallet.address"/>
				    </td>
				    <td class="cell actions">
					    <g-symbol v-bind:name="wallet.is_active ? 'check' : 'close'"
					              class="is-active"
					              width="16"
                                  height="16"
                                  v-on:click="disactivate(i)"/>
					    <g-symbol name="delete"
					              class="delete"
					              width="16"
                                  height="16"
                                  v-on:click="remove(i)"/>
                    </td>
                </tr>
			    </tbody>
		    </table>

		    <g-flex class="controls" align="end" gap="10">

                <g-select v-model="currency"
                          v-bind:options="currencies"
                          option-text="n"
                          option-value="v"
                          label="Currency"/>

			    <g-field v-model="address"
                        label="Address"
			            v-on:keypress.enter="addWallet"/>

			    <g-button class="add-btn" v-on:click="addWallet">
				    <g-symbol name="import" width="22" height="22"/>
			    </g-button>
		    </g-flex>
        </fieldset>
	</div>
</template>

<style scoped lang="scss">
.wallets {
	background-color: var(--env-bg, $env-bg);
}

fieldset {
    margin: 10px 5px;
    padding: 15px 20px 10px 20px;
    border: 1px solid var(--separator-color, $separator-color);

    & > label {
        gap: 8px;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
    }
}

table {
	width: 100%;
	font-size: 0.9rem;
	white-space: nowrap;
}

tr {
	&:nth-child(odd) {
		background-color: #00000022;
	}

	.g-symbol {
		margin: 0 4px;

		&.delete {
			fill: var(--main-text-color, $main-text-color);

			&:hover {
				fill: var(--title-text-color, $title-text-color);
			}
		}
	}

	&.active > .actions > .is-active {
		fill: var(--success, $success);

		&:hover {
			fill: var(--success-hover, $success-hover);
		}
	}

	&:not(.active) > .actions > .is-active {
		fill: var(--danger, $danger);

		&:hover {
			fill: var(--danger-hover, $danger-hover);
		}
	}
}

td {
	padding: 10px 8px;

	&.address {
		width: 100%;
	}

	&.actions {
		text-align: center;

		& > .g-symbol {
			cursor: pointer;
		}
	}
}

.controls {
	margin-top: 15px;
	background-color: var(--bar-bg, $bar-bg);

	.g-field {
		flex-grow: 1;
	}

	.g-select {
		width: 160px;
	}

	.add-btn {
		color: white;
		padding: 0 15px;
		background-color: var(--save-btn-bg, $save-btn-bg);

		svg {
			fill: white;
		}
	}
}

</style>