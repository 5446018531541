<script setup>
import { computed, watch, reactive, ref, defineEmits } from 'vue';
import * as XLSX from 'xlsx';
import { useLeadActions } from '@lead';
import { verifyField } from '@/Ship';
import { useUserActions } from '@user';
import { useCampaignActions } from '@campaign';
import { useCountryActions } from '@country';

const emit = defineEmits(['performed', 'close']);

const leadImport = useLeadActions('leadImport');
const getSales = useUserActions('getSales');

const selectedFile = ref(null);
const isDragOver = ref(false);
const fileInputRef = ref(null);

const getCampaignResource = useCampaignActions('getCampaignResource');
const campaigns = getCampaignResource();
if (!campaigns.isLoaded) {
    campaigns.load();
}

const getCountryResource = useCountryActions('getCountryResource');
const countries = getCountryResource();

const errors = reactive({
    campaign_id: null,
    country_code: null,
    password: null,
});

const payload = reactive({
    excelData: null,
    sale_id: null,
    country_code: null,
    campaign_id: null,
    password: 'T$rad7#er',
});

const campaign = computed({
    get: () => payload.campaign_id,
    set: value => {
        payload.campaign_id = value;
        errors.campaign_id = verifyField(value, ['require']);
        return true;
    }
});

const country = computed({
    get: () => payload.country_code,
    set: value => {
        payload.country_code = value;
        errors.country_code = verifyField(value, ['require']);
        return true;
    }
});

const password = computed({
    get: () => payload.password,
    set: value => {
        payload.password = value;
        errors.password = verifyField(value, ['require']);
        return true;
    }
});

const isAllow = ref(false);

watch([selectedFile, errors, payload], (v) => {
    isAllow.value = !!selectedFile.value;
    for (const key in errors) {
        if (errors[key] !== null || !payload[key]) {
            isAllow.value = false;
            break;
        }
    }
});

const sales = ref([]);
getSales().then(response => {
    sales.value = response.data;
});

const handleFileChange = () => {
    selectedFile.value = fileInputRef.value.files[0];
    const validExtensions = ['.xlsx', '.xls', '.csv'];
    const fileExtension = selectedFile.value.name.substring(
        selectedFile.value.name.lastIndexOf('.')
    ).toLowerCase();
    if (!validExtensions.includes(fileExtension)) {
        alert('Please select a valid .xlsx or .xls file.');
        selectedFile.value = null;
    }
};

const handleDragOver = (event) => {
    event.preventDefault();
    isDragOver.value = true;
};

const handleDragLeave = () => {
    isDragOver.value = false;
};

const handleDrop = (event) => {
    event.preventDefault();
    isDragOver.value = false;
    selectedFile.value = event.dataTransfer.files[0];
};

const isLoaded = ref(false);
const isLoading = ref(false);
//const result = reactive({});
const results = ref([]);
const uploadFile = () => {
    if (!selectedFile.value) {
        alert('Please select a file.');
        return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        payload.excelData = XLSX.utils.sheet_to_json(
            workbook.Sheets[workbook.SheetNames[0]]
        );

        isLoading.value = true;
        isLoaded.value = false;

        results.value = [];

        if (payload.excelData.length > 1000) {
            const chunkLength = 500;
            let index = 0;
            let chunk = payload.excelData.slice(index, index + chunkLength);
            const finallyAction = () => {
                if (index < payload.excelData.length) {
                    index += chunkLength;
                    chunk = payload.excelData.slice(index, index + chunkLength);
                    sendLeads({
                        campaign_id: payload.campaign_id,
                        country_code: payload.country_code,
                        password: payload.password,
                        sale_id: payload.sale_id,
                        excelData: chunk
                    }, finallyAction);
                } else {
                    isLoading.value = false;
                    isLoaded.value = true;
                }
            }
            sendLeads({
                campaign_id: payload.campaign_id,
                country_code: payload.country_code,
                password: payload.password,
                sale_id: payload.sale_id,
                excelData: chunk
            }, finallyAction);
        } else {
            sendLeads(payload, () => {
                isLoading.value = false;
                isLoaded.value = true;
            });
        }
    };

    function sendLeads(payload, finallyAction) {
        return leadImport(payload).then(response => {
            results.value.unshift(response)
            selectedFile.value = null;
        }).catch(error => {
            results.value.unshift({
                error: error.message
            });
        }).finally(finallyAction);
    }

    reader.readAsBinaryString(selectedFile.value);
};
</script>

<template>
    <form class="leads-import" v-on:submit.prevent="uploadFile">
        <g-flex justify="between" gap="10" wrap="wrap">
            <g-select v-model="campaign"
                      v-bind:options="campaigns.state"
                      option-text="name"
                      option-value="id"
                      v-bind:label="$t('lead.default_campaign', 'Default Campaign')"
                      v-bind::error="errors.campaign_id"/>

            <g-select v-model="country"
                      v-bind:options="countries.state"
                      option-text="name"
                      option-value="code"
                      v-bind:label="$t('lead.default_country', 'Default Country')"
                      v-bind::error="errors.country_code"/>

            <g-field v-model="payload.password"
                     v-bind:label="$t('lead.password', 'Password')"
                     v-bind::error="errors.password"/>

            <g-select v-model="payload.sale_id"
                      v-bind:options="sales"
                      option-text="name"
                      option-value="id"
                      v-bind:label="$t('lead.sale')"/>
        </g-flex>

        <label v-if="!isLoaded && !isLoading"
             class="dropzone"
             v-bind:class="{ 'dropzone-over': isDragOver }"
             v-on:dragover="handleDragOver"
             v-on:dragleave="handleDragLeave"
             v-on:drop="handleDrop">

            <input type="file" ref="fileInputRef" v-on:change="handleFileChange" hidden/>

            <template v-if="!selectedFile">
                <g-symbol class="icon" name="import" width="56" height="56" v-on:change="handleFileChange"/>
                [csv, xlsx, xls]
            </template>

            <g-caption v-else size="3" class="filename">{{ selectedFile.name }}</g-caption>
        </label>
        <div v-else class="results">
            <template v-if="results.length">
                <div v-for="result in results" class="result">
                    <div v-if="result.error" class="danger">
                        Error: {{ result.error }}
                    </div>
                    <template v-else>
                        <div>Total leads: {{ result?.total_leads || 0 }}</div>
                        <div>Added: <span class="success">{{ result?.leads_added || 0 }}</span></div>
                        <div>Duplicates: <span class="warning">{{ result?.duplicates || 0 }}</span></div>
                        <div v-for="(record, key) in result.log" v-bind:key="key" class="record">
                            <g-copy v-bind:text="key"/>
                            <div v-for="(warning, i) in record.warnings" v-bind:key="`warning-${key}- ${i}`" class="warning">
                                {{ warning }}
                            </div>
                            <div v-for="(error, i) in record.errors" v-bind:key="`error-${key}- ${i}`" class="danger">
                                {{ error }}
                            </div>
                        </div>
                    </template>
                </div>
            </template>
            <g-preloader v-if="isLoading"/>
        </div>

        <g-flex justify="end" gap="5">
            <g-button class="cancel-btn" v-on:click="$emit('close')" v-bind:disabled="isLoading">{{ $t('base.cancel', 'Cancel') }}</g-button>
            <g-button v-if="isLoaded" class="upload-btn save-btn" v-on:click="isLoaded = false">
                <g-symbol name="refresh" width="16" height="16"/>
                {{ $t('lead.new_import', 'New Import') }}
            </g-button>
            <g-button v-else class="upload-btn save-btn" type="submit" v-bind:disabled="!isAllow || isLoading">
                {{ $t('base.upload', 'Upload') }}
            </g-button>
        </g-flex>
    </form>
</template>

<style scoped lang="scss">
.leads-import {
    width: 520px;

    & > .results,
    & > .dropzone {
        position: relative;
        margin: 20px 0;
        padding: 20px;
        border-radius: 5px;
        width: 100%;
        height: 280px;
    }

    & > .dropzone {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2px dashed var(--main-text-color, $main-text-color);
        transition: border-color 0.3s;

        &-over {
            border-color: var(--primary-hover, $primary-hover);
        }
    }

    & > .results {
        overflow: auto;
        background-color: var(--field-active-bg, $field-active-bg);
        border: 1px solid var(--separator-color, $separator-color);

        & > .result {
            margin: 15px 0;
            padding: 15px 0;
            border-bottom: 1px solid var(--separator-color, $separator-color);
        }

        .record {
            margin-top: 20px;

            & > .danger,
            & > .warning {
                margin-top: 2px;
                font-family: $font-title;
                letter-spacing: 0.8px;
            }
        }
    }

    .icon {
        font-size: 20px;
        margin-right: 5px;
        fill: var(--main-text-color, $main-text-color);
    }

    .filename {
        max-width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .g-button {
        padding: 0 20px;
        min-width: 120px;
    }
}

.g-field,
.g-select {
    width: calc(50% - 5px);
}
</style>
