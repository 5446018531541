import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Offer extends BaseResource {

    static #instance = null;

    endpoint = 'offers';
    referer = 'Offer Resource';

    section = 'Campaigns';
    container = 'Offers';

    static getInstance() {
        if (!Offer.#instance) {
            Offer.#instance = new Offer();
        }
        return Offer.#instance;
    }
}
