export default [{
    name: 'Campaigns',
    path: `/:locale(${process.env.VUE_APP_AVAILABLE_LOCALES})?/campaigns`,
    component: () => import('./View.vue'),
    children: [
        {
            name: 'Campaign',
            path: ':id',
            component: () => import('./components/Campaign.vue'),
        }
    ],
}];
