<script setup>

import { defineProps, defineEmits } from 'vue';
import { useFinanceResources } from '@finance';
import { useProfileActions } from '@profile';
import { STATE, i18n, PopUpManager as PopUp } from '@/Ship';

const props = defineProps(['total']);
const emit = defineEmits(['update:total']);

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const deposits = useFinanceResources('Deposit');
deposits.setIncludes(['user', 'lead']);
deposits.sort('id', 'desc');
//deposits.setFilters(filters)

deposits.onload.push(() => {
    emit('update:total', {
        count: deposits.state.length,
        sum: Math.round(deposits.state.reduce((a, b) => a + Number(b.amount), 0))
    });
});

deposits.load(null, {}, 'Replenishments Component');

const columns = [
    { key: 'id', translationKey: 'base.id', defaultTitle: 'ID', sortable: true, justify: 'center' },
    { key: 'type', translationKey: 'base.type', defaultTitle: 'Type', sortable: true, justify: 'start' },
    { key: 'lead_id', translationKey: 'base.lead', defaultTitle: 'Lead', sortable: true, justify: 'start' },
    { key: 'amount', translationKey: 'base.amount', defaultTitle: 'Amount', sortable: true, justify: 'start' },
    { key: 'rate', translationKey: 'base.rate', defaultTitle: 'Rate', sortable: true, justify: 'start' },
    { key: 'currency_amount', translationKey: 'base.usdt_amount', defaultTitle: 'USDT Amount', sortable: true, justify: 'center' },
    { key: 'address', translationKey: 'base.address', defaultTitle: 'Address', sortable: true, justify: 'start' },
    { key: 'user_id', translationKey: 'base.recipient', defaultTitle: 'Recipient', sortable: true, justify: 'start' },
    { key: 'method', translationKey: 'base.method', defaultTitle: 'Method', sortable: true, justify: 'start' },
    { key: 'status', translationKey: 'base.status', defaultTitle: 'Status', sortable: true, justify: 'center' },
    { key: 'created_at', translationKey: 'base.created_at', defaultTitle: 'Date', sortable: true, justify: 'center' },
    { key: 'actions', translationKey: 'base.actions', defaultTitle: 'Actions', sortable: true, justify: 'start' },
];

const statuses = [
    { slug: 'new', name: 'New' },
    { slug: 'suspend', name: 'Suspend' },
    { slug: 'processed', name: 'Processed' },
    { slug: 'refund', name: 'Refund' }
];

STATE.IS_SHOW_SIDEBAR = false;

function changeStatus(index, value) {
    const previousValue = deposits.state[index].status;
    deposits.save(deposits.state[index].id, {
        status: value
    });
}

function removeDeposit(id) {
    PopUp.open(require('@finance/components/PopUps/RemoveDeposit'))
    .setCaption(i18n.global.t('finances.remove_replenishment', 'Remove replenishment'))
    .setPayload({ payload: { id } });
}

function sortBy(field) {
    if (deposits.sortBy === field) {
        profile.state.finances.sortDir = deposits.sortDir === 'asc'
            ? 'desc'
            : 'asc';
    }
    profile.state.finances.sortBy = field;
    profile.saveState();

    deposits.sort(
        profile.state.finances.sortBy,
        profile.state.finances.sortDir
    );
    deposits.load(null, {}, 'FinancesEnv Resort');
}

</script>

<template>
    <div class="deposits">
        <table>
            <thead>
            <tr>
                <td v-for="column in columns" v-bind:key="column.key" v-bind:class="[column.key, {sortable: column.sortable && deposits.sortBy === column.key}]">
                    <g-flex align="center" :justify="column.justify" gap="2" v-on:click="() => column.sortable && sortBy(column.key)">
                        {{ $t(column.translationKey, column.defaultTitle) }}
                        <g-symbol v-if="column.sortable" name="arrow-down" width="14" height="14" />
                    </g-flex>
                </td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(deposit, i) in deposits.state"
                v-bind:key="`deposit-${deposit.id}`"
                v-bind:class="deposit.type">

                <td class="id" v-bind:class="{sortable: deposits.sortBy === 'id'}">
                    <div class="bookmark" v-bind:class="{'no-forwarded': deposit.is_forwarded === 'No'}">{{ deposit.id }}</div>
                </td>

                <td class="type"  v-bind:class="{sortable: deposits.sortBy === 'type'}">
                    <template v-if="deposit.is_ftd === 'Yes'">
                        <span class="test">{{ deposit.is_test === 'Yes' ? 'Test' : '' }}</span>
                        {{ deposit.type === 'deposit' ? 'FTD' : $t(`finances.${deposit.type}`) }}
                    </template>
                    <template v-else>
                        <span class="test">{{ deposit.is_test === 'Yes' ? 'Test' : '' }}</span>
                        {{ deposit.type === 'deposit' ? $t('finances.replenishment') : $t(`finances.${deposit.type}`) }}
                    </template>
                </td>
                <td class="lead" v-bind:class="{sortable: deposits.sortBy === 'lead_id'}">
                    <div>ID: <span class="lead-id">{{ deposit.lead_id }}</span></div>
                    <router-link v-if="!!deposit.lead" class="lead-link"
                                 v-bind:to="{ name: 'Lead', params: {id: deposit.lead_id} }">
                        {{ deposit.lead.first_name }} {{ deposit.lead.last_name }}
                    </router-link>
                    <span v-else class="danger">Archived lead</span>
                </td>

                <td class="amount"  v-bind:class="{sortable: deposits.sortBy === 'amount'}">
                    <template v-if="deposit.rate !== 1">
                        {{ Number((deposit.amount / deposit.rate).toFixed(5)) }}
                        <small class="currency" v-bind:class="deposit.currency.toLowerCase()">{{ deposit.currency }}</small>
                    </template>
                </td>

                <td class="rate"  v-bind:class="{sortable: deposits.sortBy === 'rate'}">
                    {{ deposit.rate }}
                </td>

                <td class="usdt-amount" v-bind:class="{sortable: deposits.sortBy === 'currency_amount'}">
                    {{ Number(deposit.amount).toFixed(2) }}
                </td>

                <td class="address"  v-bind:class="{sortable: deposits.sortBy === 'address'}">
                    <g-copy v-if="deposit.address" class="value" v-bind:text="deposit.address"></g-copy>
                </td>

                <td class="recipient" v-bind:class="{sortable: deposits.sortBy === 'user_id'}">
                    {{ deposit.user?.name || '' }}
                </td>

                <td class="method" v-bind:class="{sortable: deposits.sortBy === 'method'}">
                    <span v-bind:class="deposit.method.toLowerCase()">{{ deposit.method }}</span>
                    <template v-if="deposit.sub_method"> / {{ deposit.sub_method }}</template>
                </td>

                <td class="status" v-bind:class="[deposit.status, {sortable: deposits.sortBy === 'status'}]">
                    <g-select v-model="deposit.status"
                              option-text="name"
                              option-value="slug"
                              v-bind:options="statuses"
                              v-on:select="changeStatus(i, $event)"
                              v-bind::disabled="deposit.status === 'refund'"
                              v-bind:readonly="profile.cant('Deposit Edit')"/>
                </td>

                <td class="date" v-bind:class="{sortable: deposits.sortBy === 'created_at'}">
                    {{ deposit.created_at }}
                </td>

                <td class="actions">
                    <g-button class="actions remove-btn with-tooltip"
                              v-bind:data-tooltip="$t('finances.remove_replenishment', 'Remove replenishment')"
                              v-on:click="removeDeposit(deposit.id)"
                              v-bind:disabled="profile.cant('Deposit Edit')">
                        <g-symbol name="delete" width="18" height="18"/>
                    </g-button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped lang="scss">
.deposits {
    padding-left: 8px;
    width: 100%;
    height: calc(100% - 105px);
    overflow: auto;
    white-space: nowrap;

    table {
        width: 100%;
    }

    thead {
        color: var(--disabled-color, $disabled-color);

        & > tr {
            top: 0;
            z-index: 2;
            position: sticky;
            background-color: var(--env-bg, $env-bg);
            box-shadow: 0 2px 25px -8px #00000077;

            & > td {
                padding: 8px 8px;

                .g-flex {
                    cursor: pointer;
                    fill: var(--main-text-color, $main-text-color);
                }

                &.sortable > .g-flex > .g-symbol {
                    fill: black;
                    transform: rotate(180deg);
                }
            }
        }
    }

    tbody > tr {
        font-size: 0.9rem;

        &:nth-child(even) {
            background-color: #00000022;
        }

        &.bonus {
            & > .type {
                color: var(--success-hover, $success-hover);
            }
        }

        &.credit {
            & > .type {
                color: var(--warning, $warning);
            }
        }

        &.fake {
            & > .type {
                color: var(--danger-hover, $danger-hover);
            }
        }
    }

    td {
        padding: 0 8px;

        &.id {
            min-width: 55px;
            padding: 0 6px;
            text-align: center;
            position: relative;
            overflow: visible;

            & > .bookmark {
                top: 50%;
                left: -8px;
                transform: translateY(-50%);
                position: absolute;
                min-width: 60px;
                height: 40px;
                line-height: 40px;
                padding: 0 10px 0 5px;
                overflow: hidden;

                &.no-forwarded {
                    color: white;
                    border-radius: 5px;
                    background-color: #7a3550;
                    clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%);
                }
            }
        }

        &.type {
            text-transform: capitalize;
        }

        &.rate {
            text-align: center;
        }

        &.usdt-amount {
            text-align: center;
            color: var(--title-text-color, $title-text-color);
        }

        &.address {
            max-width: 200px;
        }

        &.method {
            & > span {
                font-weight: bold;
                color: var(--title-text-color, $title-text-color);
            }
        }

        &.status {
            min-width: 140px;

            &.new > .g-select:deep(fieldset) {
                border-color: var(--primary-hover, $primary-hover);

                & > legend {
                    color: var(--primary-hover, $primary-hover);
                }
            }

            &.suspend > .g-select:deep(fieldset) {
                border-color: var(--warning-hover, $warning-hover);

                & > legend {
                    color: var(--warning-hover, $warning-hover);
                }
            }

            &.processed > .g-select:deep(fieldset) {
                border-color: var(--success-hover, $success-hover);

                & > legend {
                    color: var(--success-hover, $success-hover);
                }
            }

            &.refund > .g-select:deep(fieldset) {
                border-color: var(--separator-color, $separator-color);

                & > legend {
                    color: var(--disabled-color, $disabled-color);
                }
            }
        }

        &.actions {
            width: 30px;
            text-align: center;

            & > button {
                position: relative;
                margin: 0 4px;
                cursor: pointer;
                background-color: transparent;
                fill: var(--main-text-color, $main-text-color);

                &:hover {
                    fill: var(--title-text-color, $title-text-color);

                    &::after {
                        transform: translateX(-50%);
                        top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
                        background-color: var(--danger, $danger);
                        left: 50%;
                    }

                    &::before {
                        transform: translateX(-50%);
                        left: 50%;
                        top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
                        border-bottom-color: var(--danger, $danger);
                    }
                }
            }
        }

        &.date {
            width: 140px;
        }
    }
}

.sortable {
    color: black !important;
    background-color: var(--warning, $warning);
}

.test {
    color: var(--disabled-color, $disabled-color);
}

.lead-id {
    color: var(--title-text-color, $title-text-color);
}

.lead-link {
    color: inherit;
    text-decoration: underline;

    &:hover {
        color: var(--title-text-color, $title-text-color);
    }
}

.currency {
    font-size: 0.8rem;
}

.btc {
    color: $btc;
    font-weight: 700;
}

.usdt {
    color: $usdt;
    font-weight: 700;
}

.eur {
    color: $eur;
    font-weight: 700;
}

.eth {
    color: $eth;
    font-weight: 700;
}

.danger {
    color: var(--danger, $danger);
}
</style>