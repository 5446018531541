import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Customer extends BaseResource {

    static #instance = null;

    endpoint = 'customers';

    section = 'Customers';
    container = 'Customers';

    columns = [
        //{
        //    key: 'phone',
        //    name: 'phone',
        //    sortable: false,
        //},
        {
            key: 'email',
            name: 'email',
            sortable: false,
        },
        {
            key: 'name',
            name: 'name',
            sortable: false,
        },{
            key: 'retention_id',
            name: 'retention',
            sortable: true,
        },{
            key: 'is_ftd',
            name: 'is_ftd',
            sortable: true,
        },{
            key: 'balance',
            name: 'balance',
            sortable: true,
        },{
            key: 'account_level',
            name: 'account_level',
            sortable: true,
        },{
            key: 'country',
            name: 'country',
            sortable: false,
        },{
            key: 'language',
            name: 'language',
            sortable: false,
        },{
            key: 'age',
            name: 'age',
            sortable: false,
        },{
            key: 'created_at',
            name: 'created_at',
            sortable: true,
        },
    ];

    static getInstance() {
        if (!Customer.#instance) {
            Customer.#instance = new Customer();
        }
        return Customer.#instance;
    }
}
