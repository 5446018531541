import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Comment extends BaseResource {

    static #instance = null;

    endpoint = 'comments';
    referer = 'Comment Resource';

    section = 'Leads';
    container = 'Comments';

    static getInstance() {
        if (!Comment.#instance) {
            Comment.#instance = new Comment();
        }
        return Comment.#instance;
    }
}
