<script setup>
import { ref, onBeforeUnmount, onMounted } from 'vue';
import { useLeadActions, useLeadResources } from '@lead';
import { useProfileActions } from '@profile';

const filters = useLeadResources('FilterState');

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getLeadResource = useLeadActions('getLeadResource');
const leads = getLeadResource();


const isFilterMenuOpen = ref(false);

const type = ref('created_at');
const from = ref();
const to = ref();

onMounted(() => {
    window.addEventListener('click', handleGlobalClick);

    [ from.value, to.value ] = (filters.state.created_at && filters.created_at.length)
        ? filters.state.created_at[0].split('-')
        : [null, null];
});

onBeforeUnmount(() => {
    window.removeEventListener('click', handleGlobalClick);
});

const toggleCalendarMenu = () => {
    isFilterMenuOpen.value = !isFilterMenuOpen.value;
};

const handleGlobalClick = (event) => {
    if (isFilterMenuOpen.value) {
        const menuElement = document.querySelector('.calendar-menu');
        const calendarButton = document.querySelector('.filter-btn');

        if (!calendarButton.contains(event.target) && !menuElement.contains(event.target)) {
            closeCalendarMenu();
        }
    }
};

const closeCalendarMenu = () => {
    isFilterMenuOpen.value = false;
};

const filtering = () => {
    if (from.value && to.value) {
        filters.state[type.value] = {[`${from.value}-${to.value}`]: `${from.value}-${to.value}`};
    } else {
        delete filters.state[type.value];
    }

    leads.setFilters(filters.state);
    leads.setPage(1);
    leads.load();

    profile.state.lead.filters = filters.state;
    profile.saveState();

    closeCalendarMenu();
};


const reset = () => {
    from.value = null;
    to.value = null;
    isFilterMenuOpen.value = false;

    filtering();

    profile.state.lead.filters[type.value] = [];
    profile.saveState();
};

const setDateRange = () => {
    if (from.value && to.value) {
        from.value = formatDateToISO(from.value);
        to.value = formatDateToISO(to.value);
    }
};

const formatDateToISO = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}.${month}.${day}`;
};

const formatDateInput = (field, {target}) => {

    let value = target.value.replace(/\D/g, '');

    if (value.length > 4) {
        value = value.replace(/^(\d{4})/, '$1.');

        if (value.length > 7) {
            value = value.replace(/^(.{7})/, '$1.');

            if (value.length > 10) {
                value = value.slice(0, 10);
            }
        }
    }

    target.value = value;
};
</script>

<template>
    <div class="lead-filter">
        <g-button class="filter-btn" v-on:click="toggleCalendarMenu" v-bind:class="{'active': isFilterMenuOpen}">
            <g-symbol name="calendar" width="18" height="18"/>
        </g-button>
        <div class="calendar-menu" v-if="isFilterMenuOpen" @click.stop="">
            <g-flex class="menu-header" justify="end" align="center">
                <!--<g-caption size="4" weight="600">Filters</g-caption>-->
                <g-symbol name="close"
                          class="menu-close"
                          height="24"
                          width="24"
                          v-on:click="isFilterMenuOpen = false"/>
            </g-flex>
            <g-select v-model="type"
                      class="filter"
                      label="Lead filters"
                      v-bind:options="[{v: 'created_at', n: $t('filters.created_at', 'Created at')}, {v: 'entry_at', n: $t('filters.entry_at', 'Entry at')}, {v: 'ftd_date', n: $t('filters.ftd_date', 'FTD Date')}]"
                      text-transform="capitalize"
                      option-text="n"
                      option-value="v"/>
            <g-flex class="action-block">
                <g-input v-model="from"
                         v-bind:reset-btn="false"
                         placeholder="YYYY-MM-DD"
                         v-on:input="formatDateInput('from', $event)"
                         v-bind:disabled="!type"/>

                <g-input v-model="to"
                         v-bind:reset-btn="false"
                         placeholder="YYYY-MM-DD"
                         v-on:input="formatDateInput('to', $event)"
                         v-bind:disabled="!type"/>

            </g-flex>
            <div class="date-block" :class="{'date-block-active': !!type}">
                <g-date-picker v-model:from="from"
                               v-model:to="to"
                               v-on:change="setDateRange"
                               v-bind:disabled="!type"/>
            </div>
            <g-flex class="action-block" justify="end" gap="5">
                <g-button class="reset-btn"
                          type="reset"
                          v-on:click="reset"
                          :disabled="!from || !to">
                    {{ $t('base.reset', 'Reset') }}
                </g-button>
                <g-button class="apply-btn"
                          v-on:click="filtering"
                          :disabled="!from || !to">
                    {{ $t('base.apply', 'Apply') }}
                </g-button>
            </g-flex>
        </div>
    </div>
</template>

<style scoped lang="scss">
.lead-filter {
    position: relative;
}

.filter-btn {
    width: 40px;
    height: 40px;
    color: white;
    font-size: 0;
    margin: 0 2px 0 10px;
    fill: var(--main-text-color, $main-text-color);
    border: 1px solid var(--input-border, $input-border);
    position: relative;
    cursor: pointer;
    background-color: var(--env-bg, $env-bg);

    &:hover,
    &.active {
        fill: var(--btn-primary-color, $btn-primary-color);
        border-color: var(--primary, $primary);
        background-color: var(--primary, $primary);

        &::after,
        &::before {
            background-color: var(--primary, $primary);
        }
    }
}

.calendar-menu {
    position: absolute;
    background-color: var(--env-bg, $env-bg);
    top: 50px;
    left: 0;
    box-shadow: 0 2px 4px var(--datepicker-shadow, $datepicker-shadow);
    padding: 20px;
    border-radius: var(--dropdown-rounding, $dropdown-rounding);
    border: 1px solid var(--success, $success);
}

.g-input {
    width: 50%;
    margin: 10px 0;

    &:last-of-type {
        margin-left: 10px;
    }
}

.action-block {
    padding: 10px;

    .g-button {
        padding: 0 20px;
        background-color: var(--btn-bg, $btn-bg);
        fill: var(--btn-primary-color, $btn-primary-color);
        color: var(--btn-primary-color, $btn-primary-color);
        border: 1px solid var(--separator-color, $separator-color);

        &.apply-btn {
            &:hover {
                fill: var(--btn-primary-color, $btn-primary-color);
                color: var(--btn-primary-color, $btn-primary-color);
                border: 1px solid var(--success, $success);
                background-color: var(--success, $success);
            }
        }

        &.reset-btn {
            &:hover {
                background-color: var(--primary, $primary);
                border: 1px solid var(--primary, $primary);
            }
        }
    }
}

.date-block {
    max-height: 0;
    transition: max-height 0.5s ease-out;
    overflow: hidden;

    &-active {
        max-height: 500px;
    }
}

.menu-close {
    cursor: pointer;
    fill: var(--main-text-color, $main-text-color);

    &:hover {
        fill: var(--title-color, $title-color);
    }
}
</style>