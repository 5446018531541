<script setup>

import { computed } from 'vue';
import { useArchiveActions } from '@archive';
import { useProfileActions } from '@profile';

import filters from '@archive/resources/FilterState';

const getArchiveResource = useArchiveActions('getArchiveResource');
const archive = getArchiveResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

if (!profile.state.archive) {
    profile.state.archive = {
        columns: [],
        filters: {},
        sortBy: 'id',
        sortDir: 'desc',
        perPage: 25,
    };
}


Object.assign(filters, profile.state.archive.filters);


archive.setFilters(filters);

function filtrate() {
    archive.setFilters(filters);
    archive.setPage(1);
    archive.load();

    profile.state.archive.filters = filters;
    profile.saveState();
}
</script>

<template>
    <!-- CONVERSION STATUS -->
    <g-filterset v-model="filters.conversion_status"
                 v-bind:label="$t('filters.conversion_status', 'Conversion status')"
                 v-bind:filterset="archive.filterset.conversion_status"
                 v-on:change="filtrate"/>

    <!-- RETENTION STATUS -->
    <g-filterset v-model="filters.retention_status"
                 v-bind:label="$t('filters.retention_status', 'Retention status')"
                 v-bind:filterset="archive.filterset.retention_status"
                 v-on:change="filtrate"/>

    <!-- TEAM -->
    <g-filterset v-model="filters.teams"
                 v-bind:label="$t('filters.teams', 'Teams')"
                 v-bind:filterset="archive.filterset.teams"
                 value-field="id"
                 key-field="value"
                 v-on:change="filtrate"/>

    <!-- AFFILIATE -->
    <g-filterset v-model="filters.affiliates"
                 v-bind:label="$t('filters.affiliates', 'Affiliates')"
                 v-bind:filterset="archive.filterset.affiliates"
                 value-field="id"
                 v-on:change="filtrate"/>

    <!-- SALE -->
    <g-filterset v-model="filters.sales"
                 v-bind:label="$t('filters.sales', 'Sale agents')"
                 value-field="id"
                 v-bind:filterset="archive.filterset.sales"
                 v-on:change="filtrate"/>

    <!-- RETENTION -->
    <g-filterset v-model="filters.retentions"
                 v-bind:label="$t('filters.retentions', 'Retention agents')"
                 value-field="id"
                 v-bind:filterset="archive.filterset.retentions"
                 v-on:change="filtrate"/>

    <!-- LANGUAGE -->
    <g-filterset v-model="filters.language"
                 v-bind:label="$t('filters.languages', 'Languages')"
                 v-bind:filterset="archive.filterset.language"
                 v-on:change="filtrate"/>

    <!-- COUNTRY -->
    <g-filterset v-model="filters.countries"
                 v-bind:label="$t('filters.countries', 'Countries')"
                 value-field="code"
                 v-bind:filterset="archive.filterset.countries"
                 v-on:change="filtrate"/>

    <!-- CAMPAIGN -->
    <g-filterset v-model="filters.campaigns"
                 v-bind:label="$t('filters.campaigns', 'Campaigns')"
                 value-field="id"
                 v-bind:key-field="profile.can('Lead RealCampaignView') ? 'name' : 'alias'"
                 v-bind:filterset="archive.filterset.campaigns"
                 v-on:change="filtrate"/>
</template>

<style lang="scss" scoped>

</style>