import { useSettingsResources } from '@settings';
import {ResourcesManager} from "@/Ship";

export default (section = null, reload = false) => {
	const settings = useSettingsResources('Settings');

	if (section) {
		if (reload || (!settings.isLoaded && !settings.isLoading)) {
			settings.load(section);
		}
	} else if (reload || (!settings.isLoaded && !settings.isLoading)) {
		settings.load();
	}

	ResourcesManager.addResource(settings, 'Settings');

	return settings;
};
