<script setup>

import { watch } from 'vue';
import {STATE, PopUpManager as PopUp, i18n} from '@/Ship';

watch(() => STATE.AUTO_SAVE_INDICATOR, () => {
    if (STATE.AUTO_SAVE_INDICATOR === 2) {
        PopUp.open(require('@/Ship/components/PopUps/Confirm'))
            .setCaption(i18n.global.t('message.saving_data', 'Saving data'))
            .setMessage(`
            <div class="message">
                <h3>${i18n.global.t('message.data_saved', 'Data has been saved')}</h3>
            </div>
            `).setPayload({payload: {
                    icon: 'check',
                    fill: 'success',
                    text: 'ok',
                    showCancelButton: false
            }}).setAction(() => {
                PopUp.close();
            });
    } else if (STATE.AUTO_SAVE_INDICATOR === 4) {
        PopUp.open(require('@/Ship/components/PopUps/Confirm'))
            .setCaption(i18n.global.t('message.saving_data', 'Saving data'))
            .setMessage(`
            <div class="message">
                <h3 class="danger">${i18n.global.t('message.data_not_saved', 'Data was not saved')}!</h3>
            </div>
            `).setPayload({payload: {
                icon: 'check',
                fill: 'danger',
                text: 'ok',
                showCancelButton: false
            }}).setAction(() => {
            PopUp.close();
        });
    }
});

</script>

<template>
    <div class="save-indicator" v-bind:class="['empty', 'filled', 'save', 'saved', 'fail'][STATE.AUTO_SAVE_INDICATOR]">
        <g-symbol v-if="STATE.AUTO_SAVE_INDICATOR" name="info" width="18" height="18"/>
        <g-symbol v-else name="save" width="20" height="20"/>
    </div>
</template>

<style scoped lang="scss">
.save-indicator {
    width: 40px;
    height: 40px;
    font-size: 0;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    display: inline-flex;

    &.empty {
        transition: all 3s;
        background-color: transparent;
        fill: var(--fade-container-btn-color, $fade-container-btn-color);
    }

    &.filled {
        fill: var(--warning, $warning);
    }

    &.fail,
    &.save {
        fill: white;
        background-color: var(--danger, $danger);
    }

    &.saved {
        fill: white;
        background-color: var(--success, $success);
    }
}
</style>