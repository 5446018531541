<template>
    <div class="export-xls">
        <button class="tool with-tooltip"
                :data-tooltip="$t('finances.Export to XLSX', 'Export to XLSX')"
                @click="onExportClick">
            <g-symbol name="export"
                      width="22"
                      height="22"/>
        </button>
    </div>
</template>

<script>
import { ref } from 'vue';
import * as XLSX from 'xlsx';

export default {
    emits: ['export-click'],
    props: {
        data: {
            type: Array,
            required: true
        },
        name: {
            type: String,
            required: true
        }
    },
    setup(props, { emit }) {
        const onExportClick = () => {
            emit('export-click', null);
            exportToXLSX(props.data, props.name);
        };

        const exportToXLSX = (data, name) => {
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(workbook, worksheet, name);
            XLSX.writeFile(workbook, `exported_${name}.xlsx`);
        };

        return {
            onExportClick
        };
    }
};
</script>

<style scoped lang="scss">
.tool {
    font-size: 0;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border-radius: var(--btn-rounding, $btn-rounding);
    width: 40px;
    height: 40px;
    color: white;
    margin: 0 2px 0 10px;
    fill: var(--main-text-color, #aabfcf);
    border: 1px solid var(--input-border, #4f5a69);

    &:hover {
        fill: var(--title-color, $title-color);

        &::after {
            white-space: nowrap;
            transform: translateX(-50%);
            top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
            left: 50%;
        }

        &::before {
            transform: translateX(-50%);
            left: 50%;
            top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
            border-bottom-color: var(--tooltip-bg, $tooltip-bg);
        }
    }
}
</style>