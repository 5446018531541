<script setup>

import { computed, ref, defineProps, defineEmits, onUpdated } from 'vue';

const emit = defineEmits(['update:modelValue', 'change', 'update:collapse']);
const props = defineProps({
    modelValue: {
        type: Array,
    },
    label: {
        type: String,
        require: true,
    },
    filterset: {
        type: Array,
        require: true,
    },
    keyField: {
        type: String,
        default: 'key',
    },
    valueField: {
        type: String,
        default: 'value',
    },
    withCount: {
        type: Boolean,
        default: true,
    },
    expanded: {
        type: Boolean,
        default: false
    },
    isSearch: {
        type: Boolean,
        default: false
    },
    isSelectAll: {
        type: Boolean,
        default: false
    }
});

//const modelValue = toRefs(props.modelValue);

//const filters = computed(() => {
//    return (typeof modelValue === "object")
//        ? Object.values(modelValue)
//        : modelValue || [];
//});

const filters = computed({
    get: () => (typeof props.modelValue === "object")
        ? Object.values(props.modelValue || {})
        : props.modelValue || [],
    set: value => {
        const values = {};
        value.forEach(value => {
            const item = props.filterset.find(item => item[props.valueField] == value);
            values[item[props.keyField]] = value;
        });
        emit('update:modelValue', values);
        emit('change');

        return true;
    }
});

const searchString = ref(null);

const selectAll = computed({
    get: () => (filters.value || []).length === Object.keys(fset.value).length,
    set: (isChecked) => {
        filters.value = isChecked
            ? Object.values(fset.value).map(filter => filter.value.join())
            : [];

        return true;
    },
});

const fset = computed(() => {

    const filters = searchString.value
        ? (props.filterset || []).filter(filter => {
            return filter[props.keyField].toLowerCase().includes(searchString.value.toLowerCase())
        })
        : props.filterset || [];

    const filterset = {};
    filters.forEach(item => {
        if (!!filterset[item[props.keyField]]) {
            filterset[item[props.keyField]].count += (item.count || 0);
            filterset[item[props.keyField]].value.push(item[props.valueField]);
        } else {
            filterset[item[props.keyField] || 'Undetermined'] = {
                value: [item[props.valueField]],
                count: item.count || 0
            };
        }
    });

    return filterset;
});

</script>

<template>
    <fieldset class="g-filterset" v-bind:class="{'expand': expanded}">
        <legend>
            <span v-if="isSelectAll" class="select-all with-tooltip" type="button" v-bind:data-tooltip="$t('lead.select_all', 'Select all')">
                <!--<g-symbol name="check" width="20px" height="20px"/>-->
                <g-checkbox v-model:checked="selectAll"/>
            </span>
            {{ label }}
            <!--<g-symbol name="arrow-down" width="18" height="18" v-on:click="onCollapsed(expanded = !expanded)"/>-->
        </legend>

        <div class="container custom-scrollbar">
            <div v-if="isSearch" class="search-input">
                <g-input v-model="searchString">
                    <g-symbol name="search" width="20" height="20"/>
                </g-input>
            </div>

            <g-checkbox v-for="(filter, key) in fset"
                        v-bind:key="`${key}`"
                        v-model="filters"
                        v-bind:value="filter.value.join()">
                <span class="value" v-bind:title="key">{{ key }}</span>
                <span class="count" v-if="withCount">({{ filter.count }})</span>
            </g-checkbox>
        </div>
    </fieldset>
</template>

<style lang="scss" scoped>
.g-filterset {
    border-radius: var(--field-rounding, $field-rounding);
    border: 1px solid var(--separator-color, $separator-color);

    & > legend {
        margin-left: 10px;
        font-size: 16px;
        font-weight: bold;
        display: inline-flex;
        align-items: center;

        & > .g-symbol {
            cursor: pointer;
            fill: var(--main-text-color, $main-text-color);
        }

        & > .with-tooltip:hover {
            position: relative;
            z-index: 3;

            &::after {
                white-space: nowrap;
                transform: translateX(-50%);
                top: calc(99% + var(--tooltip-arrow-size, $tooltip-arrow-size));
                left: 50%;
            }

            &::before {
                transform: translateX(-50%);
                left: 50%;
                top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
                border-bottom-color: var(--tooltip-bg, $tooltip-bg);
            }
        }

        .g-checkbox {
            padding: 0;
        }
    }

    & > .container {
        overflow: auto;
        transition: max-height 0.3s;
    }

    &:not(.expand) {
        & > .container {
            max-height: 0;
        }
    }

    &.expand {
        & > legend > .g-symbol {
            transform: rotate(180deg);
        }

        & > .container {
            height: 100%;
            max-height: 360px;
        }
    }

    .g-checkbox {
        display: block;
        margin: 8px 0;
        white-space: nowrap;
        padding: 0 12px 0 10px;

        &:deep(.label) {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .value {
            flex-grow: 1;
            text-transform: capitalize;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-break: break-word;
            max-width: 180px;
            overflow: hidden;
        }

        .count {
            padding-left: 4px;
            color: var(--disabled-color, $disabled-color);
        }
    }
}

.search-input {
    top: 0;
    z-index: 2;
    position: sticky;
    padding: 0 10px 0 8px;
}
</style>