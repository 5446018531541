
export default function phoneMask(value, code) {

    value = value.replace(/^\+\d+\s/, '');

    const matrix = `(___) ___-____`;
    const def = matrix.replace(/\D/g, '');
    let i = 0;
    let val = value.replace(/\D/g, '');
    let newValue = matrix.replace(/[_\d]/g, a => {
        return i < val.length
            ? val.charAt(i++) || def.charAt(i)
            : a;
    });

    i = newValue.indexOf('_');
    if (i !== -1) {
        //i < 5 && (i = i);
        newValue = newValue.slice(0, i);
    }

    let reg = matrix.substr(0, value.length).replace(/_+/g, function (a) {
        return '\\d{1,' + a.length + '}';
    }).replace(/[+()]/g, '\\$&');

    if (!(new RegExp('^' + reg + '$')).test(value) || value.length < 5) {
        value = newValue;
    }

    return `${code} ${value}`;
}
