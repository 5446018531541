<template>
    <pairs/>
</template>
<script>
import { useAssetComponents } from '@asset';
import { onMounted } from 'vue';
import { preloaderAligner } from '@/Ship/components/global/preloaders/scrollHellper';

export default {
    name: 'Assets',
    components: {
        Pairs: useAssetComponents('Pairs')
    },
};

</script>
<style scoped lang="scss">
h1 {
    text-align: center;
}
</style>