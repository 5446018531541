import { useAssetResources } from '@asset';

export default () => {

    const statuses = useAssetResources('Statuses');
    if (!statuses.isLoaded && !statuses.isLoading) {
        //statuses.setIncludes([]);
        statuses.load();
        // statuses.getInstance()
    }

    return statuses;
};
