<template>
    <currency-sprite/>
    <router-view/>
    <g-preloader-overlay v-if="STATE.IS_LOADING"/>
    <g-popup v-if="PopUp.component.value">
        <component v-bind:is="PopUp.component.value" v-bind="PopUp.payload" v-on:performed="PopUp.action" v-on:close="PopUp.close()"/>
    </g-popup>
</template>

<script>

import { watch } from 'vue';
import { useRouter } from 'vue-router'
import {STATE, i18n, PopUpManager as PopUp } from '@/Ship';
import CurrencySprite from '@/Ship/components/currency-sprite';
import { useAuthActions } from '@auth';
import inactivityTimer from '@/Ship/actions/inactivityTimer';

export default {
    name: 'CRM',
    head: {
        link: [
            {
                rel: 'icon',
                href: require('@/Ship/assets/img/logo.svg')
            },
        ]
    },

    components: {
        CurrencySprite,
    },

    setup() {

        const router = useRouter();
        const signOut = useAuthActions('signOut');

        watch(() => STATE.IS_LOGIN, () => {
            if (!STATE.IS_LOGIN) {
                router.push({ name: 'SignIn', params: router.currentRoute.value.params });
            }
        }, { deep: true });

        const INACTIVITY_TIMEOUT = Number(process.env.VUE_APP_INACTIVITY_TIMEOUT) || 1000 * 60 * 60;

        const { addListeners, resetTimer, clearTimer } = inactivityTimer(signOut, INACTIVITY_TIMEOUT);

        const channel = new BroadcastChannel('TAB_ACTIVITY');

        addListeners();

        window.addEventListener("beforeunload", (event) => {

            let isLock = false;
            Object.keys(STATE.UNSAVED_DATA).forEach(key => {
                if (STATE.UNSAVED_DATA[key].length > 0) {
                    isLock = true;
                }
            });
            if (isLock) {
                PopUp.open(require('@/Ship/components/PopUps/UnSavedAlert')).setCaption(i18n.global.t('message.saving_data', 'Saving data'));
                event.preventDefault();
                event.returnValue = "";
            }

            channel.postMessage({
                name: 'INACTIVITY_MESSAGE',
                payload: 'START_TIMER',
            });
        });

        window.addEventListener('visibilitychange', () => {
            if (!document.hidden) {
                channel.postMessage({
                    name: 'INACTIVITY_MESSAGE',
                    payload: 'STOP_TIMER',
                });
                resetTimer();
                addListeners();
            }
        });

        channel.addEventListener('message', (event) => {
            if (document.hidden && event.data.name === 'INACTIVITY_MESSAGE') {
                event.data.payload === 'START_TIMER' ? resetTimer() : clearTimer()
            }
        });

        return {
            PopUp,
            STATE
        };
    },
};
</script>
<style lang="scss">
@import './assets/index';

.hide-sprite {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
}
</style>
