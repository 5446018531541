import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Archive extends BaseResource {

    static #instance = null;

    endpoint = 'archives';
    referer = 'Archive Resource';

    section = 'Leads';
    container = 'Archive';

    static getInstance() {
        if (!Archive.#instance) {
            Archive.#instance = new Archive();
        }
        return Archive.#instance;
    }

    columns = [
        {
            key: 'name',
            name: 'name',
            sortable: false,
        },
        {
            key: 'email',
            name: 'email',
            sortable: false,
        },
        {
            key: 'account_level',
            name: 'account_level',
            sortable: true,
        },
        {
            key: 'language',
            name: 'language',
            sortable: true,
        },
        {
            key: 'country_code',
            name: 'country',
            sortable: true,
        },
        {
            key: 'timezone',
            name: 'timezone',
            sortable: true,
        },
        {
            key: 'created_at',
            name: 'created_at',
            sortable: true,
        },
        {
            key: 'deleted_at',
            name: 'deleted_at',
            sortable: true,
        },
    ];
}

