import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Pair extends BaseResource {

    static #instance = null;

    endpoint = 'pairs';
    referer = 'Pair Resource';

    section = 'Settings';
    container = 'Assets';

    static getInstance() {
        if (!Pair.#instance) {
            Pair.#instance = new Pair();
        }
        return Pair.#instance;
    }
}
