
import { reactive } from 'vue';

export default class FilerState {

    static #instance = null;

    section = 'Leads';
    container = 'Leads';

    state = reactive({});

    constructor() {
        FilerState.#instance = new Proxy(this.state, {
            get: (target, key) => {
                return key in target
                    ? target[key]
                    : this[key] || undefined;
            },
            set: (target, key, value) => {
                target[key] = value;
                return true;
            }
        });

        return FilerState.#instance;
    }

    static getInstance() {
        if (!FilerState.#instance) {
            FilerState.#instance = new FilerState();
        }

        return FilerState.#instance;
    }
}
