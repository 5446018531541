<script setup>

import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { STATE, PopUpManager as PopUp } from '@/Ship';
import { useAuthActions } from '@auth';
import { useProfileActions } from '@profile';

const router = useRouter();

const { cancelImpersonation, getProfileResource } = useProfileActions();
const profile = getProfileResource();

const signOut = useAuthActions('signOut');

const isImpersonated = computed(() => localStorage.getItem('impersonate'));
const isAdmin = computed(() => profile.roles.map(role => role.name).includes('Admin'));

const logout = () => {
    backToAccount();
    signOut();
    setTimeout(() => router.push({ name: 'SignIn' }), 0);
};

const onChangeTheme = (checked) => {
    const activeTheme = checked ? 'light' : 'dark';
    profile.state.env.is_light_mode = activeTheme;
    STATE.IS_LIGHT_MODE = activeTheme === 'light';
    profile.saveState();
    localStorage.setItem('theme', activeTheme);
    document.documentElement.setAttribute('theme', activeTheme);
};

const backToAccount = () => {
    const id = localStorage.getItem('impersonate');
    if (id) {
        cancelImpersonation(id);
    }
};

onMounted(() => {
    const activeTheme = profile.state?.env?.is_light_mode || 'dark';
    document.documentElement.setAttribute('theme', activeTheme);
    STATE.IS_LIGHT_MODE = profile.state?.env?.is_light_mode === 'light'
});
</script>

<template>
    <g-fade-container class="profile-menu"
                      :class="{'impersonated': isImpersonated}"
                      icon="profile"
                      v-bind:label="profile.name">
        <div class="account-menu">
            <router-link :to="{ name: 'Profile', params: { locale: $i18n.locale } }"
                         class="menu-item"
                         :class="{ active: $route.name === 'Profile' }">
                <g-symbol name="profile" width="24" height="24"/>
                {{ $t('auth.profile', 'Profile') }}
            </router-link>
            <label class="menu-item" v-on:click="logout">
                <g-symbol name="logout" width="24" height="24"/>
                {{ $t('auth.logout') }}
            </label>
            <label class="menu-item"
                   v-if="isImpersonated"
                   v-on:click="backToAccount">
                <g-symbol name="incognito" width="24" height="24"/>
                {{ $t('auth.cancel_impersonation', 'Cancel Impersonation') }}
            </label>
            <label class="menu-item"
                   v-else
                   v-show="isAdmin"
                   v-on:click="PopUp.open(require('@profile/components/PopUps/Impersonatation.vue')).setCaption('Impersonate')">
                <g-symbol name="incognito" width="24" height="24"/>
                {{ $t('auth.impersonation') }}
            </label>
            <hr class="separator"/>
            <label class="menu-item theme-switcher">
                {{ $t('base.light_mode') }}
                <g-switch v-on:update:checked="onChangeTheme($event)" v-bind:checked="STATE.IS_LIGHT_MODE"/>
            </label>
        </div>
    </g-fade-container>
</template>

<style lang="scss" scoped>
.account-menu {
    padding: 10px;
    background-color: var(--bar-bg, $env-bg);

    & > .menu-item {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 5px;
        margin: 5px 0;
        cursor: pointer;
        color: var(--main-text-color, $main-text-color);
        fill: var(--main-text-color, $main-text-color);

        &:hover {
            color: var(--title-text-color, $title-text-color);
            fill: var(--title-text-color, $title-text-color);
            border-radius: 5px;
            //background-color: var(--primary, $primary);
        }

        &.disabled {
            pointer-events: none;
            color: var(--disabled-color, $disabled-color);
            fill: var(--disabled-color, $disabled-color);
        }

        &.theme-switcher {
            white-space: nowrap;
            justify-content: space-between;
        }
    }

    & > .separator {
        height: 1px;
        border: none;
        margin: 10px 0;
        background-color: var(--separator-color, $separator-color);
    }
}

.impersonated {
    :deep(button) {
        background-color: var(--danger, $danger) !important;
    }
}
</style>