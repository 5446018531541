<template>
    <div class="cover">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'cover'
};
</script>

<style lang="scss" scoped>
.cover {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: auto;
    color: var(--home-page-color, $cover-color);
    background-color: var(--home-page-bg, $cover-bg);
}

.logo {
    top: 50px;
    left: 50px;
    position: absolute;
    width: 140px;
}

@media (max-width: 764px) {
    .logo {
        top: 25px;
        left: 25px;
        width: 125px;
    }
}
</style>