<template>
    <div class="settings environment">
        <div v-if="$route.name === 'Settings'">
            <general/>
        </div>
        <router-view v-else/>
    </div>
</template>

<script>

import { useSettingsComponents } from '@settings';
import { onMounted } from 'vue';
import { useProfileActions } from '@profile';
import { useRouter, useRoute } from 'vue-router';

export default {
    name: 'Environment',
    components: {
        General: useSettingsComponents('General')
    },
    setup() {

        const router = useRouter();
        const route = useRoute();
        const getProfileResource = useProfileActions('getProfileResource');
        const profile = getProfileResource();

        onMounted(() => {
            if (!profile.can('Settings GeneralView') && route.path === '/settings'){
                router.push({name: "NoAccess", ...route.params, query: { redirectedFrom: route.name }})
            }
        });
        return {};
    }
};
</script>

<style lang="scss" scoped>
.settings {
    position: relative;
    height: 100%;
}
</style>