import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Timeline extends BaseResource {

    static #instance = null;

    endpoint = 'timeline';
    referer = 'Timeline Resource';

    section = 'Leads';
    container = 'Timeline';

    static getInstance() {
        if (!Timeline.#instance) {
            Timeline.#instance = new Timeline();
        }
        return Timeline.#instance;
    }
}
