import { STACK, ApiClient } from '@/Ship';

export default function (target, file) {
    return STACK.push(() => {
        return ApiClient.post(`profile/upload-document/${target}`, { image: file }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    });
}
