<script setup>

import { computed, watch, reactive, ref, defineEmits } from 'vue';
import { useRouter } from 'vue-router';
import { verifyField, i18n } from '@/Ship';
import { useCampaignActions } from '@campaign';
import { useUserActions } from '@user';
import { useAuthComponents } from '@auth';
import { useCountryActions } from '@country';
import { useLeadActions } from '@lead';
import { useBrandActions } from '@brand';

const emit = defineEmits(['performed', 'close']);

const getCampaignResource = useCampaignActions('getCampaignResource');
const getSales = useUserActions('getSales');
const getCountryResource = useCountryActions('getCountryResource');
const getLeadResource = useLeadActions('getLeadResource');
const { getBrandResource } = useBrandActions();

const PasswordInput = useAuthComponents('PasswordInput');

const campaigns = getCampaignResource(true);
if (!campaigns.isLoaded) {
    campaigns.load();
}

const countries = getCountryResource();
const lead = getLeadResource();
const brands = getBrandResource();
const sales = ref([]);

const router = useRouter();

const payload = reactive({
    first_name: null,
    last_name: null,
    email: null,
    phone: '',
    country_code: '',
    password: null,
    campaign_id: null,
    sale_id: null,
    brand_id: null,
    is_test: 'Yes'
});

getSales().then(response => {
    sales.value = response.data;
});

const isTestDisplay = computed({
    get() {
        return payload.is_test === 'Yes';
    },
    set(value) {
        return payload.is_test =  value ? 'Yes' : 'No';
    }
});

const isAllow = ref(false);
const isComplex = ref(false);
const errors = reactive({
    first_name: false,
    last_name: false,
    email: false,
    phone: false,
    password: false,
});

const campaignId = computed({
    get: () => payload.campaign_id,
    set: value => {
        payload.campaign_id = value;
        const campaignIdx = campaigns.state.findIndex(campaign => campaign.id === value);

        if (campaignIdx !== -1) {
            payload.brand_id = campaigns.state[campaignIdx].brand_id;
        }

        return true;
    }
});

const firstName = computed({
    get: () => payload.first_name,
    set: value => {
        payload.first_name = value;
        errors.first_name = verifyField(value, ['require', 'minLength:2']);
        return true;
    }
});

const lastName = computed({
    get: () => payload.last_name,
    set: value => {
        payload.last_name = value;
        errors.last_name = verifyField(value, ['require', 'minLength:2']);
        return true;
    }
});

const email = computed({
    get: () => payload.email,
    set: value => {
        payload.email = value;
        errors.email = verifyField(value, ['require', 'email']);
        return true;
    }
});

const phone = computed({
    get: () => payload.phone,
    set: value => {
        payload.phone = value;
        errors.phone = verifyField(value.replace(/[\(\)\-\s]/g, ''), ['require', 'phone']);
        return true;
    }
});

const password = computed({
    get: () => payload.password,
    set: value => {
        payload.password = value;
        errors.password = isComplex.value ? null : i18n.global.t('validator.password');
        return true;
    }
});

watch([errors, () => payload.password, () => payload.campaign_id, () => payload.brand_id], () => {
    isAllow.value = true;

    for (const key in errors) {
        if (errors[key] !== null) {
            isAllow.value = false;
            break;
        }
    }

    if (payload.campaign_id === null || payload.brand_id === null) {
        isAllow.value = false;
    }
});

function make() {
    lead.create(payload).then(response => {
        emit('close');
        lead.index = lead.state.findIndex(item => item.id === response.id);
        router.push({ name: 'Lead', params: { ...router.currentRoute.value.params, id: response.id } });
    }).catch(error => {
        if (error.response && error.response.data && error.response.data.description) {
            const errorDescriptions = error.response.data.description;

            for (const key in errorDescriptions) {
                if (errors[key] !== undefined) {
                    errors[key] = errorDescriptions[key][0];
                }
            }
        }
    });
}
</script>

<template>
    <form class="make-lead" v-on:submit.prevent="make">
        <g-checkbox v-model:checked="isTestDisplay">
            {{ $t('lead.is_test', 'Is test') }}
        </g-checkbox>
        <g-select v-model="campaignId"
                  v-bind:label="$t('lead.campaign')"
                  v-bind:options="campaigns.state"
                  option-text="name"
                  option-value="id"/>

        <g-select v-model="payload.sale_id"
                  v-bind:label="$t('lead.sale')"
                  v-bind:options="sales"
                  option-text="name"
                  option-value="id"/>

        <g-field v-model="firstName"
                 v-bind:label="$t('lead.first_name')"
                 v-bind:error="errors.first_name"/>

        <g-field v-model="lastName"
                 v-bind:label="$t('lead.last_name')"
                 v-bind:error="errors.last_name"/>

        <g-field v-model="email"
                 type="email"
                 v-bind:label="$t('lead.email')"
                 v-bind:error="errors.email"/>

        <g-phone-input v-model="phone"
                       v-model:country="payload.country_code"
                       v-bind:countries="countries.state"
                       v-bind:label="$t('lead.phone')"
                       v-bind:error="errors.phone"/>

        <password-input v-model="password"
                        v-model:complex="isComplex"
                        v-bind:label="$t('auth.password')"
                        v-bind:error="errors.password"/>

        <g-flex justify="center" gap="5">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">{{ $t('base.cancel') }}</g-button>
            <g-button class="save-btn" v-bind:disabled="!isAllow" type="submit">{{ $t('base.make') }}</g-button>
        </g-flex>
    </form>
</template>

<style lang="scss" scoped>
.make-lead {
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.g-field,
.password-input {
    width: 100%;
}

.g-select {
    width: 100%;
}

.g-checkbox {
    margin-right: auto;
}

.g-button {
    padding: 0 20px;
    min-width: 100px;
}
</style>