import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Deposit extends BaseResource {

    static #instance = null;

    endpoint = 'deposits';
    referer = 'Deposit Resource';

    section = 'Finances';
    container = 'Finances';

    static getInstance() {
        if (!Deposit.#instance) {
            Deposit.#instance = new Deposit();
        }
        return Deposit.#instance;
    }
}
