<template>
    <div>
        <h2>Assets List</h2>
    </div>
</template>

<script>
export default {
    name: 'Asset'
};
</script>

<style lang="scss" scoped>

</style>