<script setup>
import { useSettingsActions, useSettingsComponents } from '@settings';

const {
    CrmSettings,
    TradingSettings,
	Statuses,
	AccountLevels,
    Brands
} = useSettingsComponents();

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource(null, true);

let timeout = null;

function save(payload, section) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
        settings.save(section, payload).then(() => {
            Object.keys(payload).forEach(key => delete payload[key]);
        });
    }, 2000);
}

</script>

<template>
    <div class="settings-wrapper" v-if="settings.isLoaded">
        <crm-settings v-bind:settings="settings.general" v-on:save="save"/>
        <trading-settings v-bind:settings="settings.trading" v-on:save="save"/>
        <brands/>
        <div class="gag"></div>
        <statuses/>
        <account-levels/>
    </div>
</template>

<style scoped lang="scss">
.settings-wrapper {
    padding: 10px;
    display: grid;
    grid-gap: 10px;
}

.gag {
    grid-area: gag;

    & > .g-field {
        width: 180px;
    }
}

.statuses {
    grid-area: sts;
}

.account-levels {
    grid-area: acl;
}

.general {
    padding: 15px;
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 1px solid var(--separator-color, $separator-color);

    &.crm {
        grid-area: crm;
    }

    &.trading {
        grid-area: trd;
    }

    &.brands {
        grid-area: brd;
    }
}

@media (min-width: 1385px) {
    .settings-wrapper {
        grid-template-areas:
            'crm crm'
            'trd brd'
            'acl sts';
        grid-template-columns: 480px 450px;
    }
}

@media (max-width: 1080px) {
    .general {
        flex-wrap: wrap;
    }
}

@media (max-width: 1384px) {
    .settings-wrapper {
        grid-template-areas:
            'crm'
            'trd'
            'sts'
            'acl';
        grid-template-columns: 1fr;
    }
}
</style>