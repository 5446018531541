
import { useUserResources } from '@user';
import { ApiClient, STACK } from '@/Ship';

export default function() {
	const user = useUserResources('User');

	return STACK.push(() => ApiClient.get(user.endpoint, { params: {
		roles: 'Sale',
		status: 'Active',
	} }));
}