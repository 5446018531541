import {ApiClient, STACK, STATE} from '@/Ship';
import { useAuthTasks} from '@auth';
const { reloadProfile, deleteToken } = useAuthTasks();

export default function () {
    return STACK.push(() => ApiClient.delete('/logout', { header: {Ref: 'SignOut Action'}})).then(() => {
        STATE.IS_LOGIN = false;
        STATE.IS_LIGHT_MODE = false;
        localStorage.setItem('theme', 'dark');
        document.documentElement.setAttribute('theme', 'dark');
        deleteToken();
        reloadProfile();
    });
}
