<script setup>
import { computed, reactive, defineProps, defineEmits } from 'vue';
import { useBrandActions } from '@brand';
import { useSettingsActions } from '@settings';
import { verifyField } from '@/Ship';

const props = defineProps(['payload']);
const emit = defineEmits(['performed', 'close']);

const payload = reactive(props.payload);

const getBrandResource = useBrandActions('getBrandResource');
const brands = getBrandResource();

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource('brands');

const errors = reactive({
    name: null,
    domain: null,
});

const name = computed({
    get: () => payload.name,
    set: value => {
        payload.name = value;
        errors.name = verifyField(value, ['require', 'minLength:2']);
        return true;
    }
});

const domain = computed({
    get: () => payload.domain,
    set: value => {
        payload.domain = value;
        errors.domain = verifyField(value, ['require', 'domain']);
        return true;
    }
});

async function editBrand() {
    const index = settings.brands.findIndex(item => item.id === props.payload.id);
    settings.save('brands', props.payload).then(() => {
        settings.brands[index] = payload;
        emit('close')
    }).catch(error => {
        if (error.response && error.response.data && error.response.data.description) {
            const errorDescriptions = error.response.data.description;

            for (const key in errorDescriptions) {
                if (errors[key] !== undefined) {
                    errors[key] = errorDescriptions[key][0];
                }
            }
        }
    });
}

</script>

<template>
    <form class="edit-brand" v-on:submit.prevent="editBrand">
        <g-field v-model="domain"
                 v-bind:label="$t('settings.domain', 'Domain')"
                 :error="errors.domain"/>
        <g-field v-model="name"
                 v-bind:label="$t('settings.new_brand', 'New Brand')"
                 :error="errors.name"
                 v-on:keyup.enter="editBrand"/>
        <g-flex class="btn-bar" gap="5" justify="center">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">{{ $t('base.cancel', 'Cancel') }}</g-button>
            <g-button class="save-btn"
                      type="submit"
                      v-bind:disabled="!payload.name || !payload.domain">
                {{ $t('base.save', 'Save') }}
            </g-button>
        </g-flex>
    </form>
</template>

<style scoped lang="scss">
.edit-brand {
    width: 300px;
}

.g-field {
    margin-bottom: 14px;
}

.btn-bar {
    padding-top: 40px;

    & > .g-button {
        padding: 0 20px;
        min-width: 100px;
    }
}
</style>