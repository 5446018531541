import { useSettingsResources } from '@settings';
import {ResourcesManager} from "@/Ship";

export default () => {
    const settings = useSettingsResources('Settings');
    if (!settings.isLoaded && !settings.isLoading) {
        //settings.setIncludes([]);
        settings.load();
    }

    ResourcesManager.addResource(settings, 'Settings');

    return settings;
};
