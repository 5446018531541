export function preloaderAligner(scrollableElementClass) {
    const scrollableElement = document.querySelector(scrollableElementClass);
    const preloaderWrapper = document.querySelector('.preloader-wrapper');

    if (!scrollableElement || !preloaderWrapper) {
        return;
    }

    scrollableElement.addEventListener('scroll', function () {
        const scrolledTop = scrollableElement.scrollTop;
        const scrolledLeft = scrollableElement.scrollLeft;
        preloaderWrapper.style.top = `${scrolledTop}px`;
        preloaderWrapper.style.left = `${scrolledLeft}px`;
    });
}
