import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Notification extends BaseResource {

    static #instance = null;

    endpoint = 'Notifications';

    section = 'Users';
    container = 'Notifications';

    static getInstance() {
        if (!Notification.#instance) {
            Notification.#instance = new Notification();
        }
        return Notification.#instance;
    }
}
