/* EXAMPLE DATA
		const orders = new Orders();
		const autoSaveForm = new AutoSaveForm();

		let timeout = null;

		orders.state = [ <- EXAMPLE Forms Data
			{
				name: "Sd",
				middleName: "sdsd",
				secondName: "",
			},
			{
				name: "OO",
				middleName: "OOO",
				secondName: "",
			},
		];
		orders.index = 0; // orders.model = orders.state[0]

		watch(
			() => orders.entry,
			() => {
				clearTimeout(timeout);
				timeout = setTimeout(() => {
					autoSaveForm.push(orders);
					autoSaveForm.push(orders);
				}, 1000);
			},
			{ deep: true }
		);
*/

import _ from 'lodash';
import { STATE } from '@/Ship';
import { watch, ref } from 'vue';

//import { useShipActions } from '@/Ship';
//const { filterObject } = useShipActions();

export default class AutoSaveStack {
    stack = ref([]);
    uniqData = {}; //accum all updated value,
    timeout = null;

    static #instance = null;

    constructor() {
        if (!AutoSaveStack.#instance) {
            AutoSaveStack.#instance = this;

            // watch(() => this.stack, curStack => {
            //     if (Object.keys(curStack.value).length > 0) {
            //         STATE.AUTO_SAVE_INDICATOR = 1
            //         this.resolveStack();
            //     }
            // });
        }
        return AutoSaveStack.#instance;
    }

    static getInstance() {
        return new AutoSaveStack();
    }

    observe() {

    }

    push(resource) {
        let timeout = null;
        watch(
            resource.entity,
            (curEntry, prevEntry) => {
                clearTimeout(timeout);

                //this.uniqData = { ...this.uniqData, ...filterObject(resource.model, (key) => curEntry[key] == prevEntry[key]) }; // ACUM All updated input
                //this.uniqData = filterObject(this.uniqData, (key) => !!resource.validator?.form?.[key]); // Filter not Valid Field
                //this.uniqData = filterObject(this.uniqData, (key) => resource.staticInput?.includes(key)); // Filter static input

                if (Object.keys(resource.entity).length) {
                    timeout = setTimeout(() => {
                        STATE.AUTO_SAVE_INDICATOR = 1;
                        this.stack.value.push(() => {
                            STATE.AUTO_SAVE_INDICATOR = 2;
                            resource.save().then(() => {
                                Object.assign(resource.state[resource.index], resource.entity);
                                resource.clearEntity();
                                STATE.AUTO_SAVE_INDICATOR = 3;
                            }).catch(error => {
                                STATE.AUTO_SAVE_INDICATOR = 4;
                            });
                        });
                        this.resolveStack();
                    }, 1500);
                }
            },
            { deep: true }
        );
    }

    resolveStack() {
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {

            if (this.stack.value.length) {
                const task = this.stack.value.shift();
                task();
                this.resolveStack();
            } else {
                STATE.AUTO_SAVE_INDICATOR = 0;
                this.stack.value = [];
            }
        }, 1000);
    }
}