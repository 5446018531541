<script setup>
import { defineEmits, reactive, ref, watch, computed } from 'vue';
import { useShipActions } from '@/Ship';
import { useFinanceActions } from '@finance';
import { useLeadActions } from '@lead';
import { useProfileActions } from '@profile';

//const props = defineProps(['payload']);
const emit = defineEmits(['performed','close']);

const getRates = useFinanceActions('getRates');

const onlyNumberInput = useShipActions('onlyNumberInput');

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const payload = reactive({
    type: 'deposit',
    lead_id: lead.model.id,
    currency: 'USD',
    rawAmount: null,
    rate: 1,
    method: 'Card',
    sub_method: '',
});

const rates = reactive({
    USD: 1,
    USDT: 1,
    EUR: null,
});

function chooseMethod() {
    if (payload.method === 'Crypto') {
        getRates().then(response => {
            response.data.forEach(ticker => {
                rates[ticker.base] = Number(ticker.price)
            });
        })

        payload.sub_method = 'Ethereum';
        payload.currency = 'USDT';
    } else {
        payload.sub_method = '';
        payload.address = '';
        currency.value = 'USD';
    }
}

const currencies = computed(() => {
        return {
            Bitcoin: [
                {value: 'BTC'}
            ],
            Ethereum: [
                {value: 'ETH'},
                {value: 'USDT'},
            ],
            Tron: [
                {value: 'TRX'},
                {value: 'USDT'},
            ],
        }[payload.sub_method] || [
            {value: 'USD'},
            {value: 'EUR'},
        ]
});

const type = computed({
    get() {
        return payload.type;
    },
    set(value) {
        payload.type = value;
        if (value === 'deposit') {
            payload.method = 'Card';
            currency.value = payload.method === 'Crypto'
                ? 'USDT'
                : 'USD'
        } else {
            payload.method = 'Other';
            payload.sub_method = '';
            payload.address = '';
            currency.value = 'USD';
        }

        return true;
    }
});

const currency = computed({
    get() {
        return payload.currency;
    },
    set(value) {
        payload.currency = value;
        if (['USD', 'USDT'].includes(value)) {
            rate.value = 1;
        } else if (value === 'EUR') {
            rate.value = null;
            errors.rate = 'Specify rate';
        } else {
            rate.value = rates[value];
        }

        return true;
    }
});

const amount = computed({
    get() {
        return payload.rawAmount;
    },
    set(value) {
        payload.rawAmount = value;
        payload.amount = value * payload.rate;

        if (value > 0) {
            errors.amount = null;
        } else {
            errors.amount = 'Amount must be greater than zero';
        }

        return true;
    }
});

const rate = computed({
    get() {
        return payload.rate;
    },
    set(value) {
        payload.rate = value;
        payload.amount = payload.rawAmount * value;

        if (value > 0) {
            errors.rate = null;
        } else {
            errors.rate = 'Rate must be greater than zero';
        }

        return true;
    }
});

const isManuallyDate = ref(false);
const manuallyDate = computed({
    get() {
        return isManuallyDate.value;
    },
    set(value) {
        isManuallyDate.value = value;
        if (!value) {
            delete payload.created_at;
        }


        return true;
    }
});

const isLock = ref(true);
const errors = reactive({
    amount: null,
    rate: null,
});

watch([errors, () => payload.amount], () => {
    isLock.value = !(payload.amount > 0);
    for (const key in errors) {
        if (errors[key] !== null) {
            isLock.value = true;
            break;
        }
    }
});

const getDepositResource = useFinanceActions('getDepositResource');
const resource = getDepositResource();
function makeDeposit() {
	resource.create(payload).then(res => {
        lead.model.balance = (Number(res.amount) + Number(lead.model.balance)).toFixed(2);
        emit('close');
	});
}
</script>

<template>
    <form v-on:submit.prevent="makeDeposit">

        <g-select v-model="type"
                  class="replenish-type"
                  v-bind:label="$t('finances.replenish_type', 'Replenish Type')"
                  v-bind:options="[{value: 'deposit'}, {value: 'bonus'}, {value: 'credit'},{value: 'repayment'}, {value: 'fake'}]"
                  option-text="value"
                  option-value="value"/>

        <g-select v-model="payload.method"
                  v-bind:label="$t('finances.method', 'Method')"
                  v-bind:options="[{value: 'Card'}, {value: 'Wire'}, {value: 'Crypto'}, {value: 'Other'}]"
                  v-on:change="chooseMethod"
                  v-bind:disabled="payload.type !== 'deposit'"
                  option-text="value"
                  option-value="value"/>

        <div>
            <g-select v-if="payload.method === 'Crypto'"
                      v-model="payload.sub_method"
                      v-bind:options="[{value: 'Bitcoin'}, {value: 'Ethereum'}, {value: 'Tron'}]"
                      v-bind:label="$t('finances.network', 'Network')"
                      v-on:change="currency = payload.sub_method === 'Bitcoin' ? 'BTC' : 'USDT'"
                      v-bind:disabled="payload.type !== 'deposit'"
                      option-text="value"
                      option-value="value"/>
            <g-combobox v-else
                        v-model="payload.sub_method"
                        v-bind:options="{Card: ['WayForPay','Stripe','Liqpay'], Wire: ['Wise','Zen','Bank']}[payload.method] || []"
                        v-bind:label="$t('finances.sub_method', 'SubMethod')"
                        v-bind:disabled="payload.type !== 'deposit'"/>
            <!--<g-field v-else v-model="payload.sub_method"/>-->
        </div>

        <g-select v-model="currency"
                  v-bind:disabled="payload.type !== 'deposit'"
                  v-bind:label="$t('finances.currency', 'Currency')"
                  v-bind:options="currencies"
                  option-text="value"
                  option-value="value"/>

        <g-field v-model="amount"
                 v-bind:error="errors.amount"
                 v-on:keypress="onlyNumberInput"
                 v-bind:label="$t('finances.amount', 'Amount')"/>
		<g-field v-model="rate"
                 v-bind:error="errors.rate"
                 v-bind:disabled="payload.type !== 'deposit'"
                 v-bind:label="$t('finances.rate', 'Rate')"/>


        <div class="address full-row">
            <g-field v-if="payload.method === 'Crypto'"
                     v-model="payload.address"
                     v-bind:label="$t('finances.address_from', 'From Address')"/>
        </div>

        <!--<g-checkbox v-model="payload.is_ftd">Is FTD</g-checkbox>-->
        <g-flex justify="start" align="center">
            <g-checkbox v-model:checked="manuallyDate">Set date manually</g-checkbox>
        </g-flex>

        <g-flex justify="end" align="center">
            <g-checkbox v-model:checked="payload.is_test">Is Test</g-checkbox>
        </g-flex>

        <fieldset>
            <legend>{{ $t('base.date', 'Date') }}</legend>
            <input v-model="payload.created_at"
                   class="datetime"
                   type="datetime-local"
                   v-bind:disabled="!isManuallyDate || profile.cant('Deposit Make')"/>
        </fieldset>

		<g-button v-bind:disabled="isLock">{{ $t('base.make', 'Make') }}</g-button>
    </form>
</template>

<style scoped lang="scss">
form {
    max-width: 380px;
    display: grid;
    grid-gap: 8px;
    align-items: end;
    grid-template-columns: 1.2fr 0.8fr;
}

.replenish-type {
    &:deep(input),
    &:deep(label) {
        text-transform: capitalize;
    }
}

.datetime {
    padding: 0 10px;
    background-color: transparent;
    color: var(--main-text-color, $main-text-color);
    height: var(--input-height, $input-height);
    border: 1px solid var(--input-border, $input-border);
    border-radius: var(--input-rounding, $input-rounding);
}

.g-button {
    margin-top: 6px;
    color: white;
    background-color: var(--save-btn-bg, $save-btn-bg);
}

.address {
    min-height: calc(var(--field-height, $field-height) + 20px);
}

.full-row {
    grid-column: 1/3;
}
</style>