export default [{
    name: 'Settings',
    path: `/:locale(${process.env.VUE_APP_AVAILABLE_LOCALES})?/settings`,
    component: () => import('./View.vue'),
    children: [
        {
            name: 'General',
            path: `general`,
            component: () => import('./components/General.vue'),
        },
        {
            name: 'Permissions',
            path: `permissions`,
            component: () => import('./components/Permissions.vue'),
        },
        {
            name: 'Countries',
            path: `countries`,
            component: () => import('./components/Countries.vue'),
        },
        {
            name: 'Teams',
            path: `teams`,
            component: () => import('./components/Teams.vue'),
        },
        {
            name: 'Assets',
            path: `assets`,
            component: () => import('./components/Assets.vue'),
        }
    ]
}];
