import { useNotificationResources } from '@notification';
import {ResourcesManager} from "@/Ship";

export default () => {
    const notification = useNotificationResources('Notification');
    if (!notification.isLoaded && !notification.isLoading) {
        //notification.setIncludes([]);
        notification.load();
    }

    ResourcesManager.addResource(notification, 'Notification');

    return notification;
};
