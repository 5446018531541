import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class User extends BaseResource {

    static #instance = null;

    endpoint = 'users';
    referer = 'User Resource';

    section = 'Users';
    container = 'Users';

    static getInstance() {
        if (!User.#instance) {
            User.#instance = new User();
        }
        return User.#instance;
    }
}
