import { useAssetResources } from '@asset';
import {ResourcesManager} from "@/Ship";

export default () => {
    const asset = useAssetResources('Asset');
    if (!asset.isLoaded && !asset.isLoading) {
        //asset.setIncludes([]);
        asset.load();
    }

    ResourcesManager.addResource(asset, 'Asset');

    return asset;
};
