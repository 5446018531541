<script setup>

import { ref } from 'vue';
import { useUserActions } from '@user';
import { useProfileActions } from '@profile';

const getUserResource = useUserActions('getUserResource');
const user = getUserResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const ip = ref('');
const isWeb = ref(true);

function removeIp(index) {
    user.save(user.model.id, (item => {
        return {
            drop_ip: item.ip,
            guard: item.guard
        }})(user.model.whitelist[index]),
    ).then(() => {
        user.state[user.index].whitelist.splice(index, 1);
    });
}

function addIp() {

    const guard = isWeb.value ? 'web' : 'api';

    user.save(user.model.id, {
        add_ip: ip.value,
        guard,
    }).then(() => {
        user.state[user.index].whitelist.push({
            ip: ip.value,
            guard
        });

        ip.value = '';
        isWeb.value = true;
    });
}
</script>

<template>
    <fieldset class="whitelist">
        <legend>{{$t('user.whitelist')}}</legend>

        <table class="custom-scrollbar">
            <thead>
            <tr>
                <td></td>
                <td class="ip">IP</td>
                <td class="guard">Guard</td>
                <td v-if="profile.can('User Edit')" class="title action"></td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in user.model.whitelist" v-bind:key="`ip-${item.id}`" class="row">
                <td class="index">{{ (i + 1) }}</td>
                <td class="ip">{{ item.ip }}</td>
                <td class="guard">{{ item.guard }}</td>
                <td v-if="profile.can('User Edit')" class="cell actions">
                    <button class="remove-btn with-tooltip"
                            v-bind:data-tooltip="$t('user.remove_ip_from_whitelist')"
                            v-on:click="removeIp(i)">
                        <g-symbol name="close" width="16" height="16"/>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
        <g-flex class="controls" align="center" justify="between" gap="8">
            <g-input v-model="ip"
                     v-bind:placeholder="$t('user.new_ip', 'New IP')"
                     v-on:keypress.enter="addIp"
                     v-bind:readonly="profile.cant('User Edit')">
                <g-symbol v-if="profile.cant('User Edit')" name="lock" width="18" height="18"/>
            </g-input>

            <g-switch v-model:checked="isWeb" v-bind:disabled="profile.cant('User Edit')"/>
            <span class="label">{{ isWeb ? 'Web' : 'Api' }}</span>

            <g-button class="add-btn" v-on:click="addIp" v-bind:disabled="profile.cant('User Edit')">
                <g-symbol name="import" width="22" height="22"/>
            </g-button>
        </g-flex>
    </fieldset>
</template>

<style scoped lang="scss">
.whitelist {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 220px;
    padding: 8px 15px 15px;
    border: 1px solid var(--separator-color, $separator-color);
}

tr {
    &:nth-child(odd) {
        background-color: var(--env-bg, $env-bg);
    }

    &:nth-child(even) {
        background-color: var(--bar-bg, $bar-bg);
    }
}

td {
    padding: 6px 8px;

    &.ip {
        width: 100%;
    }

    &.guard {
        text-align: center;
    }
}

.controls {
    margin-top: 15px;

    .g-input {
        flex-grow: 1;
    }

    .add-btn {
        width: 60px;
        fill: white;
        color: white;
        background-color: var(--save-btn-bg, $save-btn-bg);
    }
}

.remove-btn {
    position: relative;
    background-color: transparent;
    fill: var(--main-text-color, $main-text-color);

    &.with-tooltip::after {
        left: 50%;
        width: 100px;
        font-size: 0.8rem;
        transform: translateX(-85%);
        top: calc(95% + var(--tooltip-arrow-size, $tooltip-arrow-size));
        background-color: var(--danger, $danger);
    }

    &.with-tooltip::before {
        transform: translateX(-50%);
        left: 50%;
        top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
        border-bottom-color: var(--danger, $danger);
    }
}
</style>
