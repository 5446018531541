<template>
    <form class="sign-in" v-on:submit.prevent="login">
        <g-caption class="h4 center" weight="700">{{ $t('auth.sign_in') }}</g-caption>
        <g-field v-model="email"
                 type="email"
                 v-bind:label="$t('auth.email')"
                 v-bind:error="errors.email"/>
        <g-field v-model="password"
                 v-bind:type="showPassword ? 'text' : 'password'"
                 v-bind:label="$t('auth.password')"
                 v-bind:error="errors.password">
            <button type="button" class="button-input" v-on:click="showPassword = !showPassword">
                <g-symbol :name="showPassword ? 'eye-open' : 'eye-close'" width="24" height="24"/>
            </button>
        </g-field>

        <g-checkbox v-model:checked="rememberMe">{{ $t('auth.remember', 'Remember me') }}</g-checkbox>

        <g-button>{{ $t('auth.sign_in') }}</g-button>
    </form>
</template>

<script>

import { computed, ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthActions } from '@auth';
import { i18n, PopUpManager as PopUp, STATE, verifyField } from '@/Ship';
import { useProfileActions } from '@profile';

const signIn = useAuthActions('signIn');
const getProfileResource = useProfileActions('getProfileResource');

export default {
    name: 'SignIn',

    setup() {
        const router = useRouter();
        const profile = getProfileResource();
        const rememberMe = ref(false);
        const showPassword = ref(false);

        const payload = reactive({
            email: '',
            password: '',
        });

        const errors = reactive({
            email: null,
            password: null,
        });

        const email = computed({
            get: () => payload.email,
            set: (value) => {
                payload.email = value;
                errors.email = verifyField(value, ['require', 'email']);
                return true;
            }
        });

        const password = computed({
            get: () => payload.password,
            set: (value) => {
                payload.password = value;
                return true;
            }
        });


        if (localStorage.getItem('rememberMe')) {
            payload.email = localStorage.getItem('email');
            payload.password = localStorage.getItem('password');
            rememberMe.value = true;
        }

        const login = () => {
            signIn(payload.email, payload.password).then(() => {
                if (rememberMe.value) {
                    localStorage.setItem('email', email.value);
                    localStorage.setItem('password', password.value);
                    localStorage.setItem('rememberMe', true);
                } else {
                    localStorage.removeItem('username');
                    localStorage.removeItem('password');
                    localStorage.removeItem('rememberMe');
                }

                return profile.load().then(() => {
                    STATE.IS_LOGIN = true;
                    router.push({ name: 'Welcome', params: router.currentRoute.value.params });
                });
            }).catch(() => {
                PopUp
                    .open(require('@auth/components/signUpInfoPonpUp'))
                    .setCaption(i18n.global.t('base.info'))
                    .setPayload({
                        payload:
                            {
                                mainText: i18n.global.t('base.something_wrong'),
                                additionText: i18n.global.t('auth.wrong_login_or_password')
                            }
                    });
            });
        }

        return {
            errors,
            email,
            password,
            showPassword,
            rememberMe,
            login,
        };
    },
};
</script>

<style lang="scss" scoped>
.sign-in {
    padding: 0 18px;
    max-width: 460px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.link {
    font-size: 106%;
    font-weight: 600;
    margin-left: 5px;
    color: var(--secondary, $secondary);
    transition: color 0.2s;

    &:hover {
        color: var(--primary, $primary);
    }
}

.center {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.g-button {
    margin: 25px 0;
    flex-basis: 100%;
    background-color: var(--login-btn, $login-btn);

    &:hover {
        background-color: var(--login-btn-hover, $login-btn-hover);
    }

    &:active {
        background-color: var(--login-btn-active, $login-btn-active);
    }
}

.button-input {
    display: flex;
    background-color: transparent;
}

.g-field {
    flex-basis: 100%;
    margin-bottom: 14px;
}

.g-caption {
    margin-bottom: 25px;
}
</style>
