import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Role extends BaseResource {

    static #instance = null;

    endpoint = 'roles';
    referer = 'Role Resource';

    section = 'Settings';
    container = 'Permissions';

    static getInstance() {
        if (!Role.#instance) {
            Role.#instance = new Role();
        }
        return Role.#instance;
    }
}
