import { useLeadResources } from '@lead';
import { ResourcesManager } from "@/Ship";

export default (reload = false) => {
    const lead = useLeadResources('Lead');
    lead.onload = [];
    if (reload) {
        if (!lead.isLoading) {
            lead.perPage = 25;
            lead.setIncludes(['affiliate', 'sale', 'retention', 'country', 'campaigns', 'activity']);
            // lead.load();
        }
    }

    ResourcesManager.addResource(lead, 'Lead');

    return lead;
};
