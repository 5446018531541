<template>
    <form class="make-pairs" v-on:submit.prevent="makePair">
        <g-select v-model="payload.brand_id"
                  v-bind:label="$t('campaign.brands', 'Brand')"
                  v-bind:options="brands.state"
                  option-text="name"
                  option-value="id"/>
        <g-select v-model="payload.market"
                  :label="$t('assets.market', 'Market')"
                  :options="marketList"
                  option-text="market"
                  text-transform="capitalize"
                  option-value="market"/>
        <g-select v-model="payload.base"
                  :label="$t('assets.base', 'Base')"
                  :options="filteredAssets"
                  option-text="symbol"
                  option-value="symbol"
                  :error="'error'"
                  :disabled="!payload.market"/>
        <g-select v-model="payload.counter"
                  :label="$t('assets.counter', 'Counter')"
                  :options="counterByMarket(payload.market)"
                  option-text="counter"
                  option-value="counter"
                  :disabled="!payload.market || !counterByMarket(payload.market).length"/>

        <g-flex class="btn-bar" gap="5" justify="end">
            <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">{{ $t('base.cancel', 'Cancel') }}</g-button>
            <g-button class="make save-btn" type="submit"
                      v-bind:disabled="!payload.market || !payload.base || !payload.counter || !payload.brand_id">
                {{ $t('base.make', 'Make') }}
            </g-button>
        </g-flex>
    </form>
</template>

<script>
import { useAssetActions } from '@asset';
import { computed, reactive, ref } from 'vue';
import { useBrandActions } from '@brand';

const getPairResource = useAssetActions('getPairResource');
const getAssetResource = useAssetActions('getAssetResource');
const { getBrandResource } = useBrandActions();
export default {
    name: 'MakePair',
    setup(props, { emit }) {
        const pair = getPairResource();
        const asset = getAssetResource();
        const brands = getBrandResource();

        const payload = reactive({
            brand_id: '',
            base: '',
            counter: '',
            market: '',
        });
        const assets = computed(() => asset.state);
        const pairs = computed(() => pair.state);

        const marketList = [{ market: 'crypto' }, { market: 'commodities' }, { market: 'stocks' }, { market: 'forex' }];

        const filteredAssets = computed(() => {
            const createdPairs = pairs.value.map(pair => pair.symbol);
            return assets.value.filter(asset => {
                const canCreatePair = counterByMarket(payload.market).some(counter => counter.counter === asset.counter);
                return !createdPairs.includes(asset.symbol) || !canCreatePair;
            });
        });

        const forexCounters = computed(() => {
            const uniqueCounters = [...new Set(assets.value.map(item => item.symbol))];
            return uniqueCounters.map(counter => ({ counter }));
        });

        const counterByMarket = (value) => {
            const createdCounters = pairs.value
            .filter(pair => pair.base === payload.base)
            .map(pair => pair.counter);
            switch (value) {
                case 'crypto':
                    return [{ counter: 'USDT' }, { counter: 'ETH' }, { counter: 'BTC' }].filter(item => {
                        return !createdCounters.includes(item.counter) && item.counter !== payload.base;
                    });
                case 'commodities':
                    return [{ counter: 'USDT' }].filter(item => {
                        return !createdCounters.includes(item.counter) && item.counter !== payload.base;
                    });
                case 'stocks':
                    return [{ counter: 'USDT' }].filter(item => {
                        return !createdCounters.includes(item.counter) && item.counter !== payload.base;
                    });
                case 'forex':
                    return forexCounters.value.filter(item => {
                        return !createdCounters.includes(item.counter) && item.counter !== payload.base;
                    });

                default:
                    return [{ counter: 'USDT' }, { counter: 'ETH' }, { counter: 'BTC' }].filter(item => {
                        return !createdCounters.includes(item.counter) && item.counter !== payload.base;
                    });
            }
        };

        function makePair() {
            payload.symbol = `${payload.base.toUpperCase()}${payload.counter}`;
            pair.create(payload).then(() => {
                emit('close');
            });
        }

        return {
            payload,
            marketList,
            filteredAssets,
            assets,
            pairs,
            brands,
            makePair,
            counterByMarket,
        };
    }
};
</script>


<style scoped lang="scss">
.make-pairs {
    width: 320px;

    & > .g-field,
    & > .g-select {
        padding-top: 10px;
    }

    :deep(.datalist) {
        max-height: 160px;
        max-width: 100%;
        overflow-x: hidden;
    }
}

fieldset {
    margin-top: 15px;
    gap: 10px;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    & > .g-field,
    & > .g-select {
        width: calc(50% - 5px);
    }
}

.btn-bar {
    margin-top: 50px;
}

.g-button {
    width: 100px;
}
</style>