<script setup>

import { useUserActions } from '@user';
import { useProfileActions } from '@profile';

const getUserResource = useUserActions('getUserResource');
const users = getUserResource();

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

if (!profile.state.user) {
    profile.state.user = {
        filters: {},
        sortBy: 'users.id',
        sortDir: 'desc',
    };
}

users.setFilters(profile.state.user.filters);

function filtrate() {
    //users.setFilters(filters);
    users.setPage(1);
    for (const key in users.filters) {
        if (!Object.keys(users.filters[key]).length) {
            delete users.filters[key];
        }
    }
    users.load(null, {}, 'UserSidebar Filterate');

    profile.state.user.filters = users.filters;
    profile.saveState();
}
</script>

<template>
    <g-filterset v-model="users.filters.roles"
                v-bind:label="$t('parts.roles', 'Roles')"
                value-field="key"
                v-bind:filterset="users.filterset.roles"
                v-on:change="filtrate"
                expanded/>

    <g-filterset v-model="users.filters.teams"
                v-bind:label="$t('parts.teams', 'Teams')"
                value-field="id"
                v-bind:filterset="users.filterset.teams"
                v-on:change="filtrate"
                expanded/>

    <g-filterset v-model="users.filters.status"
                v-bind:label="$t('settings.statuses', 'Statuses')"
                value-field="key"
                v-bind:filterset="users.filterset.status"
                v-on:change="filtrate"
                expanded/>
</template>

<style lang="scss" scoped>
.g-filterset {
    border: none !important;
    padding: 10px 16px 20px 10px;

    &:deep(.value) {
        max-width: 120px !important;
    }
}
</style>