<script>
import { useCustomerComponents } from '@customer';
import { useEnvComponents } from '@overlay';

const {
    Customer,
    Sidebar,
    Environment,
} = useCustomerComponents();

export default {
    name: 'Users',
    extends: useEnvComponents('Layout'),
    components: {
        Sidebar,
        Environment,
        Rightbar: Customer,
    },
    setup() {

        return {
            title: 'customers',
            sidebar: true,
            rightbar: true,
        };
    }
};
</script>
