<script>
import { useSettingsComponents } from '@settings';
import { useEnvComponents } from '@overlay';

const {
    Sidebar,
    Environment,
} = useSettingsComponents();

export default {
    name: 'Settings',
    extends: useEnvComponents('Layout'),
    components: {
        Sidebar,
        Environment,
    },

    setup() {

        return {
            sidebar: true,
            title: 'settings',
        };
    }
};
</script>
