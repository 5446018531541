import { useTeamResources } from '@team';
import {ResourcesManager} from "@/Ship";

export default (reload = false) => {
    const team = useTeamResources('Team');
    if (reload || (!team.isLoaded && !team.isLoading)) {
        team.setIncludes(['teamlead', 'users.roles']);
        team.sort('id', 'desc');
        team.load();
    }

    ResourcesManager.addResource(team, 'Team');

    return team;
};
