
import { STACK, ApiClient, STATE } from '@/Ship';

export default function (id) {
	return STACK.push(() => {
		return ApiClient.delete(`impersonate/${id}`).then(response => {
			localStorage.setItem('access_token', localStorage.getItem('real_token'));
			localStorage.setItem('expires_in', localStorage.getItem('real_expires_in'));
			localStorage.removeItem('impersonate');
			localStorage.removeItem('real_token');
			localStorage.removeItem('real_expires_in');
			location.reload();
			//STATE.IS_LOGIN = true;
		});
	});
}