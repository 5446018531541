<script setup>

import { computed, ref, defineEmits, defineProps } from 'vue';
import { useUserActions } from '@user';
import { useLeadActions } from '@lead';
import { useProfileActions } from '@profile';
import { useSettingsActions } from '@settings';

const getProfileResource = useProfileActions('getProfileResource');

const getSales = useUserActions('getSales');
const getRetentions = useUserActions('getRetentions');

const {
    massUpdate,
    massDelete,
    archiving,
    getLeadResource,
} = useLeadActions();

const leads = getLeadResource();

 const props = defineProps({
     targets: {
         type: Array,
         default: () => [],
     }
 });

const emit = defineEmits(['updated', 'drop']);


const profile = getProfileResource();

const sale = ref(null);
const retention = ref(null);

const conversionStatus = ref(null);
const retentionStatus = ref(null);

const sales = ref([]);
const retentions = ref([]);

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource('statuses');

const statuses = computed(() => settings.statuses || []);

getSales().then(response => {
    sales.value = response.data;
});

const isAllowRitentionAssign = ref(true);
props.targets.forEach(id => {
    id = Number(id);
   const lead = leads.state.find(lead => lead.id === id);
   if (lead.is_ftd === 'No') {
       isAllowRitentionAssign.value = false;
       return;
   }
});

getRetentions().then(response => {
    retentions.value = response.data;
});

function update() {
    const payload = {
        targets: props.targets,
    };
    if (sale.value) {
        payload.sale_id = sale.value;
    }

    if (retention.value) {
        payload.retention_id = retention.value;
    }

    if (conversionStatus.value) {
        payload.conversion_status = conversionStatus.value;
    }

    if (retentionStatus.value) {
        payload.retention_status = retentionStatus.value;
    }

    massUpdate(payload).then(() => {
        emit('updated');
    });
}

function removeLeads() {
    massDelete(props.targets).then(() => {
        emit('updated');
    });
}

function archiveLeads() {
    archiving.massArchive(props.targets).then(() => {
        emit('updated');
    });
}
</script>

<template>
    <form v-on:submit.prevent="update">
        <fieldset>
            <legend>Mass Update <span class="selected-count"> ( Selected leads: {{ targets.length }} )</span></legend>
            <g-symbol class="close-btn" name="close" width="20" height="20" v-on:click="$emit('drop')"/>

            <div class="select-group">
                <g-select v-if="profile.can('Lead SaleView')"
                          v-model="sale"
                          v-bind:options="sales"
                          :label="$t('lead.assign_sale')"
                          option-text="name"
                          option-value="id"
                          v-bind:readonly="profile.cant('Lead SaleEdit')"/>

                <g-select v-if="profile.can('Lead ConversionStatusView')"
                          v-model="conversionStatus"
                          v-bind:options="statuses.filter(item=> item.target === 'conversion')"
                          :label="$t('lead.conversion_status')"
                          option-text="name"
                          option-value="name"
                          v-bind:readonly="profile.cant('Lead ConversionStatusEdit')"/>

                <g-select v-if="profile.can('Lead RetentionView')"
                          v-model="retention"
                          v-bind:options="retentions"
                          :label="$t('lead.assign_retention')"
                          option-text="name"
                          option-value="id"
                          v-bind:readonly="profile.cant('Lead RetentionEdit') || !isAllowRitentionAssign"/>

                <g-select v-if="profile.can('Lead RetentionStatusView')"
                          v-model="retentionStatus"
                          v-bind:options="statuses.filter(item=> item.target === 'retention')"
                          :label="$t('lead.retention_status')"
                          option-text="name"
                          option-value="name"
                          v-bind:readonly="profile.cant('Lead RetentionStatusEdit')"/>
            </div>

            <div class="separator"></div>

            <g-button class="apply-btn"
                      type="submit"
                      :disabled="!sale && !retention && !conversionStatus && !retentionStatus">
                <g-symbol name="check" width="20" height="20"/>
                {{ $t('base.apply') }}
            </g-button>

            <g-button v-if="profile.can('Lead Archive')"
                      class="archive-btn"
                      type="button"
                      v-on:click="archiveLeads"
                      v-bind:data-tooltip="$t('base.archive', 'Archive')">
                <g-symbol name="archive" width="20" height="20"/>
            </g-button>
            <g-button v-if="profile.can('Lead Remove')"
                      class="remove-btn"
                      type="button"
                      v-on:click="removeLeads"
                      v-bind:data-tooltip="$t('base.remove', 'Remove')">
                <g-symbol name="delete" width="20" height="20"/>
            </g-button>
        </fieldset>
    </form>
</template>

<style lang="scss" scoped>
form {
    z-index: 3;
    bottom: 10px;
    position: sticky;
    container-type: inline-size;
    container-name: form;
}

.select-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

fieldset {
    gap: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 25px 25px 20px;
    background-color: var(--popup-bg, $popup-bg);
    border: 1px solid var(--success, $success);
    border-radius: var(--bar-rounding, $bar-rounding);
    box-shadow: 0 -20px 30px 14px var(--body-bg, $body-bg);

    & > legend {
        padding: 0 6px;
        font-size: 18px;
        font-weight: bold;
        color: var(--main-text-color, $main-text-color);
    }
}

.g-button {
    color: black;
    font-weight: bold;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.apply-btn {
        width: 110px;
        flex-shrink: 0;
        background-color: var(--success, $success);

        &:hover {
            color: white;
            fill: white;
            background-color: var(--success-hover, $success-hover);
        }
    }

    &.activate-btn {
        background-color: transparent;
        fill: var(--success, $success);
        color: var(--title-color, $title-color);
        border: 1px solid var(--success-hover, $success-hover);

        &:hover {
            color: white;
            fill: white;
            background-color: var(--success, $success);
        }
    }

    &.archive-btn {
        background-color: transparent;
        fill: var(--danger-hover, $danger-hover);
        color: var(--title-color, $title-color);
        border: 1px solid var(--danger-hover, $danger-hover);

        &:hover {
            color: white;
            fill: white;
            background-color: var(--danger, $danger);
        }
    }

    &.remove-btn {
        color: black;
        background-color: var(--danger, $danger);

        &:hover {
            color: white;
            fill: white;
            background-color: var(--danger-hover, $danger-hover);
        }
    }
}

.close-btn {
    top: 20px;
    right: 10px;
    position: absolute;
    cursor: pointer;
    fill: var(--main-text-color, $main-text-color);
}

@container form (min-width: 885px) {
    .remove-btn,
    .archive-btn {
        width: 110px;

        &::after {
            content: attr(data-tooltip);
        }
    }
}

@container form (max-width: 1100px) {
    .remove-btn,
    .archive-btn {
        flex-shrink: 0;
        font-size: 0 !important;
        width: var(--btn-height);
        position: relative;

        &:hover {
            &::after {
                content: attr(data-tooltip);
                position: absolute;
                z-index: 2;
                left: 50%;
                bottom: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
                transform: translateX(-50%);
                padding: var(--tooltip-paddings, $tooltip-paddings);
                font-size: var(--tooltip-font-size, $tooltip-font-size);
                border-radius: var(--tooltip-rounding, $tooltip-rounding);
                color: var(--tooltip-text-color, $tooltip-text-color);
                background-color: var(--danger, $danger);
            }

            &::before {
                content: '';
                position: absolute;
                z-index: 3;
                left: 50%;
                bottom: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
                border: var(--tooltip-arrow-size, $tooltip-arrow-size) solid transparent;
                transform: translateX(-50%);
                border-top-color: var(--danger, $danger);
            }
        }
    }
}

@media (min-width: 725px) {
    .separator {
        flex-grow: 1;
    }
}

@media (max-width: 1024px) {
    fieldset {
        gap: 6px 2px;
        flex-wrap: wrap;
    }

    .g-select {
        width: 100%;
    }

    .g-button {
        flex-grow: 1;
    }
}

</style>
