<template>
    <div class="columns-view">
        <g-checkbox v-model="selectAll" v-on:change="selectAllChanged" value="all">Select All</g-checkbox>
        <g-checkbox v-model="userState"
                    v-for="column in columns"
                    :key="column.key"
                    :value="column.name"
                    @change="rebuild">
            {{ $t(`lead.${column.name}`) }}
        </g-checkbox>
    </div>
</template>

<script>
import { useLeadActions } from '@lead';
import { computed, onMounted, ref, watch } from 'vue';
import { useProfileActions } from '@profile';

const getLeadResource = useLeadActions('getLeadResource');
const getProfileResource = useProfileActions('getProfileResource');

export default {
    name: 'ColumnViews',

    setup() {
        const leads = getLeadResource();
        const profile = getProfileResource();
        const selectAll = ref([]);
        const userState = ref([]);

        onMounted(() => {
            selectAll.value = profile.state.lead.columns.length < columns.value.length ? [] : ['all'] ;
        });

        watch(selectAll, () => {
            userState.value = selectAll.value ? profile.state.lead.columns : [];
        });

        const columns = computed(() => {
            return leads.columns.filter(column => {
                return column.allow ? profile.permissions.includes(column.allow) : true;
            });
        });

        function rebuild() {
            profile.state.lead.columns = userState.value;
            profile.saveState();
            selectAll.value = profile.state.lead.columns.length < columns.value.length ? [] : ['all'] ;
        }

        function selectAllChanged() {
            if (selectAll.value.length) {
                userState.value = columns.value.map(column => column.name);
            } else {
                userState.value = [];
            }

            rebuild()
        }

        return {
            selectAll,
            columns,
            userState,
            rebuild,
            selectAllChanged
        };
    }
};
</script>

<style lang="scss" scoped>
.g-checkbox {
    width: 100%;
    margin: 2px 0;
    padding: 2px 6px;
    box-sizing: border-box;
    text-transform: capitalize;
    display: inline-block;

    &:deep(.label) {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@media (min-width: 685px) {
    .columns-view {
        width: 660px;
        columns: 4 auto;
    }
}

@media (min-width: 545px) AND (max-width: 684px) {
    .columns-view {
        width: 500px;
        columns: 3 auto;
    }
}

@media (max-width: 544px) {
    .columns-view {
        columns: 2 auto;
    }
}
</style>
