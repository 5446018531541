<script setup>
import { computed, watch, onMounted, ref } from 'vue';
import { STATE, PopUpManager as PopUp } from '@/Ship';
import { useAssetActions } from '@asset';
import { useProfileActions } from '@profile';
import { preloaderAligner } from '@/Ship/components/global/preloaders/scrollHellper';

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getPairResource = useAssetActions('getPairResource');
const pairs = getPairResource();

watch(() => STATE.BRAND, () => {
    pairs.load();
});

const market = ref();
const marketList = [
    { name: 'crypto' },
    { name: 'commodities' },
    { name: 'forex' },
    { name: 'stocks' }
];

const counter = ref();
const counterList = [
    { name: 'USDT' },
    { name: 'BTC' },
    { name: 'ETH' }
];

const pairState = computed(() => {
    return pairs.state.filter(pair => {
        return (!market.value || pair.market === market.value) && (!counter.value || pair.counter === counter.value)
    });
});

const inactivated = computed(() => pairs.state.filter(item => !item.is_active).map(item => item.symbol));
const payload = {
    enable: [],
    disable: []
};

onMounted(() => {
    preloaderAligner('.environment');
});

const setDisablePairs = (pair) => {

    if (inactivated.value.includes(pair.symbol)) {
        pair.is_active = 1;

        if (payload.enable.includes(pair.symbol)) {
            const index = payload.enable.findIndex((symbol) => symbol === pair.symbol);
            payload.enable.splice(index, 1);
        } else {
            payload.enable.push(pair.symbol);
        }
    } else {
        pair.is_active = 0;

        if (payload.disable.includes(pair.symbol)) {
            const index = payload.disable.findIndex((symbol) => symbol === pair.symbol);
            payload.disable.splice(index, 1);
        } else {
            payload.disable.push(pair.symbol);
        }
    }
};

</script>

<template>
    <fieldset class="pairs" v-bind:disabled="profile.cant('Asset Edit')">
        <legend>
            <g-flex align="end" justify="start" gap="6">
                <g-select v-model="market"
                          v-bind:label="$t('trading.market', 'Market')"
                          v-bind:options="marketList"
                          option-text="name"
                          text-transform="capitalize"
                          option-value="name"/>
                <g-select v-if="market === 'crypto'"
                          v-model="counter"
                          v-bind:label="$t('assets.counter', 'Counter asset')"
                          v-bind:options="counterList"
                          v-bind:text-transform="'Capitalize'"
                          option-text="name"
                          option-value="name"/>

                <g-button class="login-btn" v-on:click="PopUp.open(require('@asset/components/PopUps/MakeAssets.vue')).setCaption($t('assets.make_asset', 'Create asset'))">
                    {{ $t('assets.create_asset', 'Create asset') }}
                </g-button>

                <g-button class="login-btn" v-on:click="PopUp.open(require('@asset/components/PopUps/MakePairs.vue')).setCaption($t('assets.make_pair', 'Create pair'))">
                    {{ $t('assets.create_pair', 'Create pair') }}
                </g-button>
            </g-flex>
        </legend>

        <g-preloader-content v-bind:is-loading="pairs.isLoading || !pairs.isLoaded"/>
        <div class="container">
            <div class="pair" v-for="pair in pairState" v-bind:key="`pair-${pair.symbol}`">
                <g-checkbox v-model="inactivated"
                            v-bind:value="pair.symbol"
                            v-on:change="setDisablePairs(pair)"
                            v-bind:class="{danger: inactivated.includes(pair.symbol)}"
                            v-bind:symbol="'close'">
                    <b>{{ pair.base }}</b>{{ pair.counter }}
                </g-checkbox>
                <g-symbol name="settings"
                          width="16"
                          height="16"
                          class="edit-btn"
                          v-on:click.stop="PopUp.open(require('@asset/components/PopUps/EditPair.vue')).setCaption($t('assets.edit_pair', 'Edit pair')).setPayload({payload: { symbol: pair.symbol }})"/>
            </div>
        </div>
        <g-flex justify="end">
            <g-button type="button" class="save-btn" v-on:click="pairs.update(payload)">{{ $t('base.save', 'Save') }}</g-button>
        </g-flex>
    </fieldset>
</template>


<style scoped lang="scss">
fieldset {
    padding: 15px;
    margin: 0 10px;
    min-height: 400px;
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 1px solid var(--separator-color, $separator-color);

    & > legend {
        padding: 0 6px 6px 6px;
        position: relative;
        z-index: 4;

        .g-select {
            background-color: var(--env-bg, $env-bg);
        }
    }

    :deep(.preloader-wrapper) {
        height: calc(100% + 26px) !important;
        top: -26px !important;
        border-radius: var(--bar-rounding, $bar-rounding);
        overflow: hidden;
    }
}

.pairs {
    container-name: pairs;
    container-type: inline-size;

    .pair {
        width: 100%;
        gap: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: start;
        margin: 6px 0;

        .edit-btn {
            cursor: pointer;
            fill: var(--main-text-color, $main-text-color);

            &:hover {
                fill: var(--title-text-color, $title-text-color);
            }
        }
    }

    &:disabled {
        .g-checkbox {
            opacity: 0.5;
        }
    }
}

.g-button {
    padding: 0 20px;
    min-width: 100px;
}

@container pairs (min-width: 1300px) {
    .container {
        column-count: 8;
    }
}

@container pairs (min-width: 1000px) and (max-width: 1300px) {
    .container {
        column-count: 6;
    }
}

@container pairs (min-width: 800px) and (max-width: 1000px) {
    .container {
        column-count: 5;
    }
}

@container pairs (min-width: 700px) and (max-width: 800px) {
    .container {
        column-count: 4;
    }
}

@container pairs (min-width: 500px) and (max-width: 700px) {
    .container {
        column-count: 3;
    }
}

@container pairs (min-width: 350px) and (max-width: 500px) {
    .container {
        column-count: 2;
    }
}
</style>