import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Position extends BaseResource {

    static #instance = null;

    endpoint = 'positions';
    referer = 'Position Resource';

    section = 'Customers';
    container = 'Positions';

    static getInstance() {
        if (!Position.#instance) {
            Position.#instance = new Position();
        }
        return Position.#instance;
    }
}
