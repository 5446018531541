const inactivityTimer = (callback, timeout) => {
    let time;
    function addListeners() {
        document.addEventListener('mousemove', resetTimer);
        document.addEventListener('mousedown', resetTimer);
        document.addEventListener('touchstart', resetTimer);
        document.addEventListener('click', resetTimer);
        document.addEventListener('keydown', resetTimer);
        document.addEventListener('wheel', resetTimer);
    }

    function removeListeners() {
        document.removeEventListener('mousemove', resetTimer);
        document.removeEventListener('mousedown', resetTimer);
        document.removeEventListener('touchstart', resetTimer);
        document.removeEventListener('click', resetTimer);
        document.removeEventListener('keydown', resetTimer);
        document.removeEventListener('wheel', resetTimer);
    }

    function resetTimer() {
        clearTimeout(time);
        time = setTimeout(callback, timeout)
    }

    function clearTimer() {
        clearTimeout(time);
        removeListeners();
    }

    return {
        addListeners,
        removeListeners,
        resetTimer,
        clearTimer
    }
}

export default inactivityTimer;
