<script setup>
import { computed, defineEmits, reactive } from 'vue';
import { useCampaignActions, useCampaignComponents } from '@campaign';

const emit = defineEmits(['performed', 'close']);

const {
    CampaignForm
} = useCampaignComponents();

const getCampaignResource = useCampaignActions('getCampaignResource');
const campaign = getCampaignResource();

const payload = reactive({
    name: '',
    source: '',
    language: '',
    offer_id: '',
    brand_id: '',
    affiliate_id: null,
    buyer_id: null,
    geo: [],
    type: 'CPL',
    cost: 1,
});

const errors = reactive({
    name: '',
});

const isPayloadValid = computed(() => {
    return payload.name
        && payload.source
        //&& payload.language
        && payload.offer_id
        && payload.affiliate_id
        && payload.buyer_id
        //&& payload.brand_id
        && payload.geo.length > 0
        && payload.type
        && payload.cost;
});

function handleErrorResponse(error) {
    if (error.response && error.response.data && error.response.data.description) {
        const errorDescriptions = error.response.data.description;

        for (const key in errorDescriptions) {
            if (errors[key] !== undefined) {
                errors[key] = errorDescriptions[key][0];
            }
        }
    } else {
        console.error($t('base.server_error'), error);
    }
}

function makeCampaign() {
    campaign.create(payload).then(() => {
        emit('close');
    }).catch(error => {
        handleErrorResponse(error);
    });
}

</script>

<template>
    <campaign-form v-bind:errors=errors class="make-campaign" v-model="payload"/>

    <g-flex class="btn-bar" gap="5" justify="end">
        <g-button class="cancel-btn" type="button" v-on:click="$emit('close')">Cancel</g-button>
        <g-button class="save-btn" type="submit" v-bind:disabled="!isPayloadValid" v-on:click="makeCampaign">Make</g-button>
    </g-flex>
</template>

<style scoped lang="scss">
.make-campaign {
	width: 460px;
    position: relative;
    z-index: 1;
}

.btn-bar {
	margin-top: 20px;
}

.g-button {
	width: 100px;
}
</style>