import { useTimelineResources } from '@timeline';
import {ResourcesManager} from "@/Ship";

export default (params, reload = false, referer = null) => {
    const timeline = useTimelineResources('Timeline');
    if (reload || (!timeline.isLoaded && !timeline.isLoading)) {
        timeline.setFilters(params.filters);
        timeline.load(null, {}, referer);
    }

    ResourcesManager.addResource(timeline, 'Timeline');

    return timeline;
};
