<template>
    <div class="g-textarea" :class="[{ disabled: disabled}, {filled: !!modelValue}]" v-on:keydown.stop>
        <textarea v-bind:value="modelValue"
                  v-on:input="inputEvent"
                  v-bind="$attrs"
                  :disabled="disabled"
                  :readonly="readonly"
                  :placeholder="label"
                  v-on:keydown.tab.prevent="insertTab($event.target)"/>
        <slot/>

        <g-symbol v-if="readonly"
                  name="lock"
                  width="18"
                  height="18"
                  class="btn"/>

        <g-symbol v-else-if="modelValue !== initValue"
                  name="close"
                  class="btn"
                  width="20"
                  height="20"
                  v-on:click="reset"/>

        <div v-if="hint" class="hint-text">{{ hint }}</div>
    </div>
</template>

<script>
import { computed, toRefs } from 'vue';

export default {
    name: 'GTextarea',
    inheritAttrs: false,
    props: {
        label: {
            type: String
        },
        modelValue: {
            type: String
        },
        hint: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
    },
    emits: ['update:modelValue'],
    setup(props, {emit}) {

        const initValue = props.modelValue || '';

        const inputEvent = event => emit('update:modelValue', event.target.value);

        const reset = () => {
            emit('update:modelValue', initValue);
        };

        function insertTab(textarea) {
            const start = textarea.selectionStart;
            const before = props.modelValue.substring(0, start);
            const after = props.modelValue.substring(start, props.modelValue.length);
            textarea.value = before + '\t' + after;
            emit('update:modelValue', textarea.value);
            textarea.setSelectionRange(start+1,start+1);
        }

        return {
            inputEvent,
            reset,
            insertTab,
            initValue,
        };
    }
};
</script>

<style lang="scss" scoped>
.g-textarea {
    padding: 0;
    box-sizing: border-box;
    position: relative;
    min-height: var(--input-height, $input-height);
    color: var(--main-text-color, $main-text-color);
    background-color: var(--input-bg, $input-bg);
    border-radius: var(--input-rounding, $input-rounding);
    border: 1px solid var(--input-border, $input-border);
    transition: background-color 0.2s;

    textarea {
        width: 100%;
        height: 100%;
        border: none;
        padding: 10px 20px 10px 10px;
        resize: vertical;
        background-color: transparent;
        color: var(--title-color, $title-color);
        font-family: var(--font-primary, $font-primary);
        font-size: var(--input-font-size, $input-font-size);

        &:focus {
            outline: none;
        }
    }

    &:deep(svg) {
        cursor: pointer;
        fill: var(--main-text-color, $main-text-color);

        &:hover {
            fill: var(--title-color, $title-color);
        }
    }

    &.filled,
    &:focus-within {
        background-color: var(--input-active-bg, $input-active-bg);

        & > input {
            border-radius: var(--input-active-bg, $input-rounding);

            &:-webkit-autofill {
                background: transparent;
                transition: background-color 5000s ease-in-out 0s;
                -webkit-text-fill-color: var(--main-text-color, $main-text-color);
            }
        }

        &:deep(svg) {
            fill: var(--title-color, $title-color);
        }
    }

    &.error {
        border-color: var(--danger, $danger);

        input {
            background: var(--input-active-bg, $input-active-bg);
        }

        .hint-text {
            color: var(--danger, $danger);
        }
    }

    &.disabled {
        opacity: 0.7;
        pointer-events: none;

        input {
            color: var(--disabled-color, $disabled-color);
        }
    }

    & > .btn {
        top: 6px;
        right: 8px;
        position: absolute;
    }
}

.hint-text {
    bottom: 100%;
    left: 50%;
    max-width: 100%;
    padding: 5px 15px;
    border-radius: 3px;
    position: absolute;
    transform: translateX(-50%);
    background-color: var(--hint-bg, $hint-bg);
    color: var(--hint-text-color, $hint-text-color);

    &::after {
        content: '';
        bottom: -10px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        border: 5px solid transparent;
        border-top-color: var(--hint-bg, $hint-bg);
    }
}
</style>
