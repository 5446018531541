import { useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class PositionHistory extends BaseResource {

    static #instance = null;

    endpoint = 'positions-history';
    referer = 'PositionHistory Resource';

    section = 'Customers';
    container = 'Positions';

    static getInstance() {
        if (!PositionHistory.#instance) {
            PositionHistory.#instance = new PositionHistory();
        }
        return PositionHistory.#instance;
    }
}
