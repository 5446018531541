<script>
import { useProfileComponents } from '@profile';
import { useEnvComponents } from '@overlay';

export default {
    name: 'Profile',
    extends: useEnvComponents('Layout'),
    components: {
        Environment: useProfileComponents('Environment'),
    },

    setup() {

        return {
            sidebar: false,
            title: 'profile'
        };
    }
};
</script>
