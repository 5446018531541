import { useUserResources } from '@user';
import {ResourcesManager} from "@/Ship";

export default (reload = false, referer = null) => {
    const user = useUserResources('User');
    if (reload || (!user.isLoaded && !user.isLoading)) {
        user.setIncludes(['offers', 'roles', 'whitelist', 'teamleads', 'teams', 'brands']);
        //user.load(null, {}, referer);
    }

    ResourcesManager.addResource(user, 'User');

    return user;
};
