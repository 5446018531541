<template>
    <div class="profile environment">
        <div class="content">
            <div class="profile-settings">
                <info/>
            </div>
        </div>
    </div>
</template>

<script>

import { reactive, ref } from 'vue';
import { STATE, PopUpManager as PopUp } from '@/Ship';
import { useProfileResources } from '@profile';
import { useProfileComponents } from '@/Containers/Profile/Profile';

export default {
    name: 'Environment',
    components: {
        Info: useProfileComponents('Info')
    },
    setup: function () {
        const profile = useProfileResources('Profile');
        const tabList = reactive([
            { Info: 'Profile' },
        ]);

        let timeout = null;
        const logChanges = () => {

            if (profile.isValidationPassed()) {
                STATE.IS_PROCESSING = true;
                STATE.AUTO_SAVE = false;
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    STATE.IS_PROCESSING = false;
                    STATE.AUTO_SAVE = true;
                    profile.save();
                    profile.clearEntity();
                }, 5000);
            }
        };

        const isShowTooltip = ref([]);

        return {
            PopUp,
            profile,
            isShowTooltip,
            logChanges,
            tabList
        };
    }
};
</script>

<style lang="scss" scoped>
.environment {
    background-color: var(--env-bg, $env-bg);
}

.content {
    display: flex;
    gap: 20px;
    margin-top: 50px;
}

.profile-settings {
    width: 100%;
    padding: 0 0 0 2vw;
}

.form-wrapper {
    max-width: 744px;
    width: 100%;
    margin: auto;
}

.title {
    font-weight: 400;
    font-size: 20px;
    color: var(--secondary, $secondary);
    margin-bottom: 27px;
}

.footer {
    display: flex;
    justify-content: flex-end;
    gap: 25px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.client-item {
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid var(--separator-color, $separator-color);

    .footer {
        margin-bottom: 0;
    }
}

.header {
    margin-bottom: 20px;

    .title {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 767px) {
    .footer {
        gap: 12px;
        justify-content: space-between;

        .g-button {
            width: 50%;
        }
    }

    .row-api {
        flex-wrap: wrap;

        .g-button {
            width: 100%;
        }
    }
}

@media screen and (max-width: 550px) {
    .footer {
        flex-direction: column;

        .g-button {
            width: 100%;
        }
    }
}

@media screen and (max-width: 460px) {
    .row:not(.row-api) {
        flex-direction: column;
        margin-bottom: 16px;

        .col {
            width: 100%;
        }
    }
}
</style>
