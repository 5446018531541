import { useOfferResources } from '@offer';
import { ResourcesManager } from "@/Ship";

export default () => {
    const offer = useOfferResources('Offer');
    if (!offer.isLoaded && !offer.isLoading) {
        //campaign.setIncludes([]);
        offer.load();
    }

    ResourcesManager.addResource(offer, 'Offer');

    return offer;
};
