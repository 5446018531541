<template>
    <div class="preloader-wrapper" v-show="isLoading">
        <g-preloader-overlay/>
    </div>
</template>

<script>
export default {
    name: 'g-preloader-content',
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    },

    setup() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.preloader-wrapper {
    position: absolute;
    display: block;
    margin: auto;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    background-color: var(--popup-overlay, $popup-overlay);
    z-index: 3;
}
</style>