<script setup>

import { reactive, defineProps, defineEmits } from 'vue';
import { useCommentActions } from '@comment';

const props = defineProps(['payload']);
const emit = defineEmits(['performed','close']);

const getCommentResource = useCommentActions('getCommentResource');
const comments = getCommentResource();

comments.index = comments.state.findIndex(comment => comment.id === props.payload.id);

function saveComment() {
    comments.save().then(() => {
        comments.state[comments.index].body = comments.model.body;
        comments.index = null;
        emit('close');
    });
}

function cancel() {
    comments.index = null;
    emit('close');
}
</script>

<template>
    <form id="comment-form"
          class="edit-comment"
          v-on:reset="cancel"
          v-on:submit.prevent="saveComment"
          tabindex="0">
        <g-textarea
            v-model="comments.model.body"
            rows="8"
            maxlength="1024"
            label="..."
            @keyup.alt.enter.prevent.stop="saveComment"
        />

        <g-flex class="btn-bar" gap="5">
            <g-button class="cancel-btn" type="reset">{{ $t('base.cancel', 'Cancel') }}</g-button>
            <g-button class="save-btn" type="submit" v-bind:disabled="!comments.model.body">{{ $t('base.save', 'Save') }}</g-button>
        </g-flex>
    </form>
</template>

<style lang="scss" scoped>
.edit-comment {
    width: 540px;
    max-width: 100%;
}

.g-textarea {
    width: 100%;
}

.btn-bar {
    margin-top: 20px;
}

.g-button {
    min-width: 100px;
}

@media (min-width: 455px) {
    .btn-bar {
        justify-content: end;
    }
}

@media (max-width: 454px) {
    .btn-bar {
        justify-content: center;
    }
}
</style>