<template>
    <svg class="g-symbol-currency" v-bind="$attrs">
        <use v-bind:xlink:href="`#${name}`"/>
    </svg>
</template>

<script>
export default {
    name: 'GSymbolCurrency',
    props: ['name'],
};
</script>

<style scoped>
svg {
    vertical-align: middle;
}
</style>
