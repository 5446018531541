<script setup>

import {defineEmits, defineProps} from 'vue';

defineProps({
    tabList: {
        type: Object,
    },
    modelValue: {
        type: String,
        default: ''
    }
});
const emit = defineEmits(['update:modelValue', 'change']);

function choose(key) {
    emit('update:modelValue', key);
    emit('change');
}

</script>

<template>
    <div class="g-tabs">
        <div v-for="(value, key) in tabList"
             v-bind:key="key"
             class="tab"
             v-bind:class="{'active': key === modelValue }"
             v-on:click="choose(key)">
            {{ value }}
        </div>
    </div>
</template>

<style lang="scss" scoped>
.g-tabs {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: start;
    font-family: $font-title;

    .tab {
        font-weight: 500;
        font-size: 1.3rem;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        color: var(--main-text-color, $main-text-color);

        &:hover {
            color: var(--title-text-color, $title-text-color);
        }

        &.active {
            color: var(--warning-hover, $warning-hover);
        }
    }
}
</style>