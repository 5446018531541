<script setup>

import {computed, reactive, defineProps, defineEmits} from "vue";
import { useI18n } from "vue-i18n";
import { useBrandActions } from '@brand';

const props = defineProps({
    settings: {
        type: Object
    }
});
const emit = defineEmits(['save']);

const getBrandResource = useBrandActions('getBrandResource');
const brands = getBrandResource();

const payload = reactive({});

const { fallbackLocale } = useI18n();

const languages = computed(() => fallbackLocale.value.map(code => {
    return {v: code}
}));

const useWhitelist = computed({
    get: () => props.settings.useWhitelist || false,
    set: (value) => {
        props.settings.useWhitelist = value;
        payload.useWhitelist = value;

        emit('save', payload, 'general');

        return true;
    }
});

const defaultBrand = computed({
    get: () => props.settings.defaultBrand || '',
    set: value => {
        props.settings.defaultBrand = value;
        payload.defaultBrand = value;

        emit('save', payload, 'general');

        return true;
    }
});
const defaultLanguage = computed({
    get: () => props.settings.defaultLanguage || '',
    set: value => {
        props.settings.defaultLanguage = value;
        payload.defaultLanguage = value;

        emit('save', payload, 'general');

        return true;
    }
});

</script>

<template>
    <fieldset class="crm general">
        <legend>CRM Settings</legend>
        <g-flex gap="10" justify="between">
            <g-flex class="whitelist-switcher" align="center" justify="start" gap="8">
                <label>Enable whitelist</label>
                <g-switch v-model:checked="useWhitelist"/>
            </g-flex>

            <g-flex gap="8">
                <g-select v-model="defaultBrand"
                          label="Default Brand"
                          v-bind:options="brands.state"
                          option-text="name"
                          option-value="id"/>

                <g-select v-model="defaultLanguage"
                          label="Default Language"
                          v-bind:options="languages"
                          option-text="v"
                          option-value="v"/>
            </g-flex>
        </g-flex>
    </fieldset>
</template>

<style scoped lang="scss">
.g-select {
    width: 150px;
}

.whitelist-switcher {
    white-space: nowrap;
}
</style>