
import { ref, watch, computed } from 'vue';

export default class Socket {
    static #instances = {};
    endpoint = null;
    stack = ref([]);
    subscribes = {};
    handlers = {};

    ready = ref(false);

    constructor(endpoint) {

        this.endpoint = endpoint;

        this.connect(endpoint);

        watch([this.stack.value], () => {
            if (this.stack.value.length) {
                this.resolveStack();
            }
        });
    }

    static getInstance(endpoint) {

        const url = new URL(endpoint).host;

        if (!Socket.#instances[url]) {
            Socket.#instances[url] = new Socket(endpoint);
        }

        return Socket.#instances[url];
    }

    connect(endpoint) {
        this.connection = new WebSocket(endpoint);
        this.connection.onclose = this.onclose;
        this.connection.onerror = this.onerror;
        this.connection.onmessage = this.onmessage;
        this.connection.onopen = () => {
            for (const key in this.subscribes) {
                this.send(JSON.stringify({[key]: this.subscribes[key]}));
            }
        };
    }

    reconnect(force = false) {
        if (force || (!this.isReady)) {
            this.connect(this.connection.url);
        }
    }

    subscribe(subscribes) {
        if (this.isReady) {
            for (const key in subscribes) {
                this.subscribes[key] = subscribes[key];
                this.send(JSON.stringify(subscribes));
            }
        } else {
            setTimeout(() => {
                this.subscribe(subscribes);
            }, 1000);
        }
    }

    addHandlers(handlers) {
        Object.assign(this.handlers, handlers);
    }

    removeHandler(source, target) {
        delete this.handlers[source][target];
    }

    unsubscribe(subscribe) {
        delete this.subscribes[subscribe];
        //this.removeHandler(subscribe, target);
        this.send(JSON.stringify({
            unsubscribe: subscribe
        }));
    }

    send(message) {
        this.stack.value.push(() => {
            this.connection.send(message);
        });
    }

    resolveStack() {
        const send = this.stack.value.shift()
        send();
        if (this.stack.value.length) {
            this.resolveStack();
        }
    }

    close(code = 1000, reason = null) {
        this.connection.close(code, reason);
    }

    onmessage(event) {

        const instance = Socket.getInstance(this.url);

        //try {
            const data = (typeof event.data === 'string')
                ? JSON.parse(event.data)
                : event.data;

            for (const key in data) {
                if (instance.handlers[key]) {
                    if (typeof instance.handlers[key] === 'function') {
                        instance.handlers[key](data[key], key);
                    } else if (typeof instance.handlers[key] === 'object') {
                        Object.values(instance.handlers[key]).forEach(handler => {
                            handler(data[key], key);
                        });
                    }
                }
            }
        //} catch (exception) {
        //    console.error(`[WS]: ${exception.message}`, event);
        //}


        // for (const section in message.data) {
        //     if (section === 'Ship') {
        //         this.executeShipActions(message.data[section]);
        //     } else {
        //         this.executeContainersActions(section, message.data[section]);
        //     }
        // }
    }

    executeContainersActions(section, containers) {
        for (const container in containers) {
            const actions = containers[container];
            for (const actionName in actions) {
                try {
                    const action =
                        require(`@/Containers/${section}/${container}/actions/${actionName}.js`).default;
                    const data = actions[actionName];
                    action(data);
                } catch (error) {
                    this.onerror({
                        code: 404,
                        message: error.message,
                    });
                }
            }
        }
    }

    executeShipActions(actions) {
        for (const actionName in actions) {
            try {
                const action = require(`@/Ship/actions/${actionName}.js`).default;
                const data = actions[actionName];
                action(data);
            } catch (error) {
                this.onerror({
                    code: 404,
                    message: error.message,
                });
            }
        }
    }

    onclose(event) {
        const instance = Socket.getInstance(this.url);
        if (!event.wasClean) {
            // например, сервер убил процесс или сеть недоступна
            // обычно в этом случае event.code 1006
            if (process.env.VUE_APP_ENV === 'local') {
                console.error(`[${this.url}] Connection terminated, code: `, event.code);
            }
            setTimeout(() => {
                if (!instance.isReady) {
                    instance.reconnect();
                }
            }, 3000);
        }
        instance.isReady;
    }

    onerror(error) {

    }

    get isReady() {
        // 0 – CONNECTING
        // 1 – OPEN
        // 2 – CLOSING
        // 3 – CLOSED
        this.ready.value = this.connection.readyState === 1;

        return this.ready.value;
    }
}